import React, { useState } from 'react';
import useStyles from './styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel';


function Bar({ AppBarLabels, AppBarComponents, variant ,  activeTab, setActiveTab, ...rest }) {

    const [value, setValue] = useState(0);
    const classes = useStyles();

    const getValue = () => {
        return activeTab != null ? activeTab : value
    }

    const setTab = (index) => {
        if (setActiveTab) {
            setActiveTab(index)
        }
        else {
            setValue(index)
        }
    }

    return (
        <div className={classes.appBar}>
            <AppBar position='static' color="primary" >
                <Tabs
                    value={getValue()}
                    onChange={(event, value) => setTab(value)}
                    indicatorColor="secondary"
                    variant={variant}
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs"
                    centeredj
                >
                    {AppBarLabels.map((item, index) => {
                        return <Tab key={index} value={index} label={item.label} {...TabProps('' + index)} />
                    })}
                </Tabs>
            </AppBar>
            
            {
                AppBarComponents.map((item, index) => (
                    <TabPanel key={index} value={getValue()} index={index}>
                        {item.component(rest)}
                    </TabPanel>
                ))
            }

        </div>
    )
}

Bar.defaultProps = {
    activeTab: null,
    setActiveTab: false,
    variant : 'scrollable'
}

export default Bar;

function TabProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}