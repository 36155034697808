

export const columns = [
    { name: "question", label: "Question", options: { filter: true, sort: true, display: true } },
    { name: "answer", label: "Answer", options: { filter: true, sort: true, display: true } },
    { name: "type", label: "Type", options: { filter: true, sort: true, display: true } },
];




export const data = [
    { question: "question", answer: "answer", type: "Transaction Bot" },
    { question: "question", answer: "answer", type: "HR Admin Bot" },
    { question: "question", answer: "answer", type: "Transaction Bot" },
    { question: "question", answer: "answer", type: "Support Bot" },
    { question: "question", answer: "answer", type: "Support Bot" },
    { question: "question", answer: "answer", type: "Performance Bot" },
    { question: "question", answer: "answer", type: "CRM Bot" },
    { question: "question", answer: "answer", type: "CRM Bot" },
    { question: "question", answer: "answer", type: "Policy Bot" },
    { question: "question", answer: "answer", type: "HR Admin Bot" },

]



