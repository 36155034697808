import React from 'react'
import { PhoneNumInput, Input, Switch, TextArea, Dropdown,Table , ObjectsArray , DropdownMultiple, DatePicker, Upload } from '../';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from '~/Services/Helpers';
import Uploader from '../../Uploader';

const FormControlCreator = ({ field , formName, editState, error, errorText, editValue, value, InputChange, SetFieldValue, blur, getValues, onChange, values }) => {
    const fieldProps = {
        ...field,
        show : isEmpty(field.show) ? true: field.show ,
        value,
        error,
        errorText,
        blur,
        editState,
        editValue,
        placeholder: <FormattedMessage id={`${formName}.${field.name}`} />,
    };


    if(!fieldProps.show) return <></>;
    switch (field.type) {
        case 'phone':
            return <PhoneNumInput {...fieldProps} InputChange={SetFieldValue} />;
        case 'switch':
            return <Switch {...fieldProps} InputChange={InputChange} />;
        case 'textarea':
            return <TextArea {...fieldProps} InputChange={SetFieldValue} />;
        case 'date':
            return <DatePicker {...fieldProps} InputChange={InputChange} />;
        case 'dropdown':
            return <Dropdown {...fieldProps} InputChange={SetFieldValue} />;
        case 'dropdown-multiple':
            return <DropdownMultiple {...fieldProps} InputChange={SetFieldValue} />;
        case "table":
            return <Table {...fieldProps} InputChange={SetFieldValue} />
        case 'file':
            return <Upload {...fieldProps} InputChange={SetFieldValue} getValues={getValues} />;
        case 'objects-array':
            return <ObjectsArray {...fieldProps} values={values} InputChange={SetFieldValue} getValues={getValues} onChange={onChange} />;
        case 'uploader':
            return <Uploader {...fieldProps} InputChange={SetFieldValue} />
        default:
            return <Input {...fieldProps} InputChange={(e) => {
                InputChange(e);
                if(onChange)
                    onChange(e, SetFieldValue, values);
            }} />;
    }
}



export default FormControlCreator
