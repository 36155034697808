import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Table from '~/Components/EnhancedTable';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { columns } from './data';
import styles from './styles';


class APMaintenanceTransaction extends React.Component {
  static contextType = NotificationContext;
  tableData = [];
  loading = true;
  componentDidMount() {
    this.props.entityStore.get();
  }
  entityDidReceived(data) {
    if (!!data && Array.isArray(data)) {
      this.tableData = data;
      this.loading = false;
    }
  }
  entityDidCatch(error) {
    this.loading = false;
    this.notify("error", error)

  }
  onDeleteConfirm = (idsArray) => {
    idsArray.map(id => {
      this.props.entityStore.delete({ id });
    });
  }
  entityDidDelete() {
    this.notify("Success", "Row/s was deleted successfully");
  }
  notify = (severity, message) => {
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity,
      message
    }))
  }
  render() {

    if (this.loading) return <Loading />
    return (
      <>
        <Table
          addLink
          showKeyIndex={0}
          addButtonText="AP Maintenance Transaction "
          title="APMaintenanceTransaction"
          defaultData={this.tableData}
          columns={columns}
          parentDeleteAction={this.onDeleteConfirm}
        />
      </>
    )
  }
}

decorate(APMaintenanceTransaction, {
  tableData: observable.shallow,
  loading: observable,
})

export default withStyles(styles)(asEntity({ storeId: 'APMaintenanceTransaction' })(observer(APMaintenanceTransaction)));
