

export const columns = [
    { name: "maintCode", label: "Maintenance Type Code", options: { filter: false, sort: true, display: true } },
    { name: "mainName", label: "Maintenance Type Name", options: { filter: true, sort: true, } },
    { name: "frgnName", label: "Foreign Name", options: { filter: true, sort: true, } },
];






