import React from 'react'
import APServiceContractForm from '../Partials/APServiceContractForm'
import useStyles from './styles'

function APServiceContract(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <APServiceContractForm {...props} />
        </div>
    )
}

export default APServiceContract;
