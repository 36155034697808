

export const columns = [
    { name: "name", label: "Name", options: { filter: false, sort: true, display: true } },
    { name: "status", label: "Status", options: { filter: false, sort: true, display: true } },
    { name: "reach", label: "Reach 18", options: { filter: false, sort: true, display: true } },
];





export const data = [

]



