import { withStyles, Chip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PrintRounded from '@material-ui/icons/PrintRounded';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import APServiceContractForm from '../Partials/APServiceContractForm';
import styles from './styles';

import ApiConfigs from "~/Configs/Api";
import { StorageKeys } from "~/Configs/Storage";
import { getStorage } from "~/Services/Helpers";
import axios from "axios";
import { FileHeader } from '../../../../../Components/Uploader/Partials/FileHeader';


const token = getStorage(StorageKeys.token);
const company = window.localStorage.getItem(StorageKeys.Company) ? window.localStorage.getItem(StorageKeys.Company).split(".")[0] : "";


class ViewAPServiceContract extends React.Component {
    constructor(props) {
        super(props);
        this.getfiles = this.getfiles.bind(this);
    }
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {};
    files = {};

    loading = false;
    status = 0;

    statusVals = {
        0: "Opened",
        1: "Closed",
        2: "Canceled",
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        if (!!this.id) {
            this.loading = true;
            this.props.entityStore.get(this.id)
        }
    }

    setView = (view) => {
        this.view = view;
    }

    entityDidReceived(data) {
        if (!!data) {
            let { contractNum, date,deliveryDate,cardName, isShipFromCompany, fromCity, isShipToCompany, toCity, withReturn, returnRate, weight,amount,totalVAT, totalAmount,equipmentCode1, equipmentCode2,deliveryName, projectName,servName,contName ,salesEmployeeName,remark, attKey}  = data;
            contName= data.contractTypeNavigation.contName;
            servName= data.shipmentService.servName;
            this.data = { contractNum, date,deliveryDate,cardName, isShipFromCompany, fromCity, isShipToCompany, toCity, withReturn, returnRate, weight,amount, totalVAT, totalAmount, equipmentCode1, equipmentCode2,deliveryName, projectName,servName, contName,salesEmployeeName,remark,   }
            this.status = data.invoiceStatus;
            if (attKey) {
                this.getfiles(attKey);
            } else {
                this.loading = false;
            }

        }
    }

    deletefile = async (id) => {
        axios.delete(`${ApiConfigs.getCompanyURL(company)}Administration/Attachment/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }).then((response) => {
                this.getData();
            })
            .catch((error) => {

            })
    };
    getfiles = async (id) => {
        const result = await axios(
            `${ApiConfigs.getCompanyURL(company)}Administration/Attachment/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        );
        if (result.data) {
            this.files = result.data
            this.loading = false;
        }
    };
    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>

                <Chip style={{ position: 'absolute', right: 60, top: 13 }}
                    variant="outlined"
                    color="primary" label={this.statusVals[this.status]}
                />{
                    <Link to={ "/print/APDelivry-" + this.id} target="_blank">
                        <div style={{ position: 'absolute', right: 0, top: 13 }}>
                            <PrintRounded 
                            style={{ fontSize:40 }}
                            />
                        </div>
                    </Link>
                }
                {
                    this.view === "show" &&
                    <>
                        <ShowView title="AP service contract Info."
                            showViewName="APServiceContract"
                            data={toJS(this.data)}
                            setView={false}
                        />
                        {(this.files && this.files.attachmentLines) ? <FileHeader files={this.files} onDelete={this.deletefile} /> : null}

                    </>
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <APServiceContractForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewAPServiceContract, {
    view: observable,
    data: observable,
    loading: observable

})




export default withStyles(styles)(asEntity({ storeId: "APServiceContractForm" })(observer(ViewAPServiceContract)));
