
const Styles = theme => ({
    root: {
        minWidth: 200,
        textAlign: 'left'
    },
    dropdownControl: {
        width: "100%",
        margin: `${theme.spacing(2)}px`
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    subLoading: {
        position: 'fixed',
        top: 'calc(15%)',
        left: theme.direction === "ltr" ? 'calc(65%)' : "",
        right: theme.direction === "rtl" ? 'calc(66%)' : "",
    },
    DataFetchError : {
        padding : theme.spacing(2),
        border : 'solid 1px red',
        borderRadius : 5
    }
});

export default Styles;