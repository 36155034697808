import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import PaymentMethodsForm from '../Partials/PaymentMethodsForm';
import styles from './styles';


class ViewPaymentMethod extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {};
    itemDetails = []
    loading = false;


    componentDidMount() {
        if (!!this.id) {
            this.loading = true;
            this.props.entityStore.get(this.id)
        }
    }
    setView = (view) => {
        this.view = view;
    }
    entityDidReceived(data) {
        if (!!data) {
            this.loading = false;
            let { foodicsID,
                accountCode,
                type } = data;
            this.data = {
                foodicsID,
                accountCode,
                type: type === 1 ? "BankTransfer" : "Cash"
            }
        }
    }

    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="PaymentMethods"
                        data={this.data}
                        setView={this.setView}
                        showViewName="PaymentMethods"
                    />
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <PaymentMethodsForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewPaymentMethod, {
    view: observable,
    loading: observable
})




export default withStyles(styles)(asEntity({ storeId: "PaymentMethodForm" })(observer(ViewPaymentMethod)));
