import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    foodicsID: Yup.string().trim().required(),
    sapCode: Yup.string().trim().required(),
    isDefault: Yup.boolean(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        foodicsID: '',
        sapCode: '',
        isDefault: false,
    },
};

const FoodicsID = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}`,
            id: `${element.name}_#_${element.id}`
        })
    });
    return Options;
}

const sapCodeParseData = (data) => {
    const Options = []
    data=data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}-${element.code}`,
            id: `${element.name}_#_${element.code}`
        })
    });
    return Options;
}

export const formData = [
    { name: "foodicsID", company: true, type: "dropdown", optionsURI: "/Foodics/Taxes", parseData: FoodicsID },
    { name: "sapCode", company: true, type: "dropdown", optionsURI: "/Financials/TaxGroup/Short", parseData: sapCodeParseData },
    { name: "isDefault", type: "switch" },
];
