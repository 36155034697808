import axios from "axios";
import { create } from "apisauce";
import ApiConfigs from "../Configs/Api";
import { StorageKeys } from '../Configs/Storage';
const axiosInstance = axios.create({
  baseURL: ApiConfigs.apiUrl,
  timeout: ApiConfigs.timeout,
  headers: {
    Accept: `application/json;v=${ApiConfigs.version}`,
    'Content-Type': 'application/json',
  },
});
const BaseApi = create({ axiosInstance: axiosInstance });
let Api = create({ axiosInstance: axiosInstance });
const CancelToken = axios.CancelToken;
const requestsSourceCancellation = CancelToken.source();

export const setAPI = (company) => {
  const axiosInstanceWithCompany = axios.create({
    baseURL: ApiConfigs.getCompanyURL(company),
    timeout: ApiConfigs.timeout,
    headers: {
      Accept: `application/json;v=${ApiConfigs.version}`,
      'Content-Type': 'application/json',
    },
  });
  Api = create({ axiosInstance: axiosInstanceWithCompany })
}
const companyId = window.localStorage.getItem(StorageKeys.Company) ? window.localStorage.getItem(StorageKeys.Company).split(".")[0] : "";
setAPI(companyId)

const endpoints = {
  account: {
    login: (data, headers) => BaseApi.post("Account/token", data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    get: (data, headers) => BaseApi.get("Account/verify", data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    resetPassword: (data, headers) => BaseApi.put(`Account/ChangePassword?oldPassword=${data.oldPassword}&Password=${data.password}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    UpdateUserInfo: (data, headers) => BaseApi.patch(`Account/UpdateUser/:${data.email}`, data.data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
  },
  Menu: {
    get: (data, headers) => BaseApi.get("Menu/MenuObj", data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
  },
  Users: {
    get: (data, headers) => BaseApi.get("Account/Users", data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getUserToken: (data, headers) => BaseApi.get(`Account/GetUser/${data.data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getUserPermissions: (data, headers) => BaseApi.get(`Account/UserPermissions/${data.data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getCompanyUsers: (data, headers) => BaseApi.get(`Account/Users/${data.data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getUserData: (data, headers) => BaseApi.get(`Account/GetUser/${data.email}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    addUser: (data, headers) => BaseApi.post("Account/Register", data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    updateUser: (data, headers) => BaseApi.patch(`Account/UpdateUser/${data.email}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    deleteUser: (data, headers) => BaseApi.delete(`Account/DeleteUser/${data.email}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token, }),
  },
  Companies: {
    get: (data, headers) => Api.get("Company/All", data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    addCompany: (data, headers) => Api.post("Company/Create", data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getCompany: (data, headers) => Api.get(`Company/${data.data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    updateCompany: (data, headers) => Api.patch(`Company/Update/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    deleteCompany: (data, headers) => Api.delete(`Company/Delete/${data.id}`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
  },
  Groups: {
    get: (data, headers) => Api.get("Account/AllGroups", data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getGroup: (data, headers) => Api.get(`/Account/GetGroup/${data.data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getGroupPermissions: (data, headers) => Api.get(`Account/GetGroupPermissions/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getUserGroups: (data, headers) => Api.get(`Account/UserGroups/${data.email}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    updateUserGroups: (data, headers) => Api.post("Account/UpdateUserGroups", data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    updateGroupPermissions: (data, headers) => Api.post(`Account/UpdateGroupPermissions`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    addGroup: (data, headers) => Api.post(`Account/CreateGroup`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    updateGroup: (data, headers) => Api.patch(`Account/UpdateGroup/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    deleteGroup: (data, headers) => Api.delete(`Account/DeleteGroup/${data.id}`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
  },
  partners: {
    add: (data, headers) => Api.post(`/secure/common/landing-page/partners`, data.body, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`/secure/common/landing-page/partners/${data.id}`, data.body, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`/secure/common/landing-page/partners/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('BusinessPartners/BusinessPartner', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`BusinessPartners/BusinessPartner/${data.code}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  documentTypes: {
    add: (data, headers) => Api.post(`FleetManagement/DocumentType`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`FleetManagement/DocumentType/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`FleetManagement/DocumentType/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('FleetManagement/DocumentType', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`FleetManagement/DocumentType/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  equipmentDocument: {
    add: (data, headers) => Api.post(`FleetManagement/EquipmentDocument`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`FleetManagement/EquipmentDocument/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`FleetManagement/EquipmentDocument/${data.code}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('FleetManagement/EquipmentDocument', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`FleetManagement/EquipmentDocument/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  equipmentMasterData: {
    add: (data, headers) => Api.post(`FleetManagement/EquipmentMasterData`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`FleetManagement/EquipmentMasterData/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`FleetManagement/EquipmentMasterData/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('FleetManagement/EquipmentMasterData', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`FleetManagement/EquipmentMasterData/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  contractType: {
    add: (data, headers) => Api.post(`FleetManagement/ContractType`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`FleetManagement/ContractType/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`FleetManagement/ContractType/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('FleetManagement/ContractType', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`FleetManagement/ContractType/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  equipmentType: {
    add: (data, headers) => Api.post(`FleetManagement/EquipmentType`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`FleetManagement/EquipmentType/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`FleetManagement/EquipmentType/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('FleetManagement/EquipmentType', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`FleetManagement/EquipmentType/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  maintenanceType: {
    add: (data, headers) => Api.post(`FleetManagement/MaintenanceType`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`FleetManagement/MaintenanceType/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`FleetManagement/MaintenanceType/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('FleetManagement/MaintenanceType', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`FleetManagement/MaintenanceType/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  repairType: {
    add: (data, headers) => Api.post(`FleetManagement/RepairType`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`FleetManagement/RepairType/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`FleetManagement/RepairType/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('FleetManagement/RepairType', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`FleetManagement/RepairType/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  tripMaster: {
    add: (data, headers) => Api.post(`FleetManagement/TripMaster`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`FleetManagement/TripMaster/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`FleetManagement/TripMaster/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('FleetManagement/TripMaster', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`FleetManagement/TripMaster/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  typeOfShipment: {
    add: (data, headers) => Api.post(`FleetManagement/TypeOfShipment`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`FleetManagement/TypeOfShipment/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`FleetManagement/TypeOfShipment/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('FleetManagement/TypeOfShipment', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`FleetManagement/TypeOfShipment/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  shipmentServiceMasterPlusERPLink: {
    add: (data, headers) => Api.post(`FleetManagement/ShipmentServiceMasterPlusERPLink`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`FleetManagement/ShipmentServiceMasterPlusERPLink/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`FleetManagement/ShipmentServiceMasterPlusERPLink/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('FleetManagement/ShipmentServiceMasterPlusERPLink', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`FleetManagement/ShipmentServiceMasterPlusERPLink/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  hrEmployee: {
    getAll: (data, headers) => Api.get(`HR/Employee?page=${data.page}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`HR/Employee/${data.code}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  InventoryItem: {
    getAll: (data, headers) => Api.get(`Inventory/Item?type=${data.type}&page=${data.page}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`Inventory/Item/${data.code}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  salesEmployee: {
    getAll: (data, headers) => Api.get('Administration/SalesEmployee', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`Administration/SalesEmployee/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  project: {
    getAll: (data, headers) => Api.get('Financials/Project', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`Financials/Project/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  systemInitializationSAP: {
    getDefalutConnection: (data, headers) => Api.get('SAP/Settings/DefalutConnection', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    addDefalutConnection: (data, headers) => Api.post(`SAP/Settings/DefalutConnection`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getTestConnection: (data, headers) => Api.get(`SAP/TestConnection`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  systemInitializationFoodics: {
    getTestConnection: (data, headers) => Api.get(`Foodics/Settings/IsConnected`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAuthLink: (data, headers) => Api.get(`Foodics/Settings/GetAuthLink`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    authorize: (data, headers) => Api.post(`Foodics/Settings/Authorize?code=${data.code}&state=${data.state}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    manualInvoiceSync: (data, headers) => Api.post(`Foodics/ManualInvoiceSync?from=${data.dateTime}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    manualItemsSync: (data, headers) => Api.post(`Foodics/ManualItemsSync?from=${data.dateTime}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    manualCustomersSync: (data, headers) => Api.post(`Foodics/ManualCustomersSync?from=${data.dateTime}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    manualSuppliersSync: (data, headers) => Api.post(`Foodics/ManualSuppliersSync?from=${data.dateTime}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    createSAPUD: (data, headers) => Api.post("Foodics/Settings/CreateSAPUD", {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    paymentMethods: (data, headers) => Api.get(`Foodics/PaymentMethods`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    chartOfAccount: (data, headers) => Api.get(`Financials/ChartOfAccount`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    syncPaymentMethod: (data, headers) => Api.get(`Foodics/Settings/SyncPaymentMethod`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  Branch: {
    add: (data, headers) => Api.post('Foodics/Settings/Branches', data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getAll: (data, headers) => Api.get('Foodics/Settings/Branches', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`Foodics/Settings/Branches/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    update: (data, headers) => Api.patch(`Foodics/Settings/Branches/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`Foodics/Settings/Branches/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  WareHouses: {
    add: (data, headers) => Api.post('Foodics/Settings/Warehouse', data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getAll: (data, headers) => Api.get('Foodics/Settings/Warehouse', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`Foodics/Settings/Warehouse/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    update: (data, headers) => Api.patch(`Foodics/Settings/Warehouse/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`Foodics/Settings/Warehouse/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  Tax: {
    add: (data, headers) => Api.post('Foodics/Settings/Tax', data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getAll: (data, headers) => Api.get('Foodics/Settings/Tax', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`Foodics/Settings/Tax/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    update: (data, headers) => Api.patch(`Foodics/Settings/Tax/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`Foodics/Settings/Tax/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  APExpenses: {
    get: (data, headers) => Api.get('Foodics/Settings/APExpenses', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    add: (data, headers) => Api.post(`Foodics/Settings/APExpenses?code=${data.code}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token, }),
  },
  homePage: {
    add: (data, headers) =>
      Api.post(`Administration/Settings/Dashboard/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    getAll: (data, headers) =>
      Api.get(
        `Administration/Settings/Dashboard/${data.id}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
  },
  Series: {
    getAll: (data, headers) =>
      Api.get(
        `Foodics/Settings/GetSeries/${data.id}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    SetSeries: (data, headers) =>
      Api.post(`Foodics/Settings/SetSeries?type=${data.type}`, data.data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    seriesTyps: {
      CustomerSerial: 0,
      VendorSerial: 1,
      ItemsSerial: 2,
      ProductsSerial: 3,
      CombosSerial: 4,
      GiftCardsSerial: 5,
      ModifierOptionsSerial: 6
    },
  },
  GenericHandlers: {
    get: (data, headers) => BaseApi.get(data.uri, data.body, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    getCompany: (data, headers) => Api.get(data.uri, data.body, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    post: (data, headers) => BaseApi.post(data.uri, data.body, { ...headers, cancelToken: requestsSourceCancellation.token, }),
  },
  APServiceContract: {
    add: (data, headers) =>
      Api.post(`/FleetManagement/ServiceContractOPSC`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`/FleetManagement/ServiceContractOPSC/${data.id}`, data.editValues, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.post(
        `/FleetManagement/ServiceContractOPSC/${data.id}/Cancel`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getAll: (data, headers) =>
      Api.get(
        '/FleetManagement/ServiceContractOPSC',
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `/FleetManagement/ServiceContractOPSC/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
  },
  ARServiceContract: {
    add: (data, headers) =>
      Api.post(`/FleetManagement/ServiceContractORSC`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`/FleetManagement/ServiceContractORSC/${data.id}`, data.editValues, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.post(
        `/FleetManagement/ServiceContractORSC/${data.id}/Cancel`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getAll: (data, headers) =>
      Api.get(
        '/FleetManagement/ServiceContractORSC',
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `/FleetManagement/ServiceContractORSC/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
  },
  APMaintenanceTransaction: {
    add: (data, headers) => Api.post(`FleetManagement/MaintenanceTrancstionOEMT`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`FleetManagement/MaintenanceTrancstionOEMT/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`FleetManagement/MaintenanceTrancstionOEMT/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    softDelete: (data, headers) => Api.post(`FleetManagement/MaintenanceTrancstionOEMT/${data.id}/Cancel`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('FleetManagement/MaintenanceTrancstionOEMT', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`FleetManagement/MaintenanceTrancstionOEMT/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  GIMaintenanceTransaction: {
    add: (data, headers) => Api.post(`FleetManagement/MaintenanceTrancstionOIMT`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`FleetManagement/MaintenanceTrancstionOIMT/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`FleetManagement/MaintenanceTrancstionOIMT/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('FleetManagement/MaintenanceTrancstionOIMT', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`FleetManagement/MaintenanceTrancstionOIMT/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  PaymentMethod: {
    add: (data, headers) => Api.post(`Foodics/Settings/PaymentMethod`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`Foodics/Settings/PaymentMethod/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`Foodics/Settings/PaymentMethod/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('Foodics/Settings/PaymentMethod', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`Foodics/Settings/PaymentMethod/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  Category: {
    add: (data, headers) => Api.post(`Foodics/Settings/Category`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`Foodics/Settings/Category/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`Foodics/Settings/Category/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('Foodics/Settings/Category', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`Foodics/Settings/Category/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  Charge: {
    add: (data, headers) => Api.post(`Foodics/Settings/Charge`, data, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    update: (data, headers) => Api.patch(`Foodics/Settings/Charge/${data.id}`, data.editValues, { ...headers, cancelToken: requestsSourceCancellation.token, }),
    delete: (data, headers) => Api.delete(`Foodics/Settings/Charge/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getAll: (data, headers) => Api.get('Foodics/Settings/Charge', {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`Foodics/Settings/Charge/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  foodicsLog: {
    getAll: (data, headers) => Api.get(`Administration/Log?from=${data.from}&to=${data.to}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(`Administration/Log/${data}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  attachment: {
    getFiles: (data, headers) => Api.get(`Administration/Attachment/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  FoodicsSetup: {
    checkup: (data, headers) => Api.get(`Foodics/IsRadyToSync`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    updateInfo: (data, headers) => Api.get(`Foodics/UpdateNames`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),

  }
};

export default endpoints;
