import Upload from "../../Components/From/Upload";

let validImgExtention = ["jpg", "jpeg", "bmp", "gif", "png", "txt"];
const handleChange = (fileObject) => {
  const file = fileObject;
  let reader = new FileReader();
  if (file) {
    let formData = new FormData();
    formData.append("file", file);
    return formData;
    // else {
    //     reader.onload = e => {
    //         let blobData = e.target.result; //blob data
    //         let obj = {
    //             src: blobData,
    //             type: 'video',
    //         };
    //     };
    //     reader.readAsDataURL(file);
    // }
  }
};
export { handleChange };
