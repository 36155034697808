

const topSection = 214;

const styles = theme => {
    return {
        menu: {
            width: "100%",
            height: `calc(100vh - ${topSection}px)`,
            "& .rst__rowContents": {
                backgroundColor: theme.palette.type == "dark" ? theme.palette.background.paper : "",
                color: theme.palette.type == "dark" ? "#fff" : "#000"
            },
            "& .rst__moveHandle": {
                background: `${theme.palette.primary.main} url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+) no-repeat center`,
            },
            "& .rst__collapseButton": {
                background: `${theme.palette.primary.main} url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==) no-repeat center`,
            },
        },
        header: {
            height: "50px",
            textAlign: "end",
            marginRight: "57px",
            marginTop: "20px",
        },
        node: {
            [theme.breakpoints.down('md')]: {
                width: '300px',
            },
            [theme.breakpoints.down('sm')]: {
                minWidth: '250px',
            },
            height: "50px",
            width: '600px',
        }
    }
};

export default styles;