import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(heme => ({
    img: {
        width: "100%",
        height: "100%",
        float: "left",
    },


}));

export default useStyles;