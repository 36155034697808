import { Button, withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { ActionButton, FormBuilder } from '~/Components/From';
import { asEntity } from '~/Hoc';
import { isEmpty } from '~/Services/Helpers';
import { NotificationContext } from '~/Services/Providers';
import { formData, formikProps, headerFormData } from './data';
import useStyles from "./styles";

class EmTabForm extends React.Component {
    static contextType = NotificationContext;
    loading = false;
    getLoading = false;
    actionType = "Add";
    code = this.props.match?.params?.id ? this.props.match.params.id : '';
    values = {}


    componentDidMount() {
        if (!!this.code) {
            this.getLoading = true;
            this.props.entityStore.get({ code: this.code });
        }
    }
    handleSubmit = (values, editValues = {}) => {
        this.loading = true;
        if (this.actionType !== "Edit") this.props.entityStore.post(values);
        else this.props.entityStore.patch({ id: values.repCode, editValues })
    };
    entityDidReceived(data) {
        if (!!data) {
            this.getLoading = false;
            let normalizedData = {};
            let DocumentType = data;
            //removing null
            Object.keys(DocumentType).map(key => {
                if (DocumentType[key] === null) normalizedData[key] = '';
                else normalizedData[key] = DocumentType[key];
            })
            this.values = normalizedData;
        }
    }
    entityDidPosted(data) {
        this.successActions('Company Added successfully');
    }

    entityDidUpdated(data) {
        this.successActions('Company Updated successfully')
    }
    successActions = (message) => {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'success',
            message
        }
        ))
        this.props.history.goBack()
    }
    entityDidCatch(errors) {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'error',
            message: isEmpty(errors?.title) ? "Something went wrong" : errors.title
        }
        ))
    }

    render() {
        const { classes } = this.props;



        return (
            <div className={classes.FormWrapper}>
                <FormBuilder {...formikProps}
                    formData={headerFormData}
                    formName="EmTabForm"
                    editValues={this.values}
                    submitAction={this.handleSubmit}
                    customStyle={{ flexBasis: "100%" }}
                >
                    <FormBuilder {...formikProps}
                        Tab={true}
                        formData={formData}
                        formName="EmTabForm"
                        editValues={this.values}
                        submitAction={this.handleSubmit}
                        customStyle={{ flexBasis: "100%" }}
                    >

                        <div style={{ flexBasis: "100%", display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.SubmitButton}
                                onClick={(e) =>
                                    !!this.code
                                        ? this.props.setView("show")
                                        : this.props.history.goBack()
                                }
                            >
                                Cancel
                   </Button>
                            <ActionButton
                                customClass={classes.SubmitButton}
                                title={this.code !== "" ? "Duplicate" : "Add"}
                                icon={this.code !== "" ? "add" : "arrow_forward"}
                                loading={this.loading}
                            />
                            {this.code !== "" && (
                                <ActionButton
                                    customClass={classes.SubmitButton}
                                    title="Update"
                                    icon="arrow_forward"
                                    loading={this.loading}
                                    onClick={() => (this.actionType = "Edit")}
                                />
                            )}
                        </div>
                    </FormBuilder>
                </FormBuilder>
            </div>

        )
    }
}


decorate(EmTabForm, {
    loading: observable,
})

export default withStyles(useStyles)(asEntity({ storeId: "EmployeesMasterDataForm" })(observer(EmTabForm)));