import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    employeeName: Yup.string().trim().required(),
    timeIn: Yup.date(),
    timeOut: Yup.date(),
    shiftMapped: Yup.string().trim(),

});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        employeeName: "",
        shiftMapped: "",
    },
};

const shiftItems = [
    { id: 0, name: "shift 1", value: "shift 1" },
    { id: 1, name: "shift 2", value: "shift 2" },
    { id: 2, name: "shift 3 ", value: "shift 3" },
]

export const formData = [
    { name: "employeeName", type: "text" },
    { name: "timeIn", title: "Time In", type: "time", width: "48%", focused: true, },
    { name: "timeOut", title: "Time Out", type: "time", width: "48%", focused: true, },
    { name: "shiftMapped", type: "dropdown", options: shiftItems },

];


