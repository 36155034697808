export const columns = [
    { name: "docNum", label: "Number", options: { filter: false, sort: true } },
    { name: "eqCodeNavigation", label: "Equipment Code", options: { filter: true, sort: true, 
         customBodyRender: (value, tableMeta, updateValue) => value?.equiName } },
    { name: "kiloMeters", label: "kilo metraj", options: { filter: true, sort: true, } },
    { name: "maintenanceType", options: { filter: true, sort: true, 
        customBodyRender: (value, tableMeta, updateValue) => value?.mainName } },
    { name: "date", label: "Date", options: { filter: true, sort: true, } },
];

export const DetailsColumns = [
    { name: "itemName", label: "Item", options: { filter: true, sort: true, } },
    { name: "workHour", label: "work Hour", options: { filter: false, sort: true } },
    { name: "qty", label: "Qty", options: { filter: true, sort: true, } },
    { name: "projectName", label: "Project", options: { filter: true, sort: true, } },
    { name: "warehouseName", label: "warehouse", options: { filter: true, sort: true, } },
    { name: "remark", label: "remark", options: { filter: true, sort: true, } },
];








