import { DescriptionRounded } from '@material-ui/icons';
import { isEmpty } from './'
export class PermissionsNormalizer {
    constructor(company) {
        let { title, permissions, type } = company;
        this.type = type;
        this.permissions = permissions;
        this.normalizedPermissions = {
            title,
            children: {}
        };
    }
    getUserAppNames() {
        let apps = [];
        this.permissions.forEach(permission => {
            let app = permission.split('.')[0];
            if (!apps.includes(app))
                apps.push(app);
        });
        return apps;
    }
    getGroupAppNames() {
        let apps = [];
        this.permissions.forEach(permission => {
            if (!apps.includes(permission.appName)) apps.push(permission.appName.toLowerCase());
        });
        return apps;
    }
    normalizeUserPermissions() {
        let apps = this.getUserAppNames(this.permissions);
        let children = this.normalizer(apps);
        this.normalizedPermissions.children = children;
    }
    normalizeGroupPermissions() {
        let apps = this.getGroupAppNames(this.permissions);
        let children = this.normalizer(apps);
        this.normalizedPermissions.children = children;
    }
    normalizer(apps) {
        let children = {};
        apps.forEach(app => {
            let temp = [];
            this.permissions.forEach(permission => {
                let name = this.type === "user" ? permission : permission.name;
                if (name.split('.')[0] === app) temp.push(name.split('.')[1])
            });
            children[app] = temp;
        });
        return children;
    }
    normalize() {
        switch (this.type) {
            case 'user':
                this.normalizeUserPermissions();
                break;
            case 'group':
                this.normalizeGroupPermissions();
                break;
        }
        return this.normalizedPermissions;
    }
}
function _sectionsArrayLengthCheck(data) {
    if (!data.children) {
        return data
    }
    if (data.children.length === 0) {
        return false
    }
    let haveSections = []
    for (let i = 0; i < data.children.length; i++) {
        haveSections[i] = _sectionsArrayLengthCheck(data.children[i])
    }
    let children = []
    for (let i = 0; i < data.children.length; i++) {
        if (haveSections[i]) { children.push(haveSections[i]) }
    }
    if (children[0])
        return { ...data, path: children[0].path, children }
    else {
        return false
    }
    // return { ...data, activeItem: sections[0].path, sections }
}
function _permissionCheck(data, permissions) {
    const activatePermission = process.env.REACT_APP_ACTIVATE_PERMISSIONS === "true" ? true : false;

    if (data.children && Array.isArray(data.children)) {
        let children = []
        for (let i = 0; i < data.children.length; i++) {
            let havePermission = _permissionCheck(data.children[i], permissions)
            if (havePermission)
                children.push(havePermission)
        }
        return { ...data, children }
    }
    else {
        if (activatePermission && data.permissions) {
            let havePermission = true;
            for (let i = 0; i < data.permissions.length; i++) {
                havePermission &= permissions.includes(data.permissions[i])
            }
            if (!havePermission) {
                return 0
            }
        }
        if (activatePermission && (!data.permissions || data.permissions.length === 0))
            return 0

        return data
    }

}
export const normalizeRoutData = (data = {}, permissions) => {
    let permissionCheckObj = {}
    let ArrayLengthCheck = {}
    Object.keys(data).map((key) => {
        //step1: looping over navData and start the permission Checker
        permissionCheckObj[key] = _permissionCheck(data[key], permissions)
    })
    //step2: Check if navData levels have sections 
    Object.keys(permissionCheckObj).map((key, index) => {
        let Data = _sectionsArrayLengthCheck(permissionCheckObj[key])
        if (Data && Data.children.length > 0) {
            ArrayLengthCheck[key] = Data
        }
    })
    return ArrayLengthCheck
}

export const normalizePatchData = (data) => {
    let normalizedData = [];

    Object.keys(data).forEach((key) => {
        normalizedData.push({
            op: "replace",
            path: `/${key}`,
            value: data[key],
        })
    })

    return normalizedData;
}

export const DataToColumns = (data, columnsValuesNormalizer =[]) => {
    let columns = []
    let columnsToNormalize = Object.keys(columnsValuesNormalizer)
    data = data.value ? data.value: data; 
    if (Array.isArray(data) && data.length !== 0) {
        Object.keys(data[0]).map((key, index) => {
            columns[index] = {
                name: `${key}`, label: `${key}`, options: {
                    filter: true, sort: true, display: index > 10 ? false : true,
                    customBodyRender: (value, tableMeta, updateValue) => isEmpty(value) 
                        ? "" 
                        :columnsToNormalize.includes(key) ? columnsValuesNormalizer[key](value) : value 
                }
            }
        })
    }
    return columns
}



const getSecondPath = (path) => path.includes('Code') 
    ? path.replace("Code", "Name") 
    : path.includes('code') 
        ? path.replace("code", "name")
        : path.includes('Card')
            ? path.replace("Card","Name")
            :  path.includes('ID')
                ? path.replace("ID", "Name")
                :path+"ID" ;
    
export const foodicsNameNormalizer  = (fields, data, editData = []) => {
    const values = {};
    let editValues = [];


    // normalized form data
    Object.keys(data).map(key => {
        if (fields.includes(key)) {
            let fieldValues = data[key].split('_#_');
            values[key] = fieldValues[1];
            const secondKey = getSecondPath(key);
            values[secondKey] = fieldValues[0];
        } else {
            values[key] = data[key]
        };
    });

    // normalize all edited field in the form only if the form is in edit state
    if (!isEmpty(editData)){
        editData.map(editedField => { 
            if(fields.some(field => editedField.path.includes(field))){
                let secondPath = getSecondPath(editedField.path); 
                let fieldValues= editedField.value.split('_#_');
                editValues = [
                    ...editValues,
                    {op : 'replace' ,  path: editedField.path , value : fieldValues[1] },
                    {op : 'replace' ,  path: secondPath , value : fieldValues[0] },
                ]
            }else{
                editValues = [...editValues ,editedField]
            }
        }); 
    }
        
    return {values , editValues};
}

export const foodicsNameDenormalizer =  (fields  , record) => {
    const denormalizedRecord = {};

    Object.keys(record).map(key => {
        if(fields.includes(key)){ 
            if (key.includes("Name")) return;
            else{
                denormalizedRecord[key] = `${record[getSecondPath(key)]}_#_${record[key]}`
            }
        }else{
            denormalizedRecord[key] = record[key]
        }
    })

    return denormalizedRecord;
}



export const autoGeneratedColumnsEnumNormalizer = (valuesObject) => (key) => valuesObject[key]
export const autoGeneratedColumnsObjectNormalizer = (key) => (valuesObject) => valuesObject[key]