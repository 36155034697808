import { Button, withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormBuilder, ActionButton } from '~/Components/From';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { formData, formikProps, formDependency } from './data';
import useStyles from "./styles";
import { isEmpty } from '~/Services/Helpers'
import { foodicsNameNormalizer, foodicsNameDenormalizer } from '~/Services/Helpers'

class ARServiceContractForm extends React.Component {
    static contextType = NotificationContext;
    loading = false; // true , false
    getLoading = false;
    actionType = "Add";
    values = {};
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    fieldsWithName = ['cardCode', 'projectCode','deliveryCard','salesEmployeeCode','fromCity','toCity'];

    componentDidMount() {
        if (!!this.id) {
            this.getLoading = true;
            this.props.entityStore.get(this.id);
        }
    }

    handleSubmit = (values, editValues = {}) => {
        this.loading = true;

        const { values: normalizedValues, editValues: normalizedEditValues } = foodicsNameNormalizer(this.fieldsWithName, values, editValues);

        if (this.actionType !== "Edit") this.props.entityStore.post(normalizedValues);
        else this.props.entityStore.patch({ id: values.docTypeCode, normalizedEditValues })
    };


    entityDidReceived(data) {
        if (!!data) {
            this.getLoading = false;
            let normalizedData = {};
            let DocumentType = data;
            //removing null
            Object.keys(DocumentType).map(key => {
                if (DocumentType[key] === null) normalizedData[key] = '';
                else normalizedData[key] = DocumentType[key];
            })
            this.values = foodicsNameDenormalizer(this.fieldsWithName, normalizedData);
        }
    }

    entityDidPosted(data) {
        this.successActions('AR Service Contract successfully');
    }

    entityDidUpdated(data) {
        this.successActions('AR Service Contract updated successfully')
    }

    successActions = (message) => {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'success',
            message
        }
        ))
        this.props.history.goBack()
    }

    entityDidCatch(errors) {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'error',
            message: isEmpty(errors.Message) ? errors : errors.Message
        }
        ))
    }

    render() {
        const { classes } = this.props;

        if (this.getLoading) {
            return <Loading />
        }

        return (
            <div className={classes.FormWrapper}>
                <FormBuilder {...formikProps}
                    formData={formData}
                    formDependency={formDependency}
                    formName="ARServiceContract"
                    editValues={this.values}
                    submitAction={this.handleSubmit}
                    customStyle={{ flexBasis: "100%" }}
                >
                    <div style={{ flexBasis: "100%", display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.SubmitButton}
                            onClick={(e) => !!this.id ? this.props.setView("show") : this.props.history.goBack()}
                        >
                            Cancel
                        </Button>
                        <ActionButton customClass={classes.SubmitButton} title={this.id !== '' ? 'Duplicate' : 'Add'}
                            icon={this.id !== '' ? 'add' : 'arrow_forward'}
                            loading={this.loading}
                        />
                        {
                            this.id !== '' &&
                            <ActionButton customClass={classes.SubmitButton} title="Update" icon="arrow_forward" loading={this.loading}
                                onClick={() => this.actionType = "Edit"}
                            />
                        }
                    </div>
                </FormBuilder>
            </div>

        )
    }
}


decorate(ARServiceContractForm, {
    loading: observable,
})

export default withStyles(useStyles)(asEntity({ storeId: "ARServiceContractForm" })(observer(ARServiceContractForm)));
