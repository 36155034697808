import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    Number : Yup.string().trim().required(), 
    CustomerCode : Yup.string().trim().required(), 
    CustomerName : Yup.string().trim().required(), 
    From : Yup.string().trim().required(), 
    To : Yup.string().trim().required(), 
    WithReturn : Yup.string().trim().required(), 
    ReturnRate : Yup.string().trim().required(), 
    Amount : Yup.string().trim().required(), 
    EquipmentCode_1 : Yup.string().trim().required(), 
    EquipmentCode_2 : Yup.string().trim().required(), 
    ProjectCode : Yup.string().trim().required(), 
    MaterialsType : Yup.string().trim().required(), 
    Attached : Yup.string().trim().required(), 
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate : false,
    },
    initialVals: {
        code: "",
        name : "",
        foreignName : "",
    },
};


export const formData = [
    { name: "Number", type: "text" , width : "33%" },
    { name: "CustomerCode", type: "text" , width : "33%" },
    { name: "CustomerName", type: "text" , width : "33%" },
    { name: "From", type: "text", width : "48%" },
    { name: "To", type: "text" , width : "48%"},
    { name: "WithReturn", type: "text", width : "48%" },
    { name: "ReturnRate", type: "text", width : "48%" },
    { name: "Amount", type: "text" , width : "48%"},
    { name: "EquipmentCode_1", type: "text" , width : "48%"},
    { name: "EquipmentCode_2", type: "text" , width : "48%"},
    { name: "ProjectCode", type: "text" , width : "48%"},
    { name: "MaterialsType", type: "text" , width : "48%"},
    { name: "Attached", type: "text", width : "48%" },
];