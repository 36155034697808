import React from 'react'
import VendorForm from '../Partials/VendorForm'
import useStyles from './styles'

function AddVendor(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <VendorForm {...props}  />
        </div>
    )
}

export default AddVendor;
