import React , { useState }from 'react'
import { FormHelperText, TextField, withStyles } from '@material-ui/core'
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { asEntity } from '~/Hoc';
import { onlyOneOfProps, isEmpty } from '~/Services/Helpers';
import Styles from './styles'
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropdownLoader } from '~/Components/Loaders'
import uuid from 'react-uuid';
const DropDownWrapper = ({ children, classes, width }) => (
    <div className={classes.root} style={{ flexBasis: !!width ? width : "100%" }}>
        <div className={classes.dropdownControl} >
            {children}
        </div>
    </div >
)
class Dropdown extends React.Component {
    value = '';
    options = [];
    loading = false;
    
    defaultValue = !!this.props.editValue ? this.props.editValue : this.value;
    dataFetchError = false;
    company =  isEmpty(this.props.company) ? true : this.props.company;  
    code = uuid();
    componentDidMount = () => {
        if (!!this.props.options) {
            this.defaultValue = !isEmpty(this.props.editValue)
                ? this.props.options.find((element => element.id == this.props.editValue))
                : this.value;

            this.options = this.props.options
            this.value = this.defaultValue;
            this.props.InputChange(this.props.name, this.defaultValue.id)
        }
        else {
            this.loading = true;
            this.props.entityStore.get({ company : this.company , uri: this.props.optionsURI, body: {} , code : this.code });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.optionsURI !== prevProps.optionsURI) {
            this.loading = true;
            this.props.entityStore.get({ company : this.company  , uri: this.props.optionsURI, body: {} , code : this.code });
        }
    }

    handleChange = (event, value) => {
        if (value) {
            this.value = value;
            this.props.InputChange(this.props.name, toJS(value).id);
        }
    };
    InputChange= (e,value,reason)=>{
        if(reason ==="input"){
            if(this.props.hasSearch){
                if (value.length>2) {
                    let nurl=`${this.props.optionsURI}${this.props.searchPre}${value}`;
                    this.props.entityStore.get({ company : this.company  , uri: nurl , body: {} , code : this.code });
                }
            }
        }
    };

    entityDidReceived(data) {

        if (!!data && data.code === this.code) {

            if (this.props.parseData) this.options = this.props.parseData(data.payload);
            else this.options = data.payload;
            
            for (let i = 0; i < toJS(this.options).length; i++) {
                if (toJS(this.options)[i].id === toJS(this.props.editValue)) {
                    this.defaultValue = toJS(this.options)[i]
                }
            }

            this.loading = false;
            this.props.InputChange(this.props.name, this.defaultValue.id)
            this.value = this.defaultValue;
        }

    }

    entityDidCatch(error) {
        if (error.uri === this.props.optionsURI) {
            this.dataFetchError = true;
        }
    }



    render() {
        let { error, errorText, name, width, placeholder, blur, classes, focused = true, dropDownLoading = true, disabled } = this.props;
        var getData = this.options.length > 0 ? true : false;
        if (this.dataFetchError && !getData) {
            return (
                // <DropDownWrapper classes={classes} width={width}>
                //     <h5 className={classes.DataFetchError}> Error fetching {name} data ! </h5>
                // </DropDownWrapper>
                
            <>
                <DropDownWrapper classes={classes} width={width}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={this.options}
                        value={this.value}
                        onChange={this.handleChange}
                        onInputChange= {this.InputChange}
                        getOptionLabel={(option) => option? option.name: `Select`}
                        style={{ flexBasis: !!width ? width : "100%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onBlur={blur}
                                label={placeholder}
                                variant="outlined"
                                name={name}
                                focused={focused}
                                disabled={disabled}

                            />
                        )}
                    />
                    {<FormHelperText error>{`Error fetching ${name} data !`}</FormHelperText>}
                </DropDownWrapper>
            </>

            )
        }

        if (this.loading && dropDownLoading) {
            return (
                <DropDownWrapper classes={classes} width={width}>
                    <DropdownLoader />
                </DropDownWrapper>
            )
        }

        return (
            <DropDownWrapper classes={classes} width={width}>
                <Autocomplete
                    id="combo-box-demo"
                    options={this.options}
                    value={this.value}
                    onChange={this.handleChange}
                    onInputChange= {this.InputChange}
                    getOptionLabel={(option) => option? option.name:"Select"}
                    style={{ flexBasis: !!width ? width : "100%" }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onBlur={blur}
                            label={placeholder}
                            variant="outlined"
                            name={name}
                            focused={focused}
                            disabled={disabled}

                        />
                    )}
                />
                {error && <FormHelperText error>{errorText}</FormHelperText>}
            </DropDownWrapper>

        )
    }
}


const onlyOneOfPropsData = {
    options: 'object',
    optionsURI: 'string'
}

Dropdown.propTypes = {
    options: onlyOneOfProps(onlyOneOfPropsData),
    optionsURI: onlyOneOfProps(onlyOneOfPropsData),
}

decorate(Dropdown, {
    options: observable,
    dataFetchError: observable,
    company: observable,
})


export default withStyles(Styles)(asEntity({ storeId: "GenericDropdown" })(observer(Dropdown)));