import React, { useState, createContext, useContext } from "react";
import { StorageKeys } from "~/Configs/Storage";

export const USER_INITIAL_STATE = {
  isAuthenticated: false,
  token: "",
  company: "",
  data: {},
  Permissions: [],
};

// initializing context
export const UserContext = createContext(USER_INITIAL_STATE);

// setting a helper function to use context
export const useUserContext = () => useContext(UserContext);

export function UserContextProvider({ children }) {
  let userFromLocalStorage = JSON.parse(window.localStorage.getItem(StorageKeys.userContext));
  const USER_STATE = userFromLocalStorage ? userFromLocalStorage : USER_INITIAL_STATE;
  const [userContextObj, setUserContext] = useState(USER_STATE);
  const setContext = (data) => {
    setUserContext((currContext) => ({ ...currContext, ...data }));
    window.localStorage.setItem(StorageKeys.userContext, JSON.stringify({ ...userContextObj, ...data }));
  };
  return (
    <UserContext.Provider value={{ userContextObj, setUserContext: setContext }}>
      {children}
    </UserContext.Provider>
  );
}
