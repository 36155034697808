import { Button, withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormBuilder, ActionButton } from '~/Components/From';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { formData, formikProps } from './data';
import useStyles from "./styles";
import { FormattedMessage } from 'react-intl';
import { isEmpty } from '~/Services/Helpers';

class ItemDataForm extends React.Component {
    static contextType = NotificationContext;
    loading = false; // true , false
    actionType = "Add";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';

    values = {};


    handleSubmit = (values, editValues = {}) => {
        this.loading = true;
        let data = {
            series: values.SAPSeriesCode,
            group: values.SAPItemGroup,
        }
        this.props.entityStore.post({ data, type: this.props.id });
    };

    entityDidPosted(data) {
        this.notify('item successfully');
        this.props.setView("show");
    }

    entityDidCatch(errors) {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'error',
            message: isEmpty(errors?.title) ? "Something went wrong" : errors.title
        }
        ))
    }

    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }

    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.FormWrapper}>
                <FormBuilder {...formikProps}
                    formData={formData}
                    formName="ItemsMasterData"
                    editValues={this.values}
                    submitAction={this.handleSubmit}
                    customStyle={{ flexBasis: "100%" }}
                >
                    <div style={{ flexBasis: "100%", display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.SubmitButton}
                            onClick={(e) => this.props.setView("show")}
                        >
                            <FormattedMessage id="Cancel" />
                        </Button>
                        <ActionButton customClass={classes.SubmitButton} title="Update" icon="arrow_forward" loading={this.loading}
                            onClick={() => this.actionType = "Edit"}
                        />
                    </div>
                </FormBuilder>
            </div>

        )
    }
}


decorate(ItemDataForm, {
    loading: observable,
})

export default withStyles(useStyles)(asEntity({ storeId: "SetSeries" })(observer(ItemDataForm)));
