

export const columns = [
    { name: "ActivityNumber",  options: { filter: false, sort: true } },
    { name: "BPCode",  options: { filter: true, sort: true, } },
    { name: "Remarks", options: { filter: true, sort: true, } },
    { name: "ActivityDate", options: { filter: true, sort: true, } },
    { name: "Telephone", options: { filter: true, sort: true, } },
    { name: "Fax",  options: { filter: true, sort: true, } },
];


export const data = [
    { ActivityNumber: "1", BPCode: "C10000003", Remarks: "N/A", ActivityDate: "11/16/2020", Telephone: "9665324321", Fax: "234234" },
    { ActivityNumber: "2", BPCode: "C10000004", Remarks: "N/A", ActivityDate: "11/17/2020", Telephone: "9665324321", Fax: "234423" },
    { ActivityNumber: "3", BPCode: "C10000005", Remarks: "N/A", ActivityDate: "11/18/2020", Telephone: "9665324321", Fax: "435323" },
    { ActivityNumber: "4", BPCode: "C10000006", Remarks: "N/A", ActivityDate: "11/19/2020", Telephone: "9665324321", Fax: "434322" },
]

