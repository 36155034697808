import React from 'react'
import { decorate, observable, toJS } from 'mobx';
import { FormHelperText, Button, withStyles } from '@material-ui/core';
import { AddOutlined } from "@material-ui/icons";
import { observer } from 'mobx-react';
import { asEntity } from '~/Hoc';
import { onlyOneOfProps, isEmpty } from '~/Services/Helpers';
import Styles from './styles';
import CustomToolbar from "~/Components/EnhancedTable/Partials/Toolbar";
import MUIDataTable from "mui-datatables";
import { DataToColumns } from "~/Services/Helpers/Normalizers";
import { FormattedMessage } from 'react-intl';
import EditModal from '~/Components/Modal';
import { FormBuilder, ActionButton } from '~/Components/From';


const TableWrapper = ({ children, classes, width }) => (
    <div className={classes.root} style={{ flexBasis: !!width ? width : "100%" }}>
        <div className={classes.TableControl} >
            {children}
        </div>
    </div>
)

class Table extends React.Component {
    tableData = [];
    loading = false;
    dataFetchError = false;
    isModalOpen = false;
    editMode = false;

    componentDidMount = () => {
        if (!!this.props.tableData) {
            this.tableData = this.addId(toJS(this.props.tableData));
            let preProcessColumns = [{}]
            for (let i = 0; i < this.props.formData.length; ++i) {
                preProcessColumns[0][`${this.props.formData[i].name}`] = this.props.formData[i]["name"];
            }
            const columns = DataToColumns(preProcessColumns, this.props.columnsOptions);
            this.columns = columns ? columns : [];
            this.columns = this.translatedColumns();
            this.props.InputChange(this.props.name, [...toJS(this.tableData)]);
        }
        else if (this.props.editValue) {
            this.tableData = this.addId(toJS(this.props.editValue))
            this.props.InputChange(this.props.name, [...toJS(this.tableData)]);
        }
    }

    normalizeDateValues = (values) => {
        let normalizedValues = {};
        Object.keys(values).forEach(key => {
            if(key.toLowerCase().includes('date')){
                normalizedValues[key] = values[key].toLocaleDateString('en-GB');
            }else{
                normalizedValues[key] = values[key];
            }
        });
        return normalizedValues;
    }
    addId = (tableData) => {
        let data = tableData
        for (let i = 0; i < data.length; i++) {
            data[i].TableId = i
        }
        return data

    }
    translatedColumns = () => this.columns.map(column => ({
        ...column,
        options: { ...column.options, customHeadLabelRender: () => <FormattedMessage id={`${this.props.name}.${column.name}`} /> }
    }
    ))
    handleChange = (values, editValues = {}) => {
        let normalizedValues = this.normalizeDateValues(values)
        console.log(normalizedValues)
        if (!this.editMode) {
            this.tableData = this.addId([...toJS(this.tableData), { ...normalizedValues }]);
            this.props.InputChange(this.props.name, toJS(this.tableData));
        }
        else {
            let tableData = [...toJS(this.tableData)];
            tableData[this.editIndex] = normalizedValues;
            this.tableData = tableData
        }
        this.toggleModal();
    }
    deleteAction = (selectedRows, displayData) => {
        let Ids = [];
        for (let i = 0; i < selectedRows.data.length; i++) {
            const rowIndex = selectedRows.data[i].dataIndex;
            Ids.push(toJS(this.tableData)[rowIndex].TableId);
        }
        this.tableData = toJS(this.tableData).filter(
            (data) => !Ids.includes(data.TableId)
        );
        this.props.InputChange(this.props.name, toJS(this.tableData));
    };
    entityDidReceived(data) {
        if (!!data && data.uri === this.props.optionsURI) {
            if (this.props.parseData) this.tableData = this.props.parseData(data.payload);
            else this.tableData = data.payload;
            this.loading = false;
            const columns = DataToColumns(this.tableData, {})
            this.columns = columns ? columns : []
        }
    }
    entityDidCatch(error) {
        if (error.uri === this.props.optionsURI) {
            this.dataFetchError = true;
        }
    }
    toggleModal = () => {
        this.isModalOpen = !this.isModalOpen
        if (!this.isModalOpen) {
            this.editValues = {}
            this.editMode = false;
        }
    }
    render() {
        let { error, errorText, name, width, placeholder, classes, } = this.props;
        const options = {
            filterType: "dropdown",
            onRowClick: (rowInfo, { dataIndex }) => {
                this.editValues = this.tableData[dataIndex];
                this.editMode = true;
                this.editIndex = dataIndex
                this.toggleModal();
            },
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <CustomToolbar
                    deleteAction={this.deleteAction}
                    selectedRows={selectedRows}
                    displayData={displayData}
                    setSelectedRows={setSelectedRows}
                />
            ),
            setRowProps: () => ({ style: { cursor: "pointer" } }),
            enableNestedDataAccess: '.',
            onDownload: (buildHead, buildBody, columns, data) => {
              return "\uFEFF" + buildHead(columns) + buildBody(data);
            },
        };

        if (this.loading) {
            return (
                <TableWrapper classes={classes} width={width}>
                    {/* <DropdownLoader /> */}
                </TableWrapper>
            )
        };
        return (
            <>
                <TableWrapper classes={classes} width={width}>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            onClick={() => this.isModalOpen = true}
                            style={{ margin: 15 }}
                            color="secondary"
                            variant="contained"
                            endIcon={<AddOutlined />}
                        >
                            <FormattedMessage id={"add"} />  <FormattedMessage id={`${this.props.name}`} />
                        </Button>
                        <div style={{ textAlign: "left" }}>
                            <MUIDataTable
                                title={placeholder}
                                data={this.tableData}
                                columns={toJS(this.columns)}
                                options={options}
                            />
                        </div>
                    </div>
                    {error && <FormHelperText error>{errorText}</FormHelperText>}
                </TableWrapper>
                <EditModal
                    open={this.isModalOpen}
                    handleClose={this.toggleModal}
                    // title={addMode ? `Add` : `Edit`}
                    title="add"
                >
                    <FormBuilder
                        {...this.props.formikProps}
                        formData={this.props.formData}
                        formName={name}
                        editValues={this.editValues}
                        submitAction={this.handleChange}
                        TabControl={true}
                    >
                        <div className={classes.buttons_wrapper}>
                            <Button variant='outlined' color='secondary' onClick={this.toggleModal} className={classes.cancel_button}>
                                Close
                            </Button>
                            <ActionButton
                                title='Save'
                                icon='arrow_forward'
                                variant='contained'
                                loading={this.loading}
                                className={classes.submit_button}
                            />
                        </div>
                    </FormBuilder>
                </EditModal>
            </>

        )
    }
}


const onlyOneOfPropsData = {
    tableData: 'object',
    optionsURI: 'string'
}

Table.propTypes = {
    tableData: onlyOneOfProps(onlyOneOfPropsData),
    optionsURI: onlyOneOfProps(onlyOneOfPropsData),
}

decorate(Table, {
    tableData: observable,
    columns: observable,
    isModalOpen: observable,
    editValues: observable,
    dataFetchError: observable,
})


export default withStyles(Styles)(asEntity({ storeId: "GenericTable" })(observer(Table)));