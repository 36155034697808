import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import styles from './styles';
import { ActionButton } from '~/Components/From';
import TextArea from '../../../../Components/From/TextArea';
import ApprovalForm from "../ApprovalForm"

class ViewApproval extends React.Component {
    view = "show";

    data = { requestName: "Vacation Request", requestType: "Vacation Request", status: "Yes" };
    loading = false;


    componentDidMount() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 600);
    }
    setView = (view) => {
        this.view = view;
    }


    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {

                    this.view === "show" &&
                    <>
                        <ShowView title="Approval Details."
                            data={this.data}
                            showViewName="Approvals"
                        // setView={this.setView}

                        />


                        <div className={classes.Edit}>
                            <ApprovalForm {...this.props} setView={this.setView} />
                        </div>
                    </>

                }
            </div>
        )
    }
}

decorate(ViewApproval, {
    view: observable,
    loading: observable,

})




export default withStyles(styles)(asEntity({ storeId: "ApprovalForm" })(observer(ViewApproval)));
