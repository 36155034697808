export const columns = [
    { name: "leaveCode", options: { filter: false, sort: true, display: true } },
    { name: "leaveName", options: { filter: true, sort: true, } },
    { name: "daysBalance", options: { filter: true, sort: true, } },
    { name: "details", options: { filter: false, sort: false, } },
];

export const detailsColumns = [
    { name: "id", options: { filter: false, sort: true, display: true } },
    { name: "from", options: { filter: false, sort: true, display: true } },
    { name: "to", options: { filter: false, sort: true, display: true } },
]


export const LeaveTransactionsData = [
    { leaveCode: 'fd231', leaveName: 'Vacation', daysBalance: 10, },
    { leaveCode: '2df234', leaveName: 'Work mission', daysBalance: 8, },
    { leaveCode: '2as3423', leaveName: 'Break', daysBalance: 8, },
    { leaveCode: 'df4234', leaveName: 'Emergency', daysBalance: 2, },
]
export const LeaveTransactionsDetailsData = [
    { id: '121', from: 'Feb 8th 2020', to: 'Feb 10th 2020', },
    { id: '122', from: 'Nov 8th 2020', to: 'Nov 10th 2020', },
    { id: '123', from: 'Dec 8th 2020', to: 'Dec 10th 2020', },
]



