
export const columns = [
    { name: "foodicsID", options: { filter: true, sort: true, } },
    { name: "foodicsName", options: { filter: true, sort: true, } },
    { name: "sapCode", options: { filter: true, sort: true, } },
    { name: "sapName", options: { filter: true, sort: true, } },
];






