import React from 'react'
import PaymentMethodsForm from '../Partials/PaymentMethodsForm'
import useStyles from './styles'

function AddPaymentMethods(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <PaymentMethodsForm {...props} />
        </div>
    )
}

export default AddPaymentMethods;
