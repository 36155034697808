import Playground from "../Screens/Playground";
import Print from "../Screens/Print";
import { EmptyPage } from "~/Components/ErrorPages";
import Login from "../Screens/Auth/Login";
import Profile from "../Screens/Profile";
import Register from "../Screens/Auth/Register";
import * as Setup from '../Screens/Administration/Setup';
import * as Inventory from '../Screens/Inventory';
import * as BusinessPartners from '../Screens/BusinessPartners';
import * as Service from '../Screens/Service';
import * as HR from '../Screens/HR';
import * as FMO from "../Screens/FleetManagementOperation";
import ViewSystemInitializationSAP from "../Screens/Administration/SystemInitialization/General-Settings-SAP/Edit-View";
import ViewSystemInitializationFoodics from "../Screens/Administration/SystemInitialization/General-Settings-Foodics/Edit-View";
import RouteNavigator from "./RouteNavigator";
import * as ESS from '../Screens/ESS';
import { AddARServiceContract, ViewARServiceContract, BrowseARServiceContract } from "../Screens/Administration/Transaction/ARServiceContract"
import { AddAPServiceContract, ViewAPServiceContract, BrowseAPServiceContract } from "../Screens/Administration/Transaction/APServiceContract"
import { AddAPMaintenanceTransaction, ViewAPMaintenanceTransaction, BrowseAPMaintenanceTransaction } from "../Screens/Administration/Transaction/APMaintenanceTransaction"
import { AddGIMaintenanceTransaction, ViewGIMaintenanceTransaction, BrowseGIMaintenanceTransaction } from "../Screens/Administration/Transaction/GIMaintenanceTransaction"
import { AddManHouMaintenanceTransaction, ViewManHouMaintenanceTransaction, BrowseManHouMaintenanceTransaction } from "../Screens/Administration/Transaction/ManHouMaintenanceTransaction";
import { BrowseFoodicsLog, ViewFoodicsLog } from "../Screens/FoodicsLog"
import { getPrivateRoutes } from "../Services/Helpers/menu";
import AutoSync from "../Screens/Administration/AutoSync/Edit-View"

export const data = [

  { component: Profile, Slug: "user-profile" },
  { components: [Setup.AddEquipmentType, Setup.ViewEquipmentType, Setup.BrowseEquipmentType], Slug: "equipment-type" },
  { components: [Setup.AddDocumentTypes, Setup.ViewDocumentTypes, Setup.BrowseDocumentTypes], Slug: "document-types" },
  { components: [Setup.AddContractTypes, Setup.ViewContractTypes, Setup.BrowseContractTypes], Slug: "contract-types" },
  { components: [Setup.AddMaintenanceTypes, Setup.ViewMaintenanceTypes, Setup.BrowseMaintenanceTypes], Slug: "maintenance-types" },
  { components: [Setup.AddSalesEmployee, Setup.ViewSalesEmployee, Setup.BrowseSalesEmployee], Slug: "sales-employee" },
  { components: [Setup.AddShipmentServiceMaster, Setup.ViewShipmentServiceMaster, Setup.BrowseShipmentServiceMaster], Slug: "shipment-service-master" },
  { components: [Setup.AddSalesEmployee, Setup.ViewSalesEmployee, Setup.BrowseSalesEmployee], Slug: "sales-employee" },
  { components: [Setup.AddTripMaster, Setup.ViewTripMaster, Setup.BrowseTripMaster], Slug: "trip-master" },
  { components: [Setup.AddTypeOfShipment, Setup.ViewTypeOfShipment, Setup.BrowseTypeOfShipment], Slug: "type-of-shipment" },
  { components: [Setup.AddRepairType, Setup.ViewRepairType, Setup.BrowseRepairType], Slug: "repair-type" },
  { components: [FMO.AddEquipmentMasterData, FMO.ViewEquipmentMasterData, FMO.BrowseEquipmentMasterData], Slug: "equipment-master-data" },
  { component: Setup.BrowseFoodicsSetup, Slug: "foodics-setup" },
  { components: [Setup.AddBranch, Setup.ViewBranch], Slug: "foodics-setup", addToPath: "/Branch" },
  { components: [Setup.AddWareHouse, Setup.ViewWareHouse], Slug: "foodics-setup", addToPath: "/WareHouse" },
  { components: [Setup.AddItemMasterData, Setup.ViewItemMasterData], Slug: "foodics-setup", addToPath: "/ItemMasterData" },
  { components: [Setup.AddCategory, Setup.ViewCategory], Slug: "foodics-setup", addToPath: "/Category" },
  { components: [Setup.AddCharge, Setup.ViewCharge], Slug: "foodics-setup", addToPath: "/Charge" },
  { components: [Setup.AddCustomer, Setup.ViewCustomer], Slug: "foodics-setup", addToPath: "/Customer" },
  { components: [Setup.AddVendor, Setup.ViewVendor], Slug: "foodics-setup", addToPath: "/Vendor" },
  { components: [Setup.AddTax, Setup.ViewTax], Slug: "foodics-setup", addToPath: "/Tax" },
  { components: [Setup.AddPaymentMethods, Setup.ViewPaymentMethods], Slug: "foodics-setup", addToPath: "/PaymentMethods" },
  { components: [BusinessPartners.AddBusinessPartnerData, BusinessPartners.ViewBusinessPartnerData, BusinessPartners.BrowseBusinessPartnerData], Slug: "business-partners-master-data" },
  { components: [BusinessPartners.AddActivity, BusinessPartners.ViewActivity, BusinessPartners.BrowseActivity], Slug: "activity" },
  { components: [Service.AddEquipmentData, Service.ViewEquipmentData, Service.BrowseEquipmentData], Slug: "equipment-data" },
  { components: [Service.AddServiceContract, Service.ViewServiceContract, Service.BrowseServiceContract], Slug: "service-contract" },
  { components: [Service.ViewMaintenanceTransactions, Service.AddMaintenanceTransactions, Service.BrowseMaintenanceTransactions], Slug: "maintenance-transaction" },
  { components: [HR.AddEmployeesData, HR.ViewEmployeesData, HR.BrowseEmployeesData], Slug: "employee-master-data" },
  { components: [Setup.AddProject, Setup.ViewProject, Setup.BrowseProject], Slug: "project" },
  { component: ViewSystemInitializationSAP, Slug: "general-settings-sap" },
  { component: ViewSystemInitializationFoodics, Slug: "general-settings-foodics" },
  { components: [Inventory.AddItFixedAssets, Inventory.ViewItFixedAssets, Inventory.BrowseItFixedAssets], Slug: "items-fixed-assets" },
  { components: [Inventory.AddItTravel, Inventory.ViewItTravel, Inventory.BrowseItTravel], Slug: "items-travel" },
  { components: [Inventory.AddItLabor, Inventory.ViewItLabor, Inventory.BrowseItLabor], Slug: "items-labor" },
  { components: [Inventory.AddItItems, Inventory.ViewItItems, Inventory.BrowseItItems], Slug: "items" },

  { components: [ESS.AddRequestForLetters, ESS.ViewRequestForLetters, ESS.BrowseRequestForLetters], Slug: "request-for-letters" },

  { components: [ESS.AddBot, ESS.ViewBot, ESS.BrowseBot], Slug: "bot" },
  { components: [AddARServiceContract, ViewARServiceContract, BrowseARServiceContract], Slug: "ar-service-contract" },
  { components: [AddAPServiceContract, ViewAPServiceContract, BrowseAPServiceContract], Slug: "ap-service-contract" },
  { components: [AddAPMaintenanceTransaction, ViewAPMaintenanceTransaction, BrowseAPMaintenanceTransaction], Slug: "ap-maintenance-transaction" },
  { components: [AddGIMaintenanceTransaction, ViewGIMaintenanceTransaction, BrowseGIMaintenanceTransaction], Slug: "gi-maintenance-transaction" },
  { components: [AddManHouMaintenanceTransaction, ViewManHouMaintenanceTransaction, BrowseManHouMaintenanceTransaction], Slug: "man-hou-maintenance-transaction" },
  { components: [ESS.AddTimeAttendance, ESS.ViewTimeAttendance, ESS.BrowseTimeAttendance], Slug: "time-and-attendance" },
  { components: [ESS.AddClaimRequest, ESS.ViewClaimRequest, ESS.BrowseClaimRequest], Slug: "claim-request" },
  { components: [ESS.AddLoansAdvancesReimbursements, ESS.ViewLoansAdvancesReimbursements, ESS.BrowseLoansAdvancesReimbursements], Slug: "loans-advances-reimbursements" },
  { components: [ESS.AddAssignActivity, ESS.ViewAssignActivity, ESS.BrowseAssignActivity], Slug: "assign-activity-task" },
  { components: [ESS.AddRequestBusinessTrip, ESS.ViewRequestBusinessTrip, ESS.BrowseRequestBusinessTrip], Slug: "request-business-trip" },
  { components: [ESS.AddReqTerminationEndContract, ESS.ViewReqTerminationEndContract, ESS.BrowseReqTerminationEndContract], Slug: "termination-end-contract" },
  { components: [ESS.ViewApproval, ESS.ViewApproval, ESS.BrowseApproval], Slug: "approval" },
  { components: [ESS.AddVacationRequest, ESS.ViewVacationRequest, ESS.BrowseVacationRequest,], Slug: "vacation-request" },
  { component: ESS.EmployeeDetails, Slug: "view-employee-details" },
  { component: ESS.ManagesDetails, Slug: "view-manages-details" },
  { component: ESS.ViewUpdateEmployeeInfo, Slug: "update-employee-information" },

  { components: [ESS.ViewPayslip, ESS.ViewPayslip, ESS.BrowsePayslip], Slug: "payslip" },
  { components: [EmptyPage, ViewFoodicsLog, BrowseFoodicsLog], Slug: "log" },
  { component: ESS.OrganizationHierarchy, Slug: "organization-hierarchy" },
  { component: AutoSync, Slug: "auto_sync" },
];

export let privateRoutes = false

export const privateRoutesGet = () => privateRoutes

export const privateRoutesSet = () => privateRoutes = getPrivateRoutes(RouteNavigator.data(), data)


export const authRoutes = [
  { path: "/login", component: Login },
  { path: "/register", component: Register },
]

export const publicRoutes = [
  { path: "/pg", component: Playground },
  { path: "/test", component: ViewSystemInitializationFoodics },
  { path: "/print/:id", component: Print },
];
