export const syncButtonsData = [
    {label  : "Create SAP UD" , successIcon : "settings_input_antenna" , failIcon : "portable_wifi_off" , toolTipTitle : "Create SAP UD" , withDate : false,} , 
    {label  : "Invoice Sync" , successIcon : "sync" , failIcon : "sync_problem" , toolTipTitle : "Invoice Sync" , withDate : true },
    {label  : "Items Sync" , successIcon : "sync" , failIcon : "sync_problem" , toolTipTitle : "Items Sync" , withDate : true },
    {label  : "Customers Sync" , successIcon : "sync" , failIcon : "sync_problem" , toolTipTitle : "Customers Sync" , withDate : true },
    {label  : "Suppliers Sync" , successIcon : "sync" , failIcon : "sync_problem" , toolTipTitle : "Suppliers Sync" , withDate : true },
] 


export const syncTypes =  {
    "Create SAP UD" : "createSAPUD",
    "Invoice Sync" : "manualInvoiceSync",
    "Items Sync" : "manualItemsSync",
    "Customers Sync" : "manualCustomersSync",
    "Suppliers Sync" : "manualSuppliersSync",
}


