import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Table from '~/Components/EnhancedTable';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { DataToColumns } from "~/Services/Helpers/Normalizers";
import { NotificationContext } from '~/Services/Providers';
import { columnsValuesNormalizer } from './data';


class BusinessPartners extends React.Component {
  static contextType = NotificationContext;
  tableData = [];
  loading = false;
  columns = []

  componentDidMount() {
    this.loading = true;
    this.props.entityStore.get();
  }

  entityDidReceived(data) {
    if (!!data) {
      const columns = DataToColumns(data, columnsValuesNormalizer)
      this.columns = columns ? columns : []
      this.tableData = columns.length > 0 ? data : [];
      this.tableData = data;
      this.loading = false;
    }
  }

  entityDidCatch(error) {
    this.loading = false;
    this.notify("error", error.title)

  }

  onDeleteConfirm = (idsArray) => {
    idsArray.map(id => {
      this.props.entityStore.delete({ id });
    });
  }
  entityDidDelete() {
    this.notify("Success", "Row/s was deleted successfully");
  }

  notify = (severity, message) => {
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity,
      message
    }))
  }

  render() {

    if (this.loading) {
      return <Loading />
    }
    const options = {
      enableNestedDataAccess: '.',
      selectableRows: "none"
    };

    return (
      <Table
        showKeyIndex={0}
        addButtonText="Add Business Partner"
        title="BusinessPartners"
        defaultData={this.tableData}
        columns={toJS(this.columns)}
        parentDeleteAction={this.onDeleteConfirm}
        customOptions={options}
      />
    )
  }
}

decorate(BusinessPartners, {
  tableData: observable.shallow,
  loading: observable,
  columns: observable,
})

export default asEntity({ storeId: 'BrowseBusinessPartners' })(observer(BusinessPartners));