

export const columns = [
    { name: "employeeName", label: "Employee Name", options: { filter: false, sort: true, display: true } },
    { name: "timeIn", label: "Time In", options: { filter: false, sort: true, display: true } },
    { name: "timeOut", label: "Time Out", options: { filter: false, sort: true, display: true } },
    { name: "shiftMapped", label: "Shift Mapped", options: { filter: false, sort: true, display: true } },

];




export const data = [
    { employeeName: "Name 1", timeIn: "9:07 AM", timeOut: "12:07 AM", shiftMapped: "shift 1" },
    { employeeName: "Name 2", timeIn: "1:07 AM", timeOut: "6:07 PM", shiftMapped: "shift 2" },
    { employeeName: "Name 3", timeIn: "7:07 PM", timeOut: "11:07 PM", shiftMapped: "shiftMapped 3" },
    { employeeName: "Name 4", timeIn: "1:07 AM", timeOut: "6:07 PM", shiftMapped: "shift 2" },
    { employeeName: "Name 5", timeIn: "7:07 PM", timeOut: "11:07 PM", shiftMapped: "shift 3" },
    { employeeName: "Name 6 ", timeIn: "9:07 AM", timeOut: "12:07 AM", shiftMapped: "shift 1" },
    { employeeName: "Name 7", timeIn: "1:07 AM", timeOut: "6:07 PM", shiftMapped: "shift 2" },
    { employeeName: "Name 8", timeIn: "7:07 PM", timeOut: "11:07 PM", shiftMapped: "shift 3" },

]



