import { FormControl, makeStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from '~/Services/Helpers'

const useStyles = makeStyles(theme => ({
    Input: {
        width: "100%",
        margin: `${theme.spacing(2)}px`
    }
}))

function Input({ type, editDisabled, disabled, editState, customClasses, value, focused, error, size, errorText, InputChange, width, name, blur, labelWidth, placeholder, controlled }) {
    const defaultClasses = useStyles();
    const classes = !!customClasses ? customClasses : defaultClasses;


    return (
        <div style={{ flexBasis: !!width ? width : "100%", minWidth: 200 }}>
            <FormControl className={classes.Input} variant="outlined" >
                <TextField
                    label={placeholder}
                    variant="outlined"
                    type={type}
                    name={name}
                    size={!!size ? size : 'medium'}
                    onChange={InputChange}
                    error={error}
                    value={value}
                    disabled={isEmpty(disabled) ? (!!editDisabled && editState) || controlled : disabled}
                    onBlur={blur}
                    {...(!!focused ? { focused } : {})}
                    helperText={!!errorText ? errorText : ''}
                />
            </FormControl>
        </div>
    )
}

Input.defaultProps = {
    customClasses: null,
};

Input.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.object.isRequired,
    customClasses: PropTypes.object,
}

export default Input;