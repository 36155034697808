import * as Yup from "yup";
import { FAParseData, costCenterParseData, eqTypeCodeParseData, docTypeParseData } from './parsers'

export const validationSchema = Yup.object().shape({
    eqCode: Yup.string().trim().required(),
    equiName: Yup.string().trim().required(),
    frgnName: Yup.string().trim(),
    eqType: Yup.string().trim().required(),
    factorName: Yup.string().trim(),
    modelCode: Yup.string().trim(),
    model: Yup.number().nullable(),
    serialNum: Yup.string().trim(),
    facode: Yup.string().trim(),
    costCenterCode: Yup.string().trim().required(),

});


export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        eqCode: "",
        equiName: "",
        frgnName: "",
        eqType: "",
        factorName: "",
        modelCode: "",
        serialNum: "",
        facode: "",
        costCenterCode: "",
       // costCenterDimensionsCode: "",
    },
};


export const formData = [
    { name: "eqCode", type: "text", width: "48%", editDisabled: true },
    { name: "equiName", type: "text", width: "48%" },
    { name: "frgnName", type: "text", width: "48%" },
    { name: "eqType", type: "dropdown", width: "48%", company: true, optionsURI: "/FleetManagement/EquipmentType", parseData: eqTypeCodeParseData },
    { name: "factorName", type: "text", width: "48%" },
    { name: "modelCode", type: "text", width: "48%" },
    { name: "model", type: "number", width: "48%" },
    { name: "serialNum", type: "text", width: "48%" },
    { name: "facode", type: "dropdown", width: "48%", company: true, optionsURI: "/Inventory/Item/Short?type=3", parseData: FAParseData,hasSearch:true, searchPre:"&search=" },
    { name: "costCenterCode", type: "dropdown", width: "48%", company: true, optionsURI: "/Financials/ProfitCenters", parseData: costCenterParseData ,hasSearch:true, searchPre:"?search="},
    //{ name: "costCenterDimensionsCode", type: "text", width: "48%" },
];


