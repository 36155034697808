import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import CustomerForm from '../Partials/CustomerForm';
import styles from './styles';
import api from "~/Services/Api";
import { NotificationContext } from '~/Services/Providers';


class ViewCustomer extends React.Component {
    static contextType = NotificationContext;

    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {};
    loading = false;



    componentDidMount() {
        this.getData();
    }

    getData() {
        this.loading = true;
        this.props.entityStore.get({ id: api.Series.seriesTyps.CustomerSerial })

    }
    setView = (view) => {
        this.view = view;
        if (view === "show") {
            this.getData()
        }
    }


    entityDidReceived(data) {
        if (!!data) {
            this.data = data;
            this.loading = false;
        }
    }

    entityDidCatch({ error, id }) {
        this.notify("error", error.title)
        this.data = { "setting": " Settings are not found" };
        this.loading = false;
    }
    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }

    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="Customers Info."
                        data={this.data}
                        setView={this.setView}
                        showViewName="Customers"
                        shouldValueCheck={false}
                    />
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <CustomerForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewCustomer, {
    view: observable,
})




export default withStyles(styles)(asEntity({ storeId: "BrowseSeries" })(observer(ViewCustomer)));
