import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    SlpCode: Yup.string().trim().required(),
    SlpName: Yup.string().trim().required(),
    Memo: Yup.string().trim().required(),
    Commission: Yup.string().trim().required(),
    GroupCode: Yup.string().trim().required(),
    Locked: Yup.string().trim().required(),
    DataSource: Yup.string().trim().required(),
    UserSign: Yup.string().trim().required(),
    EmpID: Yup.string().trim().required(),
    Active: Yup.string().trim().required(),
    Telephone: Yup.string().trim().required(),
    Mobil: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        SlpCode: "",
        SlpName: "",
        Memo: "",
        Commission: "",
        GroupCode: "",
        Locked: "",
        DataSource: "",
        UserSign: "",
        EmpID: "",
        Active: "",
        Telephone: "",
        Mobil: "",
    },
};


export const formData = [
    { name: "SlpCode", type: "text", editDisabled: true },
    { name: "SlpName", type: "text" },
    { name: "Memo", type: "text", },
    { name: "Commission", type: "text", },
    { name: "GroupCode", type: "text", },
    { name: "Locked", type: "text", },
    { name: "DataSource", type: "text", },
    { name: "UserSign", type: "text", },
    { name: "EmpID", type: "text", },
    { name: "Active", type: "text", },
    { name: "Telephone", type: "text", },
    { name: "Mobil", type: "text", },
];