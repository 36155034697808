import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import RequestForLettersForm from '../Partials/RequestForLettersForm';
import styles from './styles';


class ViewRequestForLetters extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';


    data = { lettersType: "To Whom It May Concern", requestNo: "1", entityName: "entityName1", status: "status", name: "name", basicSalary: "basicSalary", allowances: "allowances", position: "position", numOfYears: "NumOfYears", purpose: "purpose", remarks: "remarks" };

    loading = false;


    componentDidMount() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 600);
    }
    setView = (view) => {
        this.view = view;
    }


    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="Request For Letters Details."
                        data={this.data}
                        showViewName="RequestForLetters"
                        setView={this.setView}
                    />
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <RequestForLettersForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewRequestForLetters, {
    view: observable,
    loading: observable,

})




export default withStyles(styles)(asEntity({ storeId: "RequestForLettersForm" })(observer(ViewRequestForLetters)));
