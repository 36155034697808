const styles = theme => ({
  deleteIcon: {
    marginLeft: '20px',
    cursor: 'pointer',
    backgroundColor: 'white',
    marginBottom: 5,
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 250,
  },
  container: {
    width: '100%',
    margin: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: { fontSize: 14, margin: 5 },
  delete_icon: { color: 'red' },
  empty_message: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '20px'
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: '10px'
    },
    color: theme.palette.primary.main, width: '100%'

  },
  button: {
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: '10px',
      width: '100%',
    },

    backgroundColor: theme.palette.secondary.main,
    height: '30px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  icon: { color: '#fff' },
  dropzone: { outline: 'none' },
});
export default styles;
