import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflow: 'scroll',
        [theme.breakpoints.up('sm')]: {
            display: "flex",
        },
        [theme.breakpoints.down("sm")]: {
            display: "inherit",
            position: 'fixed',

            left: '4% !important'
        },

    },
    modalWrapper: {
        overflow: 'scroll',
        background: theme.palette.common.white,
        outline: "none",
        borderRadius: "10px",
        overflowX: 'hidden',
        padding: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            minWidth: "600px",
            maxWidth: "750px",
        },
        [theme.breakpoints.down("sm")]: {
            width: '90%'
        },
    },
    header: {
        fontFamily: "Montserrat",
        fontSize: "24px",
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        textAlign: "left",
        textTransform: "capitalize",
        paddingLeft: theme.spacing(2),
        marginTop: "20px",
        marginBottom: "10px",
    },
}));

export default useStyles;


