import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import GeneralForm from '../Partials/GeneralForm'
import styles from './styles';
import Avatar from '@material-ui/core/Avatar';


class ViewEmployeesMasterData extends React.Component {
    view = "show";
    code = this.props.match?.params?.id ? this.props.match.params.id : '';
    loading = false;
    data = {
        General: {}
    }
    headerData = {}
    componentDidMount() {
        if (!!this.code) {
            this.loading = true;
            this.props.entityStore.get({ code: this.code })
        }
    }
    setView = (view) => {
        this.view = view;
    }

    PasswordFix = (password) => {
        return !!password ? password.length * "*" : "No record"
    }
    arrayFix = (array) => {
        return array.length > 0 ? array.join(" , ") : "No record"
    }
    decamelize(str, separator) {
        separator = typeof separator === 'undefined' ? ' ' : separator;
        return str
            .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
            .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
            .toLowerCase();
    }
    dataTOShowView = (object, options = {}) => {
        let data = {}
        Object.keys(object).map((key) => {
            if (options[key]) {
                data[`${this.decamelize(key)} : `] = options[key](object[key])
            } else data[`${this.decamelize(key)} : `] = object[key]
        })
        return data
    }
    entityDidReceived(data) {
        if (!!data) {
            this.loading = false;
            const { employeeID, lastName, firstName, middleName, gender, jobTitle, employeeType, department,
                branch, workStreet, workBlock, workZipCode, workCity, workCounty, workCountryCode, workStateCode,
                manager, applicationUserID, salesPersonCode, officePhone, officeExtension, mobilePhone, pager, homePhone,
                fax, eMail, startDate, statusCode, salary, salaryUnit, employeeCosts, employeeCostUnit, terminationDate
                , treminationReason, bankCode, bankBranch, bankBranchNum, bankAccount, homeStreet, homeBlock, homeZipCode
                , homeCity, homeCounty, homeCountry, homeState, dateOfBirth, countryOfBirth, martialStatus, numOfChildren,
                idNumber, citizenshipCountryCode, passportNumber, passportExpirationDate, picture, remarks,
                salaryCurrency, employeeCostsCurrency, workBuildingFloorRoom, homeBuildingFloorRoom, position
                , attachmentEntry, costCenterCode, companyNumber, vacationPreviousYear, vacationCurrentYear
                , municipalityKey, taxClass, incomeTaxLiability, religion, partnerReligion, exemptionAmount,
                exemptionUnit, exemptionCurrency, additionalAmount, additionalUnit, additionalCurrency,
                taxOfficeName, taxOfficeNumber, healthInsuranceName, healthInsuranceCode, healthInsuranceType,
                socialInsuranceNumber, professionStatus, educationStatus, personGroup, jobTitleCode, bankCodeForDATEV,
                deviatingBankAccountOwner, spouseFirstName, spouseSurname, externalEmployeeNumber, birthPlace
                , paymentMethod, stdCode, cpf, crcNumber, accountantResponsible, legalRepresentative, dirfResponsible
                , crcState, active, idType, bplid, passportIssueDate, passportIssuer, qualificationCode, prWebAccess,
                previousPRWebAccess, workStreetNumber, homeStreetNumber, linkedVendor, createDate, createTime,
                updateDate, updateTime, employeeCode, employeeAbsenceInfoLines, employeeEducationInfoLines,
                employeeReviewsInfoLines, employeePreviousEmpoymentInfoLines, employeeRolesInfoLines,
                employeeSavingsPaymentInfoLines, employeeBranchAssignment, profitCenters
                , employeeRoleSetup, department2, branch2, country, brthCountrNavigation, citizenshpNavigation
                , workCountrNavigation, salesPerson, bank, employeePosition, profitCenter, businessPartner,
                businessPartners, items, warehouses
            } = data;

            const Membership = { employeeRolesInfoLines }

            const Person = { gender, dateOfBirth, countryOfBirth, martialStatus, numOfChildren, idNumber, citizenshipCountryCode, passportExpirationDate, passportNumber, passportIssueDate, passportIssuer }

            const Finance = { salary, employeeCosts, bank, bankAccount, branch }

            const Address = {
                workStreet, workStreetNumber, workBlock, workBuildingFloorRoom, workZipCode, workCity, workCounty, workStateCode, workCountryCode,
                homeStreet, homeStreetNumber, homeBlock, homeBuildingFloorRoom, homeZipCode, homeCity, homeCounty, homeState, homeCountry
            }

            const Administration = { startDate, statusCode, terminationDate, treminationReason }

            this.data = {
                Address: this.dataTOShowView(Address, {}),
                Membership: this.dataTOShowView(Membership, { employeeRolesInfoLines: this.arrayFix }),
                Administration: this.dataTOShowView(Administration, {}),
                Person: this.dataTOShowView(Person, {}),
                Finance: this.dataTOShowView(Finance, {}),
            }
            this.headerData = {
                "First Name": firstName,
                "last Name": lastName,
                "Middle Name": middleName,
                "Job Title": jobTitle,
                "Position": position,
                "Department": department,
                "employeeBranchAssignment": this.arrayFix(employeeBranchAssignment),
                "manager": manager,
                "User Code": salesPersonCode,
                "Cost Center Code": costCenterCode,
                "Employee No": employeeID,
                "Employee Code": employeeCode,
                "Ext. Employee Number": externalEmployeeNumber,
                "Active": active,
                "Office Phone": officePhone,
                "Office Extension": officeExtension,
                "Mobile Phone": mobilePhone,
                "Pager": pager,
                "Home Phone": homePhone,
                "Fax": fax,
                "linked Vendor": linkedVendor,

            }

        }
    }
    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <>
                        <Avatar variant="rounded" src={this.data.picture ? this.data.picture : ""} className={classes.Avatar} />
                        <ShowView title="Employee master Data. "
                            data={this.headerData}
                        // setView={this.setView}
                        />

                        <ShowView title=""
                            Tab={true}
                            data={this.data}
                        // setView={this.setView}
                        />
                    </>
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <GeneralForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewEmployeesMasterData, {
    view: observable,
})




export default withStyles(styles)(asEntity({ storeId: "EmployeesMasterDataForm" })(observer(ViewEmployeesMasterData)));
