export const eqTypeCodeParseData = (data) => {
    const positionData = []
    Array.isArray(data) && data.forEach(element => {

        positionData.push({
            name: `${element.eqTypeCode}-${element.eqName}`,
            id: element.eqTypeCode
        })
    });
    return positionData;
}

export const costCenterParseData = (data) => {
    const positionData = []
    Array.isArray(data) && data.forEach(element => {

        positionData.push({
            name: `${element.centerCode}-${element.centerName}`,
            id: element.centerCode
        })
    });
    return positionData;
}

export const FAParseData = (data) => {
    const FAData = []
    Array.isArray(data) && data.forEach(element => {

        FAData.push({
            name: `${element.itemCode}-${element.itemName}`,
            id: element.itemCode
        })
    });
    return FAData;
}

export const docTypeParseData = (data) => {
    const docTypeData = []
    Array.isArray(data) && data.forEach(element => {
        docTypeData.push({
            name: `${element.docTypeCode}-${element.docName}`,
            id: element.docTypeCode
        })
    });
    return docTypeData;
}