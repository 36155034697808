import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    foodicsID: Yup.string().required(),
    accountCode: Yup.string().required(),
    type: Yup.number().required()
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        foodicsID: "",
        accountCode: "",
        type: 0
    },
};
const FoodicsID = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}`,
            id: `${element.name}_#_${element.id}`
        })
    });
    return Options;
}

const ChartOfAccount = (data) => {
    const Options = []
    data=data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.code}-${element.name}`,
            id: `${element.name}_#_${element.code}`
        })
    });
    return Options;
}
const PaymentType = [
    { id: 0, name: "Cash" },
    { id: 1, name: "BankTransfer" },
]

export const formData = [
    { name: "foodicsID", width: "48%", company: true, type: "dropdown", optionsURI: "/Foodics/PaymentMethods", parseData: FoodicsID },
    { name: "accountCode", width: "48%", company: true, type: "dropdown", optionsURI: "Financials/ChartOfAccount/Short", parseData: ChartOfAccount },
    { name: "type", type: "dropdown", options: PaymentType, width: "48%", },
];