import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 200,
    textAlign: 'left',
    display : 'flex',
    flexDirection : 'column', 
    position : 'relative', 
    right : -14
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  placeholder: {
    color: theme.palette.primary.main,
  },
  textareaControl: {
    width: '100%',
    margin: `${theme.spacing(2)}px`,
  },
  input: {
    height: 55,
    borderRadius: 5,
    backgroundColor: '#fff',
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: '#eaeaea',
    '&:hover': { borderColor: theme.palette.secondary.main },
    boxShadow: 'none',
    paddingLeft: 10,
  },
  error: { fontSize: '12px', color: 'rgb(244, 67, 54)', margin: 5, height: 20 },
  title: {
    fontSize: 14,
    textAlign: 'left',
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
}));

export default useStyles;
