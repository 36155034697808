import React from 'react';
import useStyles from './styles';
import { Typography, Box, Select, MenuItem, Paper, Button, Hidden } from '@material-ui/core'
import { Facebook, Twitter, LinkedIn, ArrowForward, ArrowBack } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { useAppContext } from '../../../../Services/Providers'
import { toAbsoluteUrl } from '../../../../Services/Helpers';
import { setStorage } from '~/Services/Helpers';


const AuthScreen = (props) => {
    const classes = useStyles();
    const { appContextObj, setAppContext } = useAppContext();
    const { welcomeMsg, children } = props;
    const handleLangChange = (event) => {
        setAppContext((currentContext) => {
            return {
                ...currentContext,
                direction: event.target.value === "ar" ? "rtl" : "ltr",
                locale: event.target.value
            }
        })
        setStorage('locale', event.target.value);
    }
    return (
        <div className={classes.root} >
            <div className={classes.AuthSide} >
                <div>
                    <img className={classes.Logo} src={toAbsoluteUrl("/logo-light.png")} />
                    <div className={classes.WelcomeMsg} >
                        <h2>{welcomeMsg.text}</h2>
                        <span>{welcomeMsg.subText}</span>
                    </div>
                    <Select className={classes.LangSelect} variant="filled" defaultValue={appContextObj.locale} onChange={handleLangChange}>
                        {
                            Object.keys(appContextObj.langs).map(key =>
                                <MenuItem selected={key == appContextObj.locale} height="16" value={appContextObj.langs[key].code}>
                                    {appContextObj.langs[key].icon} &nbsp;
                                    {appContextObj.langs[key].text}
                                </MenuItem>
                            )
                        }
                    </Select>
                </div>
            </div>

            <div className={classes.FormWrapper}>
                <Hidden mdUp >
                    <img className={classes.LogoMobile} src={toAbsoluteUrl("/logo-light.png")} />
                </Hidden>
                <div className={classes.HeadTitle}>
                    <Typography color="primary" variant="h4" component="h1" >
                        {props.title}
                    </Typography>


                    <Link style={{ textDecoration: 'none' }} to={props.link.url}>
                        <Button
                            endIcon={appContextObj.locale === "en" ? <ArrowForward /> : <ArrowBack />} color="primary"
                        >
                            {props.link.text}
                        </Button>
                    </Link>

                </div>
                {children}
                {!!props.socialDivider &&
                    <section className={classes.SocialAction}>
                        <h5 className={classes.divider} >
                            <span> {props.socialDivider}  </span>
                        </h5>
                        <Button
                            variant="contained"
                            startIcon={<Facebook style={{ color: "#3b5998" }} />}
                            href="#some-link"
                        >
                            Facebook
                    </Button>
                        <Button
                            variant="contained"
                            startIcon={<Twitter style={{ color: "#1c9cea" }} />}
                            href="#some-link"
                        >
                            Twitter
                    </Button>
                        <Button
                            variant="contained"
                            startIcon={<LinkedIn style={{ color: "#0e76a8" }} />}
                            href="#some-link"
                        >
                            LinkedIn
                    </Button>
                    </section>}

            </div>
        </div>
    )
}


export default AuthScreen;
