import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Table from '~/Components/EnhancedTable';
import TableButton from '~/Components/EnhancedTable/Partials/TableActions/Partials/TableActionButton';
import Loading from '~/Components/Loading';
import Modal from '~/Components/Modal';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { ClaimTransactionsData, ClaimTransactionsDetailsData, columns, detailsColumns } from './data';


class ClaimTransactions extends React.Component {
    static contextType = NotificationContext;
    tableData = ClaimTransactionsData;
    loading = false;
    modalOpen = false;

    onDeleteConfirm = (idsArray) => {
        // idsArray.map(id => {
        //   this.props.entityStore.delete({ id });
        // });
    }

    toggleModal = () => {
        this.modalOpen = !this.modalOpen;
    }

    
  
    entityDidDelete() {
        this.notify("Success", "Row/s was deleted successfully");
    }
    
  

    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }


    render() {
        const customTableOptions = {
            onRowClick: (row) => this.toggleModal()
        }

        const DetailsButton =  (a ,b ,c ) =>  {  return <TableButton icon="visibility" title="Show Details" />}

        const normalizeColumns = (columns) => {
            const normalizedColumns = columns.map(column => {
                if(column.name !== 'details') return column
                else return {...column , options : {...column.options , 
                    customBodyRender : DetailsButton
                }}    
            })
            return normalizedColumns;
        }

        if (this.loading) {
            return <Loading />
        }

        return (
            <React.Fragment>
                <Modal open={this.modalOpen}  
                    handleClose={this.toggleModal}
                    title="Leave Transaction Details"
                >
                    <Table
                        showKeyIndex={0}
                        addButtonText="ClaimTransactionsDetail"
                        title="ClaimTransactionsDetails"
                        defaultData={ClaimTransactionsDetailsData}
                        columns={detailsColumns}
                        parentDeleteAction={this.onDeleteConfirm}
                    />
                </Modal>
                <Table
                    showKeyIndex={0}
                    addButtonText="ClaimTransaction"
                    title="ClaimTransactions"
                    defaultData={this.tableData}
                    columns={normalizeColumns(columns)}
                    customOptions={customTableOptions}
                    parentDeleteAction={this.onDeleteConfirm}
                />

            </React.Fragment>
        )
    }
}

decorate(ClaimTransactions, {
    tableData: observable.shallow,
    loading: observable,
    modalOpen: observable,
})

export default asEntity({ storeId: 'ClaimTransactions' })(observer(ClaimTransactions));
