import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import BranchForm from '../Partials/BranchForm';
import styles from './styles';


class ViewPayment extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {};
    loading = false;
    topics = [];

    
    componentDidMount() { 
        if (!!this.id) {
            this.loading = true;
            this.props.entityStore.get(this.id)
        }
    }

    setView = (view) => {
        this.view = view;
    }

    
    entityDidReceived(data) {
        if (!!data) {
            this.loading = false;
            this.data= data;
        }
    }
    
    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="Branch Info."
                        data={this.data}
                        setView={this.setView}
                        showViewName="Branches"
                    />
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <BranchForm {...this.props} 
                        setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewPayment, {
    view: observable,
})




export default withStyles(styles)(asEntity({ storeId: "BranchForm" })(observer(ViewPayment)));
