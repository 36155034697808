

export const columns = [
    { name: "empId", options: { filter: true, sort: true, display: true } },
    { name: "loanTypeCode", options: { filter: true, sort: true, display: true } },
    { name: "loanAmount", options: { filter: true, sort: true, display: true } },
    { name: "distributionStartDate", options: { filter: true, sort: true, display: true } },
    { name: "distributionEndDate", options: { filter: true, sort: true, display: true } },
    { name: "NoOfMonths", options: { filter: true, sort: true, display: true } },
];




export const data = [
    { empId: "emp 1", loanTypeCode: "code 1", loanAmount: "loan Amount", distributionStartDate: "Distribution Start Date", distributionEndDate: "Distribution End Date", NoOfMonths: "5 Months" },
    { empId: "emp 2", loanTypeCode: "code 2", loanAmount: "loan Amount", distributionStartDate: "Distribution Start Date", distributionEndDate: "Distribution End Date", NoOfMonths: "6 Months" },
    { empId: "emp 3", loanTypeCode: "code 3", loanAmount: "loan Amount", distributionStartDate: "Distribution Start Date", distributionEndDate: "Distribution End Date", NoOfMonths: "8 Months" },
    { empId: "emp 4", loanTypeCode: "code 4", loanAmount: "loan Amount", distributionStartDate: "Distribution Start Date", distributionEndDate: "Distribution End Date", NoOfMonths: "9 Months" },

]



