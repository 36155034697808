import React from 'react'
import RepairTypeForm from '../Partials/RepairTypeForm'
import useStyles from './styles'

function AddRepairType(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <RepairTypeForm {...props}  />
        </div>
    )
}

export default AddRepairType;
