import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    shipCode: Yup.string().trim().required(),
    shipName: Yup.string().trim(),
    frgnName: Yup.string().trim(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        shipCode: "",
        shipName: "",
        frgnName: "",
    },
};


export const formData = [
    { name: "shipCode", type: "text", editDisabled: true },
    { name: "shipName", type: "text" },
    { name: "frgnName", type: "text", },
];