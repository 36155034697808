const useStyles = (theme) => ({
    root : {
        textAlign : "center",
        paddingBottom : theme.spacing(3),
        display : "flex",
        
    },
    SubmitButton : {
        marginTop : theme.spacing(4),
        width : "400px"
    }
  
});


export default useStyles;