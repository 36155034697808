import React, { useState, useEffect } from 'react'
import { FormGenerator } from '../'
import { AppBar, Tab, Tabs, } from '@material-ui/core'
import useStyles from './styles'

function TabFormGenerator({ formData, ...props }) {
    const classes = useStyles()
    const [activeTab, setActiveTab] = useState(Object.keys(formData)[0])
    const TabsKeys = Object.keys(formData);
    const { errors } = props;

    const getErrorTabIndex = (fieldName) => {
        const errorKeys = TabsKeys.filter(Tab => formData[Tab].find(field => field.name === fieldName))
        return errorKeys[0];
    }

    useEffect(() => {
        if (!!errors && Object.keys(errors).length !== 0) {
            setActiveTab(getErrorTabIndex(Object.keys(errors)[0]))
        }
        else {
            setActiveTab(Object.keys(formData)[0])
        }
    }, [errors]);
    
    return (
        <div className={classes.appBar}>
            <AppBar position='static' >
                <Tabs
                    value={activeTab} onChange={(event, value) => setActiveTab(value)}
                    variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs"
                >
                    {TabsKeys.map((key, index) => {
                        return <Tab key={key} value={key} label={key} {...TabProps('' + index)} />
                    })}
                </Tabs>
            </AppBar>
            {TabsKeys.map((key, index) => <div
                role="tabpanel"
                hidden={activeTab !== key}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
            >
                <FormGenerator formData={formData[key]} {...props} />
            </div>
            )}
        </div>
    )
}


function TabProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
export default TabFormGenerator
