import { Button, withStyles } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import EditModal from '~/Components/EditModal';
import Table from '~/Components/EnhancedTable';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import EquipmentMasterDataForm from '../Partials/EquipmentMasterDataForm';
import { columns } from './Partials/data';
import ItemForm from './Partials/ItemForm';
import styles from './styles';


class ViewEquipmentMasterData extends React.Component {
    isModalOpen = false;
    static contextType = NotificationContext;
    tableData = [];
    dataArr = [];
    editValues = {};
    equipmentDocumentEditValues = {};
    EValues = {};
    view = "show";
    actionType;
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {};
    EquipmentDocumentData = {};
    loading = false;
    eqCode = '';

    toggleModal = (type = "show", row = '') => {
        this.equipmentDocumentEditValues =
        {
            "docNum": row[0],
            "docTypeCode": row[1],
            "issueDate": row[2],
            "expDate": row[3],
            "issuePlace": row[4]
        };
        this.actionType = type;
        this.isModalOpen = !this.isModalOpen;
    }
    refresh = () => {
        this.dataArr = [];
        this.loading = true;
        this.props.entityStore.get({ body: this.id, type: "EquipmentMasterData" })
    }

    componentDidMount() {
        if (!!this.id) {
            this.loading = true;
            this.props.entityStore.get({ body: this.id, type: "EquipmentMasterData" })
        }
    }

    setView = (view) => {
        this.view = view;
    }

    entityDidReceived({ data, type }) {
        console.log(`type`, type);
        console.log(`data`, data);
        if (!!data) {

            if (type == "EquipmentMasterData") {
                let { eqCode, facode, equiName, frgnName, eqType, factorName, costCenterCode, model, modelCode, serialNum } = data;
                this.data = {
                    eqCode, facode, equiName, frgnName, eqType, factorName, costCenterCode, model, modelCode, serialNum
                }
                this.editValues = this.data;
                this.eqCode = eqCode;
                this.props.entityStore.get({ body: data.eqCode, type: "equipmentDocument" })
            } else if (type == "equipmentDocument") {
                this.tableData = data;
                this.loading = false;
            }
        }
    }

    entityDidCatch(error) {
        this.loading = false;
        this.notify("error", error)

    }
    onDeleteConfirm = (idsArray) => {
        idsArray.map(id => {
            this.props.entityStore.delete({ code: id });
        });
    }
    entityDidDelete() {
        this.refresh();
        this.notify("Success", "Row/s was deleted successfully");
    }
    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }



    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <>
                        <ShowView title="Equipment Master Data  Info."
                            data={this.data}
                            setView={this.setView}
                            showViewName="EquipmentsMasterData"
                        />
                        <div className={classes.tableRoot}>
                            <Button
                                onClick={() => this.toggleModal()}
                                className={classes.AddButton}
                                color="secondary"
                                variant="contained"
                                endIcon={<AddOutlined />}
                            >
                                Add Equipment Document
                        </Button>
                            <Table
                                handleOpen={this.toggleModal}
                                showKeyIndex={0}
                                addButtonText="EquipmentDocument"
                                title="EquipmentDocument"
                                defaultData={this.tableData}
                                columns={columns}
                                parentDeleteAction={this.onDeleteConfirm}
                            />
                        </div>
                        <EditModal
                            open={this.isModalOpen}
                            handleClose={this.toggleModal}
                            title={`Equipment Document`}
                        >
                            <ItemForm
                                handleClose={this.toggleModal}
                                editValues={this.actionType == "show" ? {} : this.equipmentDocumentEditValues}
                                handelSubmit={() => { }}
                                refresh={this.refresh}
                                code={this.eqCode}
                                actionType={this.actionType}
                            />
                        </EditModal>
                    </>

                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <EquipmentMasterDataForm editValues={this.editValues} {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewEquipmentMasterData, {
    view: observable,
    isModalOpen: observable,
    tableData: observable,
    eqCode: observable,
    actionType: observable,

})




export default withStyles(styles)(asEntity({ storeId: "EquipmentMasterDataForm" })(observer(ViewEquipmentMasterData)));
