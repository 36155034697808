import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
    appBarWrap: {
        flexGrow: 1,
        width: '100%',
    },
    appBar: {
        marginTop: "-7px"

    },

}))

export default useStyles;