import {  Toolbar, IconButton, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useState } from 'react';
import NotificationPopup from '~/Components/Notification/NotificationPopup';
import useStyles from './styles';


const EnhancedTableToolbar = ({ selectedRows, displayData, setSelectedRows, deleteAction, children }) => {
    const classes =useStyles();
    const [ShowDeleteConfirm, setShowDeleteConfirm] = useState(false)

    const DeleteMessage = () => <div className={classes.deleteMessage}>
        Are you sure you want to delete the selected Rows <br />
        <div className={classes.modalBtnWrapper}>
            <Button className={classes.modalButton} onClick={cancelDelete} variant="contained" color="error">
                Cancel
            </Button>
            <Button className={classes.modalButton} onClick={deleteRows} variant="contained" color="primary">
                Yes
            </Button>

        </div>
    </div>

    const deleteRows = () => {
        deleteAction(selectedRows, displayData)
        setShowDeleteConfirm(false);
        setSelectedRows([])
    }
    const cancelDelete = () => {
        setShowDeleteConfirm(false);
        setSelectedRows([])
    }


    return (
        <>
            <NotificationPopup
                open={ShowDeleteConfirm}
                vertical='bottom'
                horizontal='center'
                severity='warning'
                message={<DeleteMessage />}
            />
            <Toolbar>
                <IconButton onClick={() => setShowDeleteConfirm(true)} aria-label="delete" className={classes.margin}>
                    <Delete />
                </IconButton>
                <Button className={classes.modalButton} onClick={() => setSelectedRows([])} variant="contained" color="default">
                    Cancel
                </Button>
                {children}
            </Toolbar>
        </>
    );
};

EnhancedTableToolbar.propTypes = {

};

export default EnhancedTableToolbar;
