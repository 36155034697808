

import React, { useState, useEffect } from 'react';
import { List, ListSubheader } from '@material-ui/core';
import useStyles from './styles';
import { getStorage, setStorage } from '~/Services/Helpers';
import { StorageKeys } from '~/Configs/Storage';
import { useHistory } from 'react-router-dom';
import UrlItemForm from './UrlItemForm';
import EditModal from "~/Components/EditModal";
import Tooltip from '@material-ui/core/Tooltip';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { WidthProvider, Responsive } from "react-grid-layout";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Loading from '~/Components/Loading';
import api from "~/Services/Api";
import { useNotification } from '~/Services/Providers'
import { useUserContext } from '~/Services/Providers'
import uuid from 'react-uuid';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function Profile() {
  const { setNotification } = useNotification();
  const { userContextObj, setUserContext } = useUserContext();
  const token = getStorage(StorageKeys.token);
  
  const [loading, setLoading] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const history = useHistory()
  const classes = useStyles()
  const [Urls, setUrls] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const [layouts, setLayouts] = useState(JSON.parse(JSON.stringify(getFromLS("layouts") || {})));
  let lastVisited = getStorage(StorageKeys.lastVisited) ? JSON.parse(getStorage(StorageKeys.lastVisited)) : [];
  const [type, setType] = useState("add");
  const options = {
    headers: { Authorization: "Bearer " + token }
  };

  const successActions = (message ,severity ="error") => {
      setNotification((currState) => ({
          ...currState,
          open: true,
          severity: severity,
          message
      }
      ))
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if(isModalOpen){
      setEditValue(null)
      setType('add')
    }
  }

  const handelSubmit = (values) => {
    if(type === 'add'){
      let arr = Urls;
      arr.push({id:uuid() , url: values.url });
      addUrls(arr);
      setUrls(arr);
      setIsModalOpen(!isModalOpen)
    }else{
      let arr=[];
      Urls.map((item) => {
        if(item.id === editValue.id){
          arr.push({ id:item.id, url: values.url })
        }else{
          arr.push(item)
        }
      })
      addUrls(arr,"Edit");
      setUrls(arr);
      setIsModalOpen(!isModalOpen)
    }
  }

  //  useEffect(() => {
  //   const fetchData = async () => {
  //     const result = await api.homePage.getAll({id:userContextObj.data.email} ,options);
  //       if(result.data){
  //         if(result.ok){
  //           setUrls(result.data?.Urls)
  //         }
  //       }
  //   };
 
  //   fetchData();
  // }, []);


  const addUrls = (UrlsObj, type="Add") => {
    api.homePage.add({id:userContextObj.data.email , body:{Urls:UrlsObj}},options)
    .then((response) => {
      successActions(`${type} Urls Successfully`,"success");
    }, (error) => {
      successActions(error);
    });  
  }

  const resetLayout = () => {
    setLayouts({});
  }
  const editLayout = () => {
    setIsDraggable(true);
  }
  const saveLayout = () => {
    setIsDraggable(false);
    saveToLS("layouts", layouts);
    addUrls(Urls ,"Save");
  }

  const onLayoutChange = (layout, layouts) => {
    setLayouts(layouts);
  }

  const removeItem = (item) => {
    let arr = Urls.filter(url => url !== item);
    setUrls(arr);
  }
  const editItem = (item) => {
    setType("edit");
    setEditValue(item);
    toggleModal();
  }
  const fixWidth = (containerWidth, margin, cols, containerPadding) => {
    for (let t = 0; t <= 300; t += 10) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, t)
    }
  }

  return (
    loading ? <Loading /> : <>
      <ResponsiveReactGridLayout
        style={{ direction: "ltr" }}
        containerPadding={[8, 8]}
        margin={[8, 8]}
        isDraggable={isDraggable}
        isResizable={isDraggable}
        className={classes.layout}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={30}
        layouts={layouts}
        onLayoutChange={(layout, layouts) =>
          onLayoutChange(layout, layouts)
        }
        onWidthChange={(containerWidth, margin, cols, containerPadding) =>
          fixWidth(containerWidth, margin, cols, containerPadding)
        }
      >
        <div key="-1" data-grid={{ x: 0, y: 0, w: 20, h: 5 }}>
          <List
            style={{ paddingBottom: "0", height: '100%', width: '100%', boxShadow: "1px 1px 4px -1px #000", borderRadius: "6px", }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader className={classes.subheader} component="div" id="nested-list-subheader">
                Open Recent.
              </ListSubheader>
            }
            className={classes.root}
          >
            {lastVisited && lastVisited.map((item, index) => {
              return <a className={classes.link} key={index + 1} onClick={!isDraggable ? () => history.push(item) : null} >{
                item.split("/")[item.split("/").length - 1]
              }</a>
            })}
          </List>
        </div>


        {Urls && Urls.map((item, index) => {
          return <div className={classes.dragDiv} key={index} data-grid={{ x: 0, y: 6, w: 5, h: 10 }}>
            <iframe style={{ pointerEvents: isDraggable ? "none" : "", opacity: isDraggable ? 0.7 : "" }} className={classes.dragIfream} src={item.url}></iframe>
            {isDraggable ? <Tooltip title="Remove">
              <IconButton className={classes.removeIcon} onClick={() => removeItem(item)} color="default" aria-label="upload picture" component="span">
                <CloseIcon />
              </IconButton>
            </Tooltip> : null}
            {isDraggable ? <Tooltip title="Edit">
              <IconButton className={classes.editIcon} onClick={() => editItem(item)} color="default" aria-label="upload picture" component="span">
                <EditIcon />
              </IconButton>
            </Tooltip> : null}
          </div>

        })}
      </ResponsiveReactGridLayout >

      <Tooltip title={`${isDraggable ? "Save" : "Edit"} Layout`}>
        <Fab className={classes.EditBtn} onClick={isDraggable ? saveLayout : editLayout}>
          {isDraggable ? < SaveIcon /> : <EditIcon />}
        </Fab>
      </Tooltip>
      <Tooltip title="Reset Layout">
        <Fab className={classes.ResetBtn} onClick={resetLayout}>
          <RotateLeftIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Add Url">
        <Fab className={classes.addBtn} color="primary" aria-label="add" onClick={toggleModal}>
          <AddIcon />
        </Fab>
      </Tooltip>

     {isModalOpen ?  <EditModal
        open={isModalOpen}
        handleClose={toggleModal}
        title={`${type} Url Embded item`}
      >
        <UrlItemForm
          handleClose={toggleModal}
          handelSubmit={handelSubmit}
          type={type}
          editValue={editValue?.url}
        />
      </EditModal> : null}
    </>
  )
}


function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}


export default Profile


