import React from 'react'
import { LinearProgress, createStyles, withStyles } from '@material-ui/core';

const ErrorLinearProgress = withStyles((theme) =>
  createStyles({
    bar: {
      backgroundColor: theme.palette.error.main,
    },
  })
)(LinearProgress);

const SuccessLinearProgress = withStyles((theme) =>
  createStyles({
    bar: {
      backgroundColor: theme.palette.success.main,
    },
  })
)(LinearProgress);

export default function Progress({progress ,success ,error}) {
    return (
        <>  
            { 
                !error && !success ? 
                <LinearProgress variant="determinate" value={progress} />
                
                : (!error && success ?
                <SuccessLinearProgress variant="determinate" value={100} />
                :
                <ErrorLinearProgress variant="determinate" value={100} />) 
            }  
        </>
    )
}
