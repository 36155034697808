const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    Edit: {
        width: "100%"
    },
    con: {
        marginTop: theme.spacing(4),
        color: theme.palette.type == "dark" ? "#FFF" : "#000"
    }
})


export default styles;