import React from 'react'
import WareHouseForm from '../Partials/WareHouseForm'
import useStyles from './styles'

function AddWareHouse(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <WareHouseForm {...props}  />
        </div>
    )
}

export default AddWareHouse;
