import * as Yup from "yup";
import { ProjectParseData, ItemParseData, WarehouseCodeParseData , MaintenanceTypeParseData, BusinessPartnerParseData, EquipmentTypeParseData } from './parsers'

export const validationSchema = Yup.object().shape({
    eqCode: Yup.string().trim().required(),
    BPCode: Yup.string().trim(),
    kiloMeters: Yup.number(),
    maintCode: Yup.string().trim(),
    dueDate: Yup.string().trim(),
    discountPercent : Yup.number().min(0).max(100),
});

export const TestValidationSchema = Yup.object().shape({
    itemCode: Yup.string().trim().required(),
    qty: Yup.number().required(),
    price: Yup.number().required(),
    discountPercent : Yup.number().min(0).max(100),
    discountPercent : Yup.number().min(0),
    projectCode: Yup.string().required(),
    warehouseCode: Yup.number().required(),
    remark : Yup.string()
});

export const testFormikProps = {
    TestValidationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        itemCode : "",
        warehouseCode : "",
        projectCode : "",
        qty : "",
        price : "",
        discountPercent : 0,
        lineTotal:0,
        remark : "",
    }
};
export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        eqCode : "",
        kiloMeters : "",
        maintCode : "",
        cardCode : "",
        discountPercent : 0,
        total : 0,
        dueDate : "",
        remark : "",
        attach : "",
        maintenanceTrancstionEMT1s : []
    },
};



let ItemCodeType = -1
const itemTypes = [
    { id: 0, name: "Item" },
    { id: 1, name: "Text" },
]


export const detailForm = [
    { name: "itemCode", action: "show", company: true, type: "dropdown", optionsURI: `/Inventory/Item/short?type=0`, hasSearch:true, searchPre:"&search=", parseData: ItemParseData, width: "98%", },
    { name: "qty", type: "number", width: "48%", },
    { name: "price", type: "number", width: "48%", },
    { name: "discountPercent", type: "number", width: "48%", },
    { name: "lineTotal", type: "number", width: "48%" },
    { name: "projectCode", width: "48%",  type: "dropdown", company: true, optionsURI: '/Financials/Project/Short', hasSearch:true, searchPre:"?search=",  parseData: ProjectParseData },
    { name: "warehouseCode",width: "48%",  type: "dropdown", company: true, optionsURI: "/Inventory/Warehouse/short", hasSearch:true, searchPre:"?search=", parseData: WarehouseCodeParseData  },
    { name: "remark", type: "textarea", maxWords: 25 },
];
export const formData = [
    { name: "eqCode", company: true, type: "dropdown", optionsURI: "/FleetManagement/EquipmentMasterData", parseData: EquipmentTypeParseData, width: "48%", },
    { name: "kiloMeters", type: "number", width: "48%", },
    { name: "maintCode", company: true, type: "dropdown", optionsURI: "/FleetManagement/MaintenanceType", parseData: MaintenanceTypeParseData, width: "48%", },
    { name: "cardCode", company: true, type: "dropdown", optionsURI: "/BusinessPartners/BusinessPartner/Short?type=1", hasSearch:true, searchPre:"&search=", parseData: BusinessPartnerParseData, width: "48%", },
    { name: "discountPercent", type: "number", width: "48%", },
    { name: "total", type: "number", width: "48%", },
    { name: "dueDate", type: "date", width: "48%", focused: true, },
    { name: "remark", type: "textarea", maxWords: 25 },
    { 
        name: "attach",
        label: "attach",
        type: "uploader",
        width: "100%",
        accept: ['image/*', ".pdf", ".doc", "audio/*", "video/*"],
        aspectRatio: "1:1",
        multiple: true,
        maxFileSize: 30
    },
    {
        name: "maintenanceTrancstionEMT1s", 
        type: "objects-array", 
        formikProps: testFormikProps, 
        formData: detailForm , 
        showComponentType : "maintenanceTransaction",
    },
];