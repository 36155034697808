import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import Calender from '../../../../Components/Calender';
import TimeAttendanceForm from '../Partials/TimeAttendanceForm';
import styles from './styles';


class ViewTimeAttendance extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';


    data = { employeeName: "Name 2", timeIn: "1:07 AM", timeOut: "6:07 PM", shiftMapped: "shift 2" };
    loading = false;


    componentDidMount() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 600);
    }
    setView = (view) => {
        this.view = view;
    }

    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <>
                        <ShowView title="Time and Attendance Details."
                            data={this.data}
                            setView={this.setView}
                            showViewName="TimeAttendances"
                        />
                        <br />
                        <Calender />
                    </>
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <TimeAttendanceForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewTimeAttendance, {
    view: observable,
    loading: observable,

})




export default withStyles(styles)(asEntity({ storeId: "TimeAttendanceForm" })(observer(ViewTimeAttendance)));
