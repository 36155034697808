import { makeStyles } from '@material-ui/core'
import { drawerWidth } from '~/Components/Layout/Partials/Aside/styles'

const useStyles = makeStyles(theme => ({
    appBar: {
        flexGrow: 1,
        width: '100%',
        maxWidth: `calc(100vw - ${drawerWidth  - 45}px)`,
        [theme.breakpoints.down('sm')] : { 
            maxWidth:  `calc(100vw - 30px)`,
        }
    },
}))

export default useStyles