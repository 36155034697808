import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    contTypeCode: Yup.string().trim().required(),
    contName: Yup.string().trim(),
    frgnName: Yup.string().trim(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        contTypeCode: "",
        contName: "",
        frgnName: "",
    },
};

const invoiceTypeOptions = [
    {id : 0 , name : 'Account Receivable'},
    {id : 1 , name : 'Account Payable'},
]

export const formData = [
    { name: "contTypeCode", width: '48%', type: "text", editDisabled: true },
    { name: "contName", width: '48%', type: "text" },
    { name: "frgnName", width: '48%', type: "text", },
    { name: "invoiceType", width: '48%', type: "dropdown",  options :invoiceTypeOptions },
];


