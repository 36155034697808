const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    Edit: {
        width: "100%"
    },
    tableRoot: {
        textAlign: theme.direction === "ltr" ? 'right' : "left",
        MUIDataTableToolbar: {
            actions: {
                textAlign: "left",
            }
        },
    },
    AddButton: {
        margin: 15,

    },
})


export default styles;