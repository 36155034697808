import React from 'react'
import TypeOfShipmentForm from '../Partials/TypeOfShipmentForm'
import useStyles from './styles'

function AddTypeOfShipment(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <TypeOfShipmentForm {...props}  />
        </div>
    )
}

export default AddTypeOfShipment;
