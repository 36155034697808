import { withStyles, IconButton, ListItem, ListItemText, Divider, CircularProgress, Tooltip } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import SystemInitializationForm from '../Partials/SystemInitializationForm';
import styles from './styles';
import { NotificationContext } from '~/Services/Providers';
import PermScanWifiIcon from '@material-ui/icons/PermScanWifi';
import WifiIcon from '@material-ui/icons/Wifi';
import PasswordStar from '~/Components/PasswordStar/PasswordStar';
import ShowLableWithButton from '../../../../../Components/ShowLableWithButton';

class ViewSystemInitializationSAP extends React.Component {
    static contextType = NotificationContext;

    view = "show";
    data = {};


    TestConData = {
        TestConnection: "",
    };
    loading = false;
    TestConnectionloading = false;


    componentDidMount() {
        this.getData();
    }

    getData() {
        this.loading = true;
        this.props.entityStore.get({ type: "" })
        this.TestConnectionloading = true;
        this.props.entityStore.get({ type: "TestConnection" });
    }
    setView = (view) => {
        this.view = view;
        if (view === "show") {
            this.getData()
        }
    }

    testConnection = () => {
        this.TestConnectionloading = true;
        this.props.entityStore.get({ type: "TestConnection" })
    }


    entityDidReceived(data) {
        if (!!data) {
            if (data.type == "TestConnection") {
                this.TestConnectionloading = false;
                this.TestConnectionIcon = "wifi_icon";
                this.notify("success", "Connection successfully");
            } else {
                this.loading = false;
                this.data = data
            }
        }
    }
    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }

    entityDidCatch({ error, type }) {
        if (type == "TestConnection") {
            this.TestConnectionIcon = "perm_scan_wifi_icon";

            this.TestConnectionloading = false;
            this.notify("error", "Connection error");
        } else {
            this.loading = false;
            this.notify("error", error);
        }


    }

    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <>
                <div className={classes.root}>
                    {
                        this.view === "show" &&
                        <ShowView title="Defalut Connection Info."
                            data={this.data}
                            setView={this.setView}
                            showViewName="SystemInitializations"
                        />
                    }

                    {
                        this.view === "edit" &&
                        <div className={classes.Edit}>
                            <SystemInitializationForm {...this.props} setView={this.setView} />
                        </div>
                    }
                </div>
                {
                    this.view === "show" &&
                    <div className={classes.con}>
                        <ShowLableWithButton
                            label="Test Connection"
                            icon={this.TestConnectionIcon}
                            handleClick={() => this.testConnection()}
                            loading={this.TestConnectionloading}
                            tooltipTitle="Test Connection"
                        />

                    </div>
                }
            </>
        )
    }
}

decorate(ViewSystemInitializationSAP, {
    view: observable,
})




export default withStyles(styles)(asEntity({ storeId: "ViewSystemInitializationSAP" })(observer(ViewSystemInitializationSAP)));

