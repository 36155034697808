import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    comment: Yup.string().trim().required(),
    alternativeApproval: Yup.boolean(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        comment: "",
        alternativeApproval: false,
    },
};

export const formData = [
    { name: "comment", type: "textarea" },
    { name: "alternativeApproval", type: "switch" },
];


