import { SignalWifi0Bar } from '@material-ui/icons'
import React from 'react'
import MaintenanceTransaction from './MaintenanceTransaction' 

const ShowComponent = ({type , ...rest}) => {
    
    switch (type) {
        case 'maintenanceTransaction':
            return  <MaintenanceTransaction {...rest} />
        default:
            break;
    }
}

export default ShowComponent
