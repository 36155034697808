import React from 'react'
import ContractTypesForm from '../Partials/ContractTypesForm'
import useStyles from './styles'

function AddContractTypes(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <ContractTypesForm {...props}  />
        </div>
    )
}

export default AddContractTypes;
