import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    Show: {
        width: "100%",
        color: theme.palette.text.primary,

    },
    ShowHeader: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        marginBottom : theme.spacing(1),
        backgroundColor: theme.palette.type === "light" ? "#fff" : "#212121",

    },
    InfoPanel: {
        boxShadow: theme.shadows[3],
        borderRadius: 5,
        paddingBottom: 0
    },

}));


export default useStyles;