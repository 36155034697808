import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import RequestBusinessTripForm from '../Partials/RequestBusinessTripForm';
import styles from './styles';


class ViewRequestBusinessTrip extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';


    data = {
        destinationFrom: `destination From 1`,
        destinationTo: `destination To`,
        dateFrom: `${new Date().getFullYear()}`,
        dateTo: `${new Date().getFullYear()}`,
        project: "project 1",
        advanceRequest: "advanceRequest",
        amounted: "amounted",
        airTicket: "airTicket",
        visaRequired: false,
        country: "country",
        exitReEntryVisa: "exitReEntryVisa",
        accomodationReservation: "accomodationReservation",
        carRentalReservation: "carRentalReservation"
    };


    loading = false;


    componentDidMount() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 600);
    }
    setView = (view) => {
        this.view = view;
    }


    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="Request for Business Trip Details."
                        data={this.data}
                        setView={this.setView}
                        showViewName="RequestBusinessTrips"
                    />
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <RequestBusinessTripForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewRequestBusinessTrip, {
    view: observable,
    loading: observable,

})




export default withStyles(styles)(asEntity({ storeId: "RequestBusinessTripForm" })(observer(ViewRequestBusinessTrip)));
