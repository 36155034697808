
export const columns = [
    { name: "id", options: { filter: false, sort: true, display: true } },
    { name: "name", options: { filter: true, sort: true, } },
];


export const RolesData = [
    { id: 1, name: 'Supervisor', },
    { id: 2, name: 'Employee', },
    { id: 3, name: 'Manger', },
]
