
import axios from "axios";
import { create } from "apisauce";
import ApiConfigs from "~/Configs/Api";
import { StorageKeys } from "~/Configs/Storage";
import { getStorage } from "~/Services/Helpers";

const token = getStorage(StorageKeys.token);
const company = window.localStorage.getItem(StorageKeys.Company) ? window.localStorage.getItem(StorageKeys.Company).split(".")[0] : "";

const axiosInstance = axios.create({
  baseURL: ApiConfigs.getCompanyURL(company),
  timeout: ApiConfigs.timeout,
  headers: {
    Accept: `application/json;v=${ApiConfigs.version}`,
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
  },
});

console.log(`ApiConfigs.getCompanyURL(company)`, ApiConfigs.getCompanyURL(company))

const Api = create({ axisInstance: axiosInstance });
const CancelToken = axios.CancelToken;
const requestsSourceCancellation = CancelToken.source();



export const getfiles = async (id) => {
  axios.get(`${ApiConfigs.getCompanyURL(company)}Administration/Attachment/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
    }).then((response) => {
      console.log("getfiles", response);
      return response;
    })
    .catch((error) => {
      console.log("getfiles", error);

    })
};

export const deleteFile = (id, notifications) => {

  axios.delete(`${ApiConfigs.getCompanyURL(company)}Administration/Attachment/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
    }).then((response) => {
      if (response.ok) {
        notifications("File Deleted Successfully", "success");
      }
    })
    .catch((error) => {
      console.log(error);

    })
};

export function uploadFile(onProgress, notifications, setError, uploadedFiles) {
  const url = `${ApiConfigs.getCompanyURL(company)}${ApiConfigs.multipleFileUpload}`;
  const key = 'docs_upload_example_us_preset';

  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.onload = () => {
      const resp = JSON.parse(xhr.responseText);
      if (xhr.status == 200) {
        res(resp.attKey);
      } else {
        setError(true);
        notifications(resp);
      }
    };
    xhr.onerror = (evt) => {
      rej(evt)
      notifications("connection error");
    };
    xhr.onreadystatechange = function () {
      if (this.status == 500) {
        setError(true);
        notifications("Server Error");
      }
    };

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;
        onProgress(Math.round(percentage));
      }
    };

    const formData = new FormData();

    uploadedFiles.forEach(file => {
      formData.append('files', file.file);
    });
    formData.append('upload_preset', key);

    xhr.send(formData);
  });
}
