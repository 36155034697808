import { Button, CircularProgress, withStyles } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Input, PhoneNumInput } from '~/Components/From';
import { withFormik } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import LoginForm from '../../Login/Steps/Login/LoginForm';
import { formData } from './data';
import useStyles from "./styles";


class RegisterForm extends React.Component {
    loading = false; // true , false
    static contextType = NotificationContext;

    handleSubmit = (e) => {
        e.preventDefault();
        let validate = this.props.validateForm();

        validate.then((err) => {
            if (this.props.isValid) {
                //if the form valid do something
            } else {
                //do something when there is an errors 
            }
        });
    };


    entityDidCatch(error) {
        this.loading = false;
        this.notify("error", error)

    }

    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <form noValidate onSubmit={this.handleSubmit}  >
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        {
                            formData.map(field => (
                                field.type === "phone"
                                    ? <PhoneNumInput
                                        name={field.name}
                                        placeholder={<FormattedMessage id={`Auth.RegisterForm.${field.name}`} />}


                                    />
                                    : <Input type={field.type}
                                        name={field.name}
                                        labelWidth={field.labelWidth}
                                        placeholder={<FormattedMessage id={`Auth.RegisterForm.${field.name}`} />}
                                        icon={field.icon}
                                        InputChange={this.props.handleChange}
                                        id={`register-form-${field.name}`}
                                        key={`reigster-form-${field.name}`}
                                        width={field.width}
                                        error={this.props.errors[field.name]}
                                        errorText={this.props.errors[field.name]}
                                    />
                            ))
                        }

                        <div style={{ flexBasis: "100%", textAlign: "center" }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.SubmitButton}
                                endIcon={this.loading ? <CircularProgress color="light" size={15} /> : <ArrowForward />}
                                type="submit"
                                disabled={this.loading}

                            >
                                <FormattedMessage id="Auth.Continue" />
                            </Button>
                        </div>

                    </div>
                </form>
            </div>

        )
    }
}


decorate(LoginForm, {
    loading: observable,
})

export default withStyles(useStyles)(withFormik(observer(RegisterForm)));
