import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import AssignActivityForm from '../Partials/AssignActivityForm';
import styles from './styles';


class ViewAssignActivity extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';


    data = { taskNumber: "taskNumber1", taskDescription: "taskDescription", start: "start", end: "end", taskCategory: "taskCategory", assignedPerson: "assignedPerson", Reminder: "Reminder", typeNotfication: "1", when: "befor 15 min", projectCode: "projectCode", projectStage: "projectStage" };

    loading = false;


    componentDidMount() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 600);
    }
    setView = (view) => {
        this.view = view;
    }


    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="Assign Activity Details."
                        data={this.data}
                        showViewName="AssignActivitys"
                        setView={this.setView}
                    />
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <AssignActivityForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewAssignActivity, {
    view: observable,
    loading: observable,

})




export default withStyles(styles)(asEntity({ storeId: "AssignActivityForm" })(observer(ViewAssignActivity)));
