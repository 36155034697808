
export const columns = [
    { name: "id", options: { filter: false, sort: true, display: true } },
    { name: "name", options: { filter: true, sort: true, } },
];


export const AssetsAndCustodyData = [
    { id: 1, name: 'Asset 1', },
    { id: 2, name: 'Asset 2', },
    { id: 3, name: 'Asset 3', },
]
