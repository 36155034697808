import React, { Component } from 'react';
import ChoseCompanyForm from './ChoseCompanyForm';
import AuthScreen from '../../../Partials/AuthScreen';
import { LoginPageData } from "./data"

export default class componentName extends Component {
    render() {
        return (
            <AuthScreen {...LoginPageData}>
                <ChoseCompanyForm {...this.props}></ChoseCompanyForm>
            </AuthScreen>

        );
    }
}