

export const columns = [

    { name: "destinationFrom", label: "Destination From", options: { filter: false, sort: true, display: true } },
    { name: "destinationTo", label: "Destination To", options: { filter: false, sort: true, display: true } },
    { name: "dateFrom", label: "Date From", options: { filter: false, sort: true, display: true } },
    { name: "dateTo", label: "Date To", options: { filter: false, sort: true, display: true } },
    { name: "project", label: "Project", options: { filter: false, sort: true, display: true } },
    { name: "advanceRequest", label: "Advance request", options: { filter: false, sort: true, display: true } },
    { name: "amounted", label: "Amounted", options: { filter: false, sort: true, display: true } },
    { name: "airTicket", label: "Air Ticket reservation required", options: { filter: false, sort: true, display: true } },
    { name: "visaRequired", label: "Visa Required", options: { filter: false, sort: true, display: true } },
    { name: "country", label: "Country", options: { filter: false, sort: true, display: true } },
    { name: "exitReEntryVisa", label: "Exit Re-entry Visa", options: { filter: false, sort: true, display: true } },
    { name: "accomodationReservation", label: "Accomodation reservation", options: { filter: false, sort: true, display: true } },
    { name: "carRentalReservation", label: "Car Rental reservation", options: { filter: false, sort: true, display: true } },

];




export const data = [
    { destinationFrom: "destination From 1", destinationTo: "destination To", dateFrom: "Thursday, February 11, 2021", dateTo: "Thursday, February 12, 2021", project: "project 1", advanceRequest: "Yes", amounted: "amounted", airTicket: "Yes", visaRequired: "Yes", country: "country", exitReEntryVisa: "Yes", accomodationReservation: "Yes", carRentalReservation: "Yes" },
    { destinationFrom: "destination From  2", destinationTo: "destination To", dateFrom: "Thursday, February 11, 2021", dateTo: "Thursday, February 12, 2021", project: "project 2", advanceRequest: "Yes", amounted: "amounted", airTicket: "No", visaRequired: "No", country: "country", exitReEntryVisa: "No", accomodationReservation: "No", carRentalReservation: "Yes" },
    { destinationFrom: "destination From 3", destinationTo: "destination To", dateFrom: "Thursday, February 11, 2021", dateTo: "Thursday, February 12, 2021", project: "project 3", advanceRequest: "No", amounted: "amounted", airTicket: "Yes", visaRequired: "No", country: "country", exitReEntryVisa: "No", accomodationReservation: "Yes", carRentalReservation: "No" },
    { destinationFrom: "destination From 4", destinationTo: "destination To", dateFrom: "Thursday, February 11, 2021", dateTo: "Thursday, February 12, 2021", project: "project 4", advanceRequest: "No", amounted: "amounted", airTicket: "Yes", visaRequired: "No", country: "country", exitReEntryVisa: "Yes", accomodationReservation: "Yes", carRentalReservation: "Yes" },
    { destinationFrom: "destination From 5", destinationTo: "destination To", dateFrom: "Thursday, February 11, 2021", dateTo: "Thursday, February 12, 2021", project: "project 5", advanceRequest: "Yes", amounted: "amounted", airTicket: "No", visaRequired: "Yes", country: "country", exitReEntryVisa: "Yes", accomodationReservation: "No", carRentalReservation: "No" },
    { destinationFrom: "destination From 6", destinationTo: "destination To", dateFrom: "Thursday, February 11, 2021", dateTo: "Thursday, February 12, 2021", project: "project 6", advanceRequest: "Yes", amounted: "amounted", airTicket: "Yes", visaRequired: "Yes", country: "country", exitReEntryVisa: "No", accomodationReservation: "No", carRentalReservation: "No" },

]



