import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        width: '95%',
        padding: theme.spacing(1),
        textAlign: 'center',
        display: 'block',
        marginTop: theme.spacing(1),
        borderRadius: 5,
        color: theme.palette.type === "dark" ? "#FFF" : "#000"
    }
}))


export default useStyles;
