import React from 'react'
import GIMaintenanceTransactionForm from '../Partials/GIMaintenanceTransactionForm'
import useStyles from './styles'

function GIMaintenanceTransaction(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <GIMaintenanceTransactionForm {...props} />
        </div>
    )
}

export default GIMaintenanceTransaction;
