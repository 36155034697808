import MenuCollapse from '../MenuCollapse'
import MenuItem from '../MenuItem'
import React from 'react'
import { getStorage } from '~/Services/Helpers';

function MenuControl({ type, element, level, ...props }) {

    const language = getStorage("locale") || "en";
    const name = language === "en" ? element.title : element.ArabicLabel
    const sharedProps = {
        name: name ? name : element.title,
        icon: element.icon,
        active: props.activeNav.path === element.path,
        level: level
    }
    switch (type) {
        case 'Collapse':
            return <MenuCollapse data={element.children}
                {...props} {...sharedProps}
            />
        case 'Item':
            return <MenuItem  {...sharedProps} {...props} />
        default:
            return <></>
    }
}

export default MenuControl
