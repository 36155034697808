const useStyles = (theme) => ({
    root: {
        textAlign: "center",
        paddingBottom: theme.spacing(3),
        display: "flex",

    },
    SubmitButton: {
        marginTop: theme.spacing(4),
        width: "400px"
    },
    FormWrapper: {
        padding: theme.spacing(1),
        paddingRight: theme.spacing(4),
        marginTop: theme.spacing(3),
        borderRadius: 5,
    }
});


export default useStyles;