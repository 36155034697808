import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    docTypeCode: Yup.string().trim().required(),
    docName: Yup.string().trim(),
    frgnName: Yup.string().trim(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        docTypeCode: "",
        docName: "",
        frgnName: "",
    },
};


export const formData = [
    { name: "docTypeCode", type: "text", editDisabled: true },
    { name: "docName", type: "text" },
    { name: "frgnName", type: "text", },
];