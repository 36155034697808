import { Drawer, Hidden } from '@material-ui/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { MainMenu, SubMenu } from '~/Components/Menu';
import useStyles, { Styles } from './styles';
import Navigator from '~/Routes/RouteNavigator';

function Aside({ handleDrawerToggle, open, }) {
    const classes = useStyles();
    const [activeNav, setActiveNav] = useState({ activeTile: '', path: '' })
    const [navData, setNavData] = useState(Navigator.routData)
    if (Object.keys(navData).length === 0) {
         setTimeout(() => {
            setNavData({ ...Navigator.data() })
        }, 200);
    }
    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <div style={{ display: 'flex' }}>
                <MainMenu
                    handleDrawerToggle={handleDrawerToggle}
                    setActive={setActiveNav}
                    data={navData}
                    activeNav={activeNav}
                />
                <div className={classes.SubMenu} style={Styles.drawerPaperWidth(activeNav.path.split("/").length - 1, open)}>
                    <SubMenu
                        data={!!activeNav.activeTile ? navData[activeNav.activeTile].children : {}}
                        setActiveNav={setActiveNav}
                        activeNav={activeNav}
                    />
                </div>
            </div>
        </div >
    );
    return (
        <nav>
            <Hidden mdUp>
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}

                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    onClose={() => handleDrawerToggle(false)}
                >
                    {drawer}
                </Drawer>
            </Hidden>

            <Hidden smDown implementation="js">
                <Drawer
                    style={{ height: "100%" }}

                    variant="permanent"
                    classes={{
                        paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >

                    {drawer}
                </Drawer>
            </Hidden>
        </nav >
    )
}

export default Aside;
