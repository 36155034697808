import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    foodicsID: Yup.string().required(),
    sapCode: Yup.string().trim().required(),
    warehoseOffsetCode: Yup.string().trim().required(),
    sapCostCenterCode: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        foodicsID: null,
        sapCode: '',
        warehoseOffsetCode: '',
        sapCostCenterCode: ''
    },
};
const FoodicsID = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}`,
            id: `${element.name}_#_${element.id}`
        })
    });
    return Options;
}
const sapCodeParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.warehouseName}`,
            id: `${element.warehouseName}_#_${element.warehouseCode}`
        })
    });
    return Options;
}

const costCenterParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {

        Options.push({
            name: `${element.centerName}`,
            id: `${element.centerName}_#_${element.centerCode}`
        })
    });
    return Options;
}


export const formData = [
    { name: "foodicsID", width : "48%" , company: true, type: "dropdown", optionsURI: "/Foodics/Warehouse", parseData: FoodicsID },
    { name: "sapCode", width : "48%" , type: "dropdown", company: true, optionsURI: "/Inventory/Warehouse/Short", parseData: sapCodeParseData },
    { name: "sapCostCenterCode", width : "48%" , type: "dropdown", company: true, optionsURI: "/Financials/ProfitCenters", parseData: costCenterParseData },
    { name: "warehoseOffsetCode", width : "48%" , company: true, type: "dropdown", optionsURI: "/Inventory/Warehouse/Short", parseData: sapCodeParseData },
];