import React from 'react'
import TaxForm from '../Partials/TaxForm'
import useStyles from './styles'

function AddTax(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <TaxForm {...props}  />
        </div>
    )
}

export default AddTax;
