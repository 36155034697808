import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    slServer: Yup.string().trim().required(),
    companyDB: Yup.string().trim().required(),
    userName: Yup.string().trim().required(),
    password: Yup.string().trim().required(),

});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        slServer: "",
        companyDB: "",
        userName: "",
        password: "",

    },
};


export const formData = [
    { name: "slServer", type: "text" },
    { name: "companyDB", type: "text" },
    { name: "userName", type: "text", },
    { name: "password", type: "text", },

];