import * as Yup from "yup";
import React from 'react';
export const validationSchema = Yup.object().shape({
    register: Yup.string(),
    type: Yup.string(),
    from: Yup.string(),
    to: Yup.string().trim(),
    considerHolidays: Yup.boolean(),
    requireExitReEntry: Yup.string().trim(),
    exitReEntryId: Yup.string().trim(),
    exitReEntryFrom: Yup.string(),
    exitReEntryTo: Yup.string(),
    requireTickets: Yup.string().trim(),
    ticketsNumber: Yup.string().trim(),
    ticketsAirlines: Yup.string().trim(),
    ticketsType: Yup.string().trim(),
    settleRemaining: Yup.string().trim(),
    reason: Yup.string().trim(),
    vacationEncashingRequest: Yup.string().trim().required(),
    duration: Yup.string().trim().required(),


});
export const testSchema = Yup.object().shape({

});
export const testFormikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {},
};
export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        vacationEncashingRequest: 0,
        register: "",
        type: "",
        from: "",
        to: "",
        considerHolidays: "",
        requireExitReEntry: "",
        exitReEntryId: "",
        exitReEntryFrom: "",
        exitReEntryTo: "",
        requireTickets: "",
        ticketsNumber: "",
        ticketsAirlines: "",
        ticketsType: "",
        settleRemaining: "",
        reason: "",
        attachment: "",
        // duration: "",

    },
};

const YesNo = [
    { id: 1, name: "Yes", value: "Yes" },
    { id: 0, name: "No", value: "No" }
];
let Test = [];
let ValueCheck = (value) => {
    if (value == 1) {
        return true;
    } else {
        return false;
    }
}


const valueSet = (values) => {
    if (values) {
        const duration = dateDiffIndays(values["from"], values["to"]);
        return duration;
    } else return false
}

const dateDiffIndays = function (date1, date2) {
    let dt1 = new Date(date1);
    let dt2 = new Date(date2);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
}

const ItemType = [
    { id: 0, name: "Annual", value: "Annual" },
    { id: 1, name: "Paid ", value: "Paid " },
    { id: 2, name: "Unpaid ", value: "Unpaid " },
];
const registerItem = [
    { id: 0, name: "Manager", value: "Manager" },
    { id: 1, name: "Employee ", value: "Employee " },
];
const ItemRequireExitReEntry = [
    { id: 0, name: "Employee Only", value: "Employee Only" },
];
export const formData = [
    { name: "vacationEncashingRequest", type: "dropdown", options: YesNo },
    { name: "register", type: "dropdown", options: registerItem },
    { name: "type", type: "dropdown", options: ItemType },
    { name: "from", title: "From", type: "date", width: "48%", focused: true, action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },
    { name: "to", title: "TO", type: "date", width: "48%", focused: true, action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },
    { name: "considerHolidays", type: "switch", action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },
    { name: "requireExitReEntry", type: "switch", action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },
    { name: "exitReEntryId", type: "text", action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },
    { name: "exitReEntryFrom", type: "data", action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },
    { name: "exitReEntryTo", type: "data", action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },

    { name: "requireTickets", type: "dropdown", options: ItemRequireExitReEntry, action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },
    { name: "ticketsNumber", type: "number", action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },
    { name: "ticketsAirlines", type: "text", action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },
    { name: "ticketsType", type: "text", action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },

    { name: "settleRemaining", type: "switch", action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },
    { name: "reason", type: "text", action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },

    {
        name: "duration",
        type: "text",
        actions: [{ type: "value", actionONFields: ['from', "to"] }, { type: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },],
        valueSet: valueSet,
        controlled: true
    },
    { name: "attachment", type: "file", width: "100%", accept: ["application/pdf"], maxFileSize: 10, action: "toggle", actionONFields: [{ name: 'vacationEncashingRequest', schema: ValueCheck }] },
    // {
    //     name: "tableTest", type: "table", tableData: Test,
    //     formikProps: testFormikProps, formData: [
    //         { name: "id", type: "text", },
    //         { name: "name", type: "text", },
    //         { name: "value", type: "text", }
    //     ]
    // },
];

