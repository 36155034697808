import { isEmpty } from '~/Services/Helpers'
export const ContractTypeParseData = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.contTypeCode}-${element.contName}`,
            id: element.contTypeCode
        })
    });
    return Options;
};
export const EquipmentDocumentParseData = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.eqCode}-${element.equiName}`,
            id: element.eqCode
        })
    });
    return Options;
}

export const ProjectParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.code}-${element.name}`,
            id: `${element.name}_#_${element.code}`
        })
    });
    return Options;
}

export const BusinessPartnerParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.cardCode}-${element.cardName}`,
            id: `${element.cardName}_#_${element.cardCode}`
        })
    });
    return Options;
}
export const EMPParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.employeeID}-${element.firstName} ${element.lastName}`,
            id: `${element.firstName} ${element.lastName}_#_${element.employeeID}`
        })
    });
    return Options;
}
export const SalseEMPParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.salesEmployeeCode}-${element.salesEmployeeName}`,
            id: `${element.salesEmployeeName}_#_${element.salesEmployeeCode}`
        })
    });
    return Options;
}
export const WarehouseCodeParseData = (data) => {
    const Options = []
    if (isEmpty(data)) return Options;
    let type ='address';
    if(!Array.isArray(data))
    {
        type ='warehouse';
        data=data.value;
    }
    Array.isArray(data) && data.forEach(element => {
        if(type === 'address'){
            Options.push({
                name: `${element.addressName}`,
                id: `${element.rowNum}_#_${element.addressName}`
            })
        }else{
            Options.push({
                name: `${element.warehouseName}`,
                id: `${element.warehouseCode}_#_${element.warehouseName}`
            })
        }
    });
    return Options;
}

export const MaterialCodeParseData = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.shipCode}-${element.shipName}`,
            id: element.shipCode
        })
    });
    return Options;
}

export const ServicePraseData = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.servName}`,
            id: `${element.servCode}`
        })
    });
    return Options;
}