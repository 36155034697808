import React from 'react'
import ContentLoader from "react-content-loader";


const MenuItemLoader = () => {
    return (
        <ContentLoader
            speed={2}
            width={350}
            height={85}
            viewBox="0 0 400 85"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="-1" y="9" rx="3" ry="3" width="88" height="60" />
            <rect x="-1" y="75" rx="3" ry="3" width="88" height="5" />
        </ContentLoader>
    )
}

export default MenuItemLoader
