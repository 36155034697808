import { withStyles } from '@material-ui/core';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import GIMaintenanceTransactionForm from '../Partials/GIMaintenanceTransactionForm';
import styles from './styles';
import Table from '~/Components/EnhancedTable';
import { DetailsColumns } from "../Browse/data"

import ApiConfigs from "~/Configs/Api";
import { StorageKeys } from "~/Configs/Storage";
import { getStorage } from "~/Services/Helpers";
import axios from "axios";
import { FileHeader } from '../../../../../Components/Uploader/Partials/FileHeader';

const token = getStorage(StorageKeys.token);
const company = window.localStorage.getItem(StorageKeys.Company) ? window.localStorage.getItem(StorageKeys.Company).split(".")[0] : "";



class ViewGIMaintenanceTransaction extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {};
    itemDetails = []
    loading = false;
    files = {};

    componentDidMount() {
        this.getData();
    }

    getData() {
        if (!!this.id) {
            this.loading = true;
            this.props.entityStore.get(this.id)
        }
    }
    setView = (view) => {
        this.view = view;
    }



    deletefile = async (id) => {
        axios.delete(`${ApiConfigs.getCompanyURL(company)}Administration/Attachment/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }).then((response) => {
                this.getData();
            })
            .catch((error) => {

            })
    };
    getfiles = async (id) => {
        const result = await axios(
            `${ApiConfigs.getCompanyURL(company)}Administration/Attachment/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        );
        if (result.data) {
            this.files = result.data
            this.loading = false;
        }
    };
    entityDidReceived(data) {
        if (!!data) {
            this.loading = false;
            let { docNum, eqCode, kiloMeters, maintenanceType, date, attKey } = data;
            eqCode=data?.eqCodeNavigation?.equiName;
            maintenanceType= data?.maintenanceType?.mainName;
            this.data = { docNum, eqCode, kiloMeters, maintenanceType, date }
            this.DetailsData = data.maintenanceTrancstionIMT1s;
            if (attKey) {
                this.getfiles(attKey);
            } else {
                this.loading = false;
            }
        }
    }

    render() {
        const options = {
            enableNestedDataAccess: '.',
            selectableRows: "none",
            onRowClick: (rowData, rowMeta) => { }
        };
        const { classes } = this.props;
        if (this.loading) return <Loading />
        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="GI Maintenance Transaction."
                        showViewName="GIMaintenanceTransaction"
                        data={this.data}
                        setView={false}
                    />
                }
                {this.view === "show" &&
                    <>
                        < Table
                            title="GIMaintenanceTransactionDetails"
                            showKeyIndex={0}
                            defaultData={this.DetailsData}
                            columns={DetailsColumns}
                            parentDeleteAction={this.onDeleteConfirm}
                            customOptions={options}
                        />
                        {(this.files && this.files.attachmentLines) ? <FileHeader files={this.files} onDelete={this.deletefile} /> : null}

                    </>
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <GIMaintenanceTransactionForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewGIMaintenanceTransaction, {
    view: observable,
    data: observable,
    itemDetails: observable,
    loading: observable

})




export default withStyles(styles)(asEntity({ storeId: "GIMaintenanceTransactionForm" })(observer(ViewGIMaintenanceTransaction)));
