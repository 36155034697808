const Styles = (theme) => ({
    root: {
        paddingBottom: theme.spacing(3),
        display: "flex",
        flexWrap: "wrap"
    },
    formWrapper: {
        [theme.breakpoints.down('sm')]: {
            width: "70%",
        },
        [theme.breakpoints.up('sm')]: {
            width: "100%",
        },

        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        padding: "1em",
    }
});

export default Styles;
