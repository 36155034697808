

export const columns = [
    { name: "tripCode", label: "Trip Master Code", options: { filter: false, sort: true, display: true } },
    { name: "tripName", label: "Trip Master Name", options: { filter: true, sort: true, } },
    { name: "fromCity", label: "From City", options: { filter: true, sort: true, } },
    { name: "toCity", label: "To City", options: { filter: true, sort: true, } },
    { name: "distance", label: "Distance", options: { filter: true, sort: true, } },
    { name: "price", label: "Price", options: { filter: true, sort: true, } },
    { name: "tripTotal", label: "Trip Total", options: { filter: true, sort: true } },
];






