import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { StorageKeys } from '~/Configs/Storage';
import { getStorage } from '~/Services/Helpers';

function AuthRoute(props) {
    let lastVisited = getStorage(StorageKeys.lastVisited) ? JSON.parse(getStorage(StorageKeys.lastVisited)) : [];
    let redirectPath = lastVisited.length > 0 ? lastVisited[0] : "/administration/user/user-profile"
    if (window.location.pathname === "/login") {
        redirectPath = "/home-page"
    }
    return (
        getStorage(StorageKeys.token) && window.localStorage.getItem(StorageKeys.Company)
            ? <Redirect to={redirectPath} exact />
            : <Route {...props} />
    )
}


export default AuthRoute;