import { autoGeneratedColumnsEnumNormalizer, autoGeneratedColumnsObjectNormalizer } from "~/Services/Helpers";

const cardTypeValues = {
    0: 'Customer',
    1: 'Supplier',
    2: 'Lid',
}
const vatLiableValues = {
    0: 'Exempted',
    1: 'Liable',
    2: 'EC',
}
const companyPrivateValues = {
    0: 'Company',
    1: 'Private',
    2: 'Government',
    3: 'Employee',
}
const taxRoundingRuleValues = {
    0: 'RoundDown',
    1: 'RoundUp',
    2: 'RoundOff',
    3: 'CompanyDefault',
}
const discountBaseObjectValues = {
    0: 'None',
    1: 'Item Groups',
    2: 'Item Properties',
    3: 'Manufacturer',
    4: 'Items',
}
const discountRelationsValues = {
    0: 'Lowest Discount',
    1: 'Highest Discount Groups',
    2: 'Average Discount',
    3: 'Discount Totals',
    4: 'Multiplied Discount',
}
const typeReportValues = {
    0: 'At Company',
    1: 'At Others'
}
const boolObject = {
    0: 'No',
    1: 'Yes'
}


export const columnsValuesNormalizer = {
    cardType: autoGeneratedColumnsEnumNormalizer(cardTypeValues),
    vatLiable: autoGeneratedColumnsEnumNormalizer(vatLiableValues),
    companyPrivate: autoGeneratedColumnsEnumNormalizer(companyPrivateValues),
    taxRoundingRule: autoGeneratedColumnsEnumNormalizer(taxRoundingRuleValues),
    discountBaseObject: autoGeneratedColumnsEnumNormalizer(discountBaseObjectValues),
    discountRelations: autoGeneratedColumnsEnumNormalizer(discountRelationsValues),
    typeReport: autoGeneratedColumnsEnumNormalizer(typeReportValues),
    deductibleAtSource: autoGeneratedColumnsEnumNormalizer(boolObject),
    valid: autoGeneratedColumnsEnumNormalizer(boolObject),
    frozen: autoGeneratedColumnsEnumNormalizer(boolObject),
    backOrder: autoGeneratedColumnsEnumNormalizer(boolObject),
    partialDelivery: autoGeneratedColumnsEnumNormalizer(boolObject),
    blockDunning: autoGeneratedColumnsEnumNormalizer(boolObject),
    collectionAuthorization: autoGeneratedColumnsEnumNormalizer(boolObject),
    singlePayment: autoGeneratedColumnsEnumNormalizer(boolObject),
    paymentBlock: autoGeneratedColumnsEnumNormalizer(boolObject),
    deferredTax: autoGeneratedColumnsEnumNormalizer(boolObject),
    equalization: autoGeneratedColumnsEnumNormalizer(boolObject),
    subjectToWithholdingTax: autoGeneratedColumnsEnumNormalizer(boolObject),
    thresholdOverlook: autoGeneratedColumnsEnumNormalizer(boolObject),
    surchargeOverlook: autoGeneratedColumnsEnumNormalizer(boolObject),
    insuranceOperation347: autoGeneratedColumnsEnumNormalizer(boolObject),
    hierarchicalDeduction: autoGeneratedColumnsEnumNormalizer(boolObject),
    withholdingTaxCertified: autoGeneratedColumnsEnumNormalizer(boolObject),
    bookkeepingCertified: autoGeneratedColumnsEnumNormalizer(boolObject),
    affiliate: autoGeneratedColumnsEnumNormalizer(boolObject),
    datevFirstDataEntry: autoGeneratedColumnsEnumNormalizer(boolObject),
    useShippedGoodsAccount: autoGeneratedColumnsEnumNormalizer(boolObject),
    noDiscounts: autoGeneratedColumnsEnumNormalizer(boolObject),
    endorsableChecksFromBP: autoGeneratedColumnsEnumNormalizer(boolObject),
    acceptsEndorsedChecks: autoGeneratedColumnsEnumNormalizer(boolObject),
    blockSendingMarketingContent: autoGeneratedColumnsEnumNormalizer(boolObject),
    useBillToAddrToDetermineTax: autoGeneratedColumnsEnumNormalizer(boolObject),
    fceRelevant: autoGeneratedColumnsEnumNormalizer(boolObject),
    fceValidateBaseDelivery: autoGeneratedColumnsEnumNormalizer(boolObject),
    businessPartnerGroup : autoGeneratedColumnsObjectNormalizer('name')
}
