import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root : {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "5px"
    }
}))


export default useStyles;