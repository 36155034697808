import React from 'react';

import Branch from '../Partials/Branch/Browse';
import WareHouse from '../Partials/WareHouse/Browse';
import ItemData from '../Partials/ItemData/Edit-View'
import Category from '../Partials/Category/Browse'
import Charge from '../Partials/Charges/Browse'
import Customer from '../Partials/Customer/Edit-View';
import APExpense from '../Partials/APExpense/Edit-View';
import Vendor from '../Partials/Vendor/Edit-View';
import Tax from '../Partials/Tax/Browse';
import PaymentMethods from '../Partials/PaymentMethods/Browse';

const AppBarLabels = [
    { label: "Branch" },
    { label: "Warehouses" },
    { label: "Customer" },
    { label: "Vendor" },
    { label: "Item master data" },
    { label: "Tax" },
    { label: "Category" },
    { label: "Charge" },
    { label: "PaymentMethods" },
    { label: "AP Expense" },
];

const AppBarComponents = [
    { component: (props) => <Branch {...props}></Branch> },
    { component: (props) => <WareHouse {...props}></WareHouse> },
    { component: (props) => <Customer {...props}></Customer> },
    { component: (props) => <Vendor {...props}></Vendor> },
    { component: (props) => <ItemData {...props}></ItemData> },
    { component: (props) => <Tax {...props}></Tax> },
    { component: (props) => <Category {...props}></Category> },
    { component: (props) => <Charge {...props}></Charge> },
    { component: (props) => <PaymentMethods {...props}></PaymentMethods> },
    { component: (props) => <APExpense {...props}></APExpense> },
   
];

const ActionButtons = [
    { key: 'checkup', label: "Check up", icon: "done_all", toolTip: "Check up all settings" },
    { key: 'updateInfo', label: "Update info", icon: "call_made", toolTip: "Synchronize all updates" }
]

export { AppBarLabels, AppBarComponents, ActionButtons }
