import React from 'react'
import { FormBuilder, ActionButton } from '~/Components/From';
import useStyles from './styles';
import { Button } from '@material-ui/core';

const ObjectsArrayForm = ({ formData , formikProps, handleSubmit, name, editValues, cancelAction, isEdit, onChange }) => {
    const classes = useStyles();

    return (
        <div className={classes.FormWrapper}>
            <FormBuilder {...formikProps}
                formData={formData}
                formName={name}
                editValues={editValues}
                submitAction={handleSubmit}
                customStyle={{ flexBasis: "100%" }}
                onChange={onChange}
            >
                <div style={{ flexBasis: "100%", display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.SubmitButton}
                        onClick={cancelAction}
                    >
                        Cancel
                    </Button>

                    <ActionButton customClass={classes.SubmitButton} title={isEdit ? 'Update' : 'Add'}
                        icon={isEdit ? 'arrow_forward' : 'add'}
                    />
                </div>
            </FormBuilder>
        </div>
    )
}

export default ObjectsArrayForm
