import React from 'react'
import { IconButton, Icon, Tooltip } from '@material-ui/core'

const TableActionButton = ({ icon, title, action }) => {
    return (
        <Tooltip title={title}>
            <IconButton onClick={action} >
                <Icon> {icon} </Icon>
            </IconButton>
        </Tooltip>
    )
}

export default TableActionButton
