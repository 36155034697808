

export const columns = [
    { name: "Number", label: "Number", options: { filter: true, sort: true, } },
    { name: "EquipmentCode", label: "Equipment Code", options: { filter: true, sort: true, } },
    { name: "KiloMetraj", label: "Kilo Metraj", options: { filter: true, sort: true, } },
    { name: "Maintenancetype", label: "Maintenance Type", options: { filter: true, sort: true, } },
    { name: "Date", label: "Date", options: { filter: true, sort: true, } },
    { name: "spareparts", label: "Spareparts", options: { filter: true, sort: true, } },
    { name: "Qty", label: "Qty", options: { filter: true, sort: true, } },
    { name: "WorkingHours", label: "Working Hours", options: { filter: true, sort: true, } },
    { name: "ProjectCode", label: "Project Code", options: { filter: true, sort: true, } },
];



export const data = [
    { Number: "1", EquipmentCode: "EQ-001", KiloMetraj: "1231314", Maintenancetype: "صيانة عادية ", Date: "11/1/2020", spareparts: "SP-0002", Qty: "2", WorkingHours: "2", ProjectCode: "10", },
    { Number: "2", EquipmentCode: "EQ-002", KiloMetraj: "5544511", Maintenancetype: "صيانة دورية", Date: "11/15/2020", spareparts: "SP-0005", Qty: "5", WorkingHours: "5", ProjectCode: "10", },
    { Number: "3", EquipmentCode: "EQ-001", KiloMetraj: "2156435153", Maintenancetype: "صيانة اضطرارية", Date: "11/10/2020", spareparts: "SP-0010", Qty: "6", WorkingHours: "6", ProjectCode: "10", },
]


