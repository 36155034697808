

export const columns = [

    { name: "dateOfClaim", label: "Date of Claim", options: { filter: false, sort: true, display: true } },
    { name: "periodOfClaim", label: "Period of Claim", options: { filter: false, sort: true, display: true } },
    { name: "claimType", label: "Claim Type", options: { filter: false, sort: true, display: true } },
    { name: "claimAmount", label: "Claim Amount", options: { filter: false, sort: true, display: true } },
    { name: "projectCode", label: "Project Code", options: { filter: false, sort: true, display: true } },
    { name: "stage", label: "Stage", options: { filter: false, sort: true, display: true } },
    { name: "attachments", label: "Attachments", options: { filter: false, sort: true, display: true } },

];




export const data = [
    { dateOfClaim: "dateOfClaim", periodOfClaim: "periodOfClaim", claimType: "claimType", claimAmount: "claimAmount", projectCode: "projectCode", attachments: "attachments", stage: "stage" },
    { dateOfClaim: "dateOfClaim2", periodOfClaim: "periodOfClaim", claimType: "claimType", claimAmount: "claimAmount", projectCode: "projectCode", attachments: "attachments", stage: "stage" },
    { dateOfClaim: "dateOfClaim6", periodOfClaim: "periodOfClaim", claimType: "claimType", claimAmount: "claimAmount", projectCode: "projectCode", attachments: "attachments", stage: "stage" },
    { dateOfClaim: "dateOfClaim2", periodOfClaim: "periodOfClaim", claimType: "claimType", claimAmount: "claimAmount", projectCode: "projectCode", attachments: "attachments", stage: "stage" },
    { dateOfClaim: "dateOfClaim5", periodOfClaim: "periodOfClaim", claimType: "claimType", claimAmount: "claimAmount", projectCode: "projectCode", attachments: "attachments", stage: "stage" },
    { dateOfClaim: "dateOfClaim5", periodOfClaim: "periodOfClaim", claimType: "claimType", claimAmount: "claimAmount", projectCode: "projectCode", attachments: "attachments", stage: "stage" },
    { dateOfClaim: "dateOfClaim5", periodOfClaim: "periodOfClaim", claimType: "claimType", claimAmount: "claimAmount", projectCode: "projectCode", attachments: "attachments", stage: "stage" },

]



