import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
    root : {
        width  : '100%' ,
        display : 'flex' , 
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems : 'center',
        padding : theme.spacing(1),
    },
    header : { 
        width : '100%' ,
        display : 'flex' ,
        justifyContent : 'space-between',
        flexWrap : 'wrap',
        margin : '5px 0px' ,
        '& > span' : { 
            flex : 1,
            flexShrink : 100,
            textAlign : 'left',
        }
    },
    MaintenanceItem : {
        width : '100%',
        display : 'flex',
        justifyContent : 'space-between',
        flexWrap : 'wrap',
        margin : '5px 0px',
        '& > span' : { 
            flex : 1,
            flexShrink : 100,
            textAlign : 'left',
            paddingRight:  theme.direction === "ltr"  ? theme.spacing(2) : 0,
            paddingLeft:  theme.direction === "rtl"  ? theme.spacing(2) : 0,
        }
    }
}))

export default useStyles;