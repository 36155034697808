import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    sapCode: Yup.string().trim().required(),
    foodicsID: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        sapCode: '',
        foodicsID: '',
    },
};
const FoodicsID = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}`,
            id: `${element.name}_#_${element.id}`
        })
    });
    return Options;
}
const sapCodeParseData = (data) => {
    const Options = []
    data=data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.number}-${element.groupName}`,
            id: `${element.groupName}_#_${element.number}`
        })
    });
    return Options;
}
export const formData = [
    { name: "foodicsID", company: true, type: "dropdown", optionsURI: "/Foodics/Categories", parseData: FoodicsID },
    { name: "sapCode", company: true, type: "dropdown", optionsURI: "/Inventory/ItemGroups/Short", parseData: sapCodeParseData },
];
