import React from 'react'
import RequestBusinessTripForm from '../Partials/RequestBusinessTripForm'
import useStyles from './styles'

function AddRequestBusinessTrip(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <RequestBusinessTripForm {...props} />
        </div>
    )
}

export default AddRequestBusinessTrip;
