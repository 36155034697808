import { makeStyles } from '@material-ui/core'


const useStyles = makeStyles(theme => {
    return {
        root : { 
            paddingLeft: `${theme.spacing(2)}px`
        },
        label: {
            fontFamily: 'Montserrat',
            fontSize: 14,
            textAlign: 'left',
            width:'100%',
            marginTop:'10px',
            color: theme.palette.primary.main,
            marginLeft: 5,
        },
        
    }
})


export default useStyles;