import React from 'react'
import DocumentTypesForm from '../Partials/DocumentTypesForm'
import useStyles from './styles'

function AddDocumentTypes(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <DocumentTypesForm {...props}  />
        </div>
    )
}

export default AddDocumentTypes;
