import { withStyles } from '@material-ui/core';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import AutoSyncForm from '../Partials/AutoSyncForm';
import styles from './styles';


class ViewAutoSync extends React.Component {
    view = "show";
    data = {
        "Startdatetime": "11/16/2020",
        "Intervalsecond": "15",
        "Name": "N",
        "Active": "Yes",
        "Syncfromdate": "11/16/2020",
        "Lastsussedsyncdatatime": "11/16/2020",
        "Laststartdatetime": "11/16/2020",
        "Lastenddatetime": "11/16/2020"
    };
    loading = false;

    componentDidMount() {
        // this.loading = true;
        // this.props.entityStore.get(this.id)
    }

    setView = (view) => {
        this.view = view;
    }

    entityDidReceived(data) {
        if (!!data) {
            this.loading = false;
            let { contractTypeCode, contractNum, cardName, fromCity, toCity, withReturn, returnRate, amount, equipmentCode1, equipmentCode2, project, attach } = data;

            this.data = {
                contractTypeCode, contractNum, cardName, fromCity, toCity, withReturn, returnRate, amount, equipmentCode1, equipmentCode2, project, attach
            }

        }
    }

    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="Auto Sync Info."
                        showViewName="AutoSync"
                        data={toJS(this.data)}
                        setView={this.setView}
                    />
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <AutoSyncForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewAutoSync, {
    view: observable,
    data: observable
})




export default withStyles(styles)(asEntity({ storeId: "AutoSyncForm" })(observer(ViewAutoSync)));
