
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    personalBrief: Yup.string().trim().required(),
    emergencyContact: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        personalBrief: "",
        emergencyContact: "",
    },
};


export const formData = [
    { name: "personalBrief", type: "textarea" },
    { name: "emergencyContact", type: "number" },

];


