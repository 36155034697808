import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import VacationRequestForm from '../Partials/VacationRequestForm';
import styles from './styles';


class ViewVacationRequest extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';

    data = {
        register: "register",
        type: "type",
        from: `${new Date()}`,
        to: `${new Date()}`,
        considerHolidays: "considerHolidays",
        requireExitReEntry: "requireExitReEntry",
        exitReEntryId: "exitReEntryId",
        exitReEntryFrom: "exitReEntryFrom",
        exitReEntryTo: "exitReEntryTo",
        requireTickets: "requireTickets",
        ticketsNumber: "202154",
        ticketsAirlines: "Airlines",
        ticketsType: "Type",
        settleRemaining: "settleRemaining",
        reason: "reason",
        attachment: "attachment"
    };
    loading = false;


    componentDidMount() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 600);
    }
    setView = (view) => {
        this.view = view;
    }


    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="Vacation Request Details."
                        data={this.data}
                        setView={this.setView}
                        showViewName="VacationRequests"
                    />
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <VacationRequestForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewVacationRequest, {
    view: observable,
    loading: observable,

})




export default withStyles(styles)(asEntity({ storeId: "VacationRequestForm" })(observer(ViewVacationRequest)));
