import { Grid, IconButton, LinearProgress, Link } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FileHeader } from './FileHeader';
import useStyles from '../Partials/FileHeader/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ApiConfigs from "~/Configs/Api";

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
export function ShowSingleFileUploaded({ value, onDelete }) {
    let parsedData = isJson(value) ? JSON.parse(value) : value;
    const classes = useStyles();
    return (
        <Grid item>
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                    <aside className={classes.thumbsContainer}>
                        {parsedData.original_name ?
                            <Link href={ApiConfigs.storageUrl + parsedData.download_link} target="_blank">
                                <div className={classes.fileNamewithIcon}>
                                    <AttachFileIcon /> {parsedData.original_name}
                                </div>
                            </Link>
                            :
                            <div className={classes.thumb} key={parsedData?.original_name ? parsedData?.original_name : parsedData}>

                                <div className={classes.thumbInner}>
                                    <img src={`${ApiConfigs.storageUrl}${parsedData}`} className={classes.img} alt="" />
                                </div>
                            </div>
                        }
                    </aside></Grid>
                <Grid item>
                    <IconButton onClick={() => onDelete(parsedData, "oldUploaded")}  >
                        <DeleteOutlineIcon color="primary" />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
