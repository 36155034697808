

export const columns = [
    { name: "servCode", label: "Service Code", options: { filter: false, sort: true, display: true } },
    { name: "servName", label: "Service Name", options: { filter: true, sort: true, } },
    { name: "frgnName", label: "Foreign Name", options: { filter: true, sort: true, } },
    { name: "erpservCode", label: "ERP Service Code", options: { filter: true, sort: true, } },
    { name: "erpservName", label: "ERP Service", options: { filter: true, sort: true, } },
];






