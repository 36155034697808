import * as Yup from "yup";
import { FAParseData, costCenterParseData, eqTypeCodeParseData, docTypeParseData } from '../ItemForm/parsers'

export const validationSchema = Yup.object().shape({

});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {

    },
};

export let formData = [
    { name: 'docTypeCode', width: '48%', type: 'dropdown', company: true, optionsURI: '/FleetManagement/DocumentType', parseData: docTypeParseData },
    { name: 'docNum', type: 'text', width: '48%' },
    { name: 'issueDate', type: 'date', width: '48%' },
    { name: 'expDate', type: 'date', width: '48%' },
    { name: 'issuePlace', type: 'text', },
];