import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    servCode: Yup.string().trim().required(),
    servName: Yup.string().trim(),
    frgnName: Yup.string().trim(),
    erpservCode: Yup.string().trim(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        servCode: "",
        servName: "",
        frgnName: "",
        erpservCode: "",
    },
};

const ItemParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.itemCode}-${element.itemName}`,
            id: `${element.itemName}_#_${element.itemCode}`
        })
    });
    return Options;
}

export const formData = [
    { name: "servCode", type: "text", editDisabled: true },
    { name: "servName", type: "text" },
    { name: "frgnName", type: "text", },
    { name: "erpservCode", action: "show", company: true, type: "dropdown", optionsURI: `/Inventory/Item/Short?type=0`, parseData: ItemParseData,hasSearch:true, searchPre:"&search=" },
];