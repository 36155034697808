import React from 'react'
import AssetsAndCustody from './AssetsAndCustody';
import BasicInfo from './BasicInfo';
import ClaimTransactions from './ClaimTransactions';
import LeaveTransactions from './LeaveTransactions';
import Roles from './Roles';


const AppBarLabels = [
    { label: "Basic Info." },
    { label: "Roles" },
    { label: "Assets & Custody" },
    { label: "Claim Transactions" },
    { label: "Leave Transactions" },
];

const AppBarComponents  = [
    { component: (props) => <BasicInfo {...props}></BasicInfo> },
    { component: (props) => <Roles {...props}></Roles> },
    { component: (props) => <AssetsAndCustody {...props}></AssetsAndCustody> },
    { component: (props) => <ClaimTransactions {...props}></ClaimTransactions> },
    { component: (props) => <LeaveTransactions {...props}></LeaveTransactions> },
];

export { AppBarLabels, AppBarComponents }
