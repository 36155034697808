

export const columns = [
    { name: "repCode", label: "Repair Type Code", options: { filter: false, sort: true, display: true } },
    { name: "repName", label: "Repair Type Name", options: { filter: true, sort: true, } },
    { name: "frgnName", label: "Foreign Name", options: { filter: true, sort: true, } },
];






