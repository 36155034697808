

export const columns = [
    { name: "eqCode",  options: { filter: false, sort: true, display: true } },
    { name: "equiName", options: { filter: true, sort: true, } },
    { name: "frgnName", options: { filter: true, sort: true, } },
    { name: "eqType",  options: { filter: true, sort: true, } },
    { name: "factorName", options: { filter: true, sort: true, } },
    { name: "modelCode",  options: { filter: true, sort: true, } },
    { name: "model",  options: { filter: true, sort: true, } },
    { name: "serialNum", options: { filter: true, sort: true, } },
    { name: "facode",  options: { filter: true, sort: true, } },
    { name: "costCenterCode",  options: { filter: true, sort: true, } },
    { name: "costCenterDimensionsCode", options: { filter: true, sort: true, } },
];








