const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    Edit: {
        width: "100%",
    },
})


export default styles;