import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    sapSeriesCode: Yup.string().trim().required(),
    bpGroupCustomer: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        sapSeriesCode: '',
        bpGroupCustomer: '',
    },
};
const sapSeriesCode = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}-${element.seriesType}`,
            id: element.series
        })
    });
    return Options;
}
const bpGroupCustomer = (data) => {
    const Options = []
    data=data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}`,
            id: element.code
        })
    });
    return Options;
}


export const formData = [
    { name: "sapSeriesCode", type: "dropdown", width: "48%", company: true, optionsURI: "Administration/Series/2", parseData: sapSeriesCode },
    { name: "bpGroupCustomer", type: "dropdown", width: "48%", company: true, optionsURI: "BusinessPartners/BusinessPartnerGroup/ByType/0", parseData: bpGroupCustomer },
];