
export const columns = [
    { name: "foodicsID", options: { filter: false, sort: true } },
    { name: "foodicsName", options: { filter: false, sort: false } },
    { name: "sapCode", options: { filter: false, sort: true, } },
    { name: "sapName", options: { filter: false, sort: true, } },
    { name: "sapCostCenterCode", options: { filter: false, sort: true, } },
    { name: "sapCostCenterName", options: { filter: false, sort: true, } },
    { name: "sapOneTimeCustomerCode", options: { filter: false, sort: true, } },
    { name: "sapWarehouseCode", options: { filter: false, sort: true, } },
    { name: "sapWarehouseName", options: { filter: false, sort: true, } },
    { name: "warehoseOffsetCode", options: { filter: false, sort: true, } },
    { name: "warehoseOffsetName", options: { filter: false, sort: true, } },
];

