

export const columns = [
    { name: "docNum", label: "DocNum", options: { filter: false, sort: true, display: true } },
    { name: "docTypeCode", label: "DocTypeCode", options: { filter: false, sort: true, display: true } },
    { name: "issueDate", label: "IssueDate", options: { filter: false, sort: true, display: true } },
    { name: "expDate", label: "ExpDate", options: { filter: false, sort: true, display: true } },
    { name: "issuePlace", label: "IssuePlace", options: { filter: false, sort: true, display: true } },
];








