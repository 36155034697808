import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    foodicsID: Yup.string().required(),
    sapCode: Yup.string().trim().required(),
    sapCostCenterCode: Yup.string().trim().required(),
    sapOneTimeCustomerCode: Yup.string().required(),
    sapWarehouseCode: Yup.string().required(),

});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        foodicsID: "",
        sapCode: "",
        sapCostCenterCode: "",
        sapOneTimeCustomerCode: "",
        sapWarehouseCode: ""
    },
};



const costCenterParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {

        Options.push({
            name: `${element.centerCode}-${element.centerName}`,
            id: `${element.centerName}_#_${element.centerCode}`
        })
    });
    return Options;
}
const WarehouseCodeParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.warehouseName}`,
            id: `${element.warehouseName}_#_${element.warehouseCode}`
        })
    });
    return Options;
}
const FoodicsID = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}`,
            id: `${element.name}_#_${element.id}`
        })
    });
    return Options;
}

const sapCodeParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}`,
            id: `${element.name}_#_${element.code}`
        })
    });
    return Options;
}


export const formData = [
    { name: "foodicsID", width: "48%", company: true, type: "dropdown", optionsURI: "/Foodics/Branches", parseData: FoodicsID },
    { name: "sapCode", width: "48%", company: true, type: "dropdown", optionsURI: "/HR/Branch", parseData: sapCodeParseData },
    { name: "sapCostCenterCode", width: "48%", type: "dropdown", company: true, optionsURI: "/Financials/ProfitCenters", parseData: costCenterParseData },
    { name: "sapWarehouseCode", width: "48%", type: "dropdown", company: true, optionsURI: "/Inventory/Warehouse/Short", parseData: WarehouseCodeParseData },
    { name: "warehoseOffsetCode", width: "48%", type: "dropdown", company: true, optionsURI: "/Inventory/Warehouse/Short", parseData: WarehouseCodeParseData },
    { name: "sapOneTimeCustomerCode", width: "48%", type: "text " },
];