import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modal_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  modal: {
    width: 450,
    height: 200,
    backgroundColor: '#fff',
    padding: 10,
    borderWidth: 0,
    outline: 'none',
    borderRadius: 5,
    boxShadow: '0 2.5px 10px 0 rgba(0, 0, 0, 0.16)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  buttons_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '90%',
    height: '85%',
  },
  first_button: {
    width: 101,
    height: 38.5,
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
    shadowColor: 'rgba(44, 39, 56, 0.08)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 1,
    margin: 10,
    textAlign: 'center',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  second_button: {
    width: 101,
    height: 38.5,
    borderRadius: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.secondary.main,
    margin: 10,
    color: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  header: {
    fontFamily: 'Montserrat',
    fontSize: '24px',
    textAlign: 'left',
    padding: 20,
  },
}));

export default useStyles;
