import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    destinationFrom: Yup.string().trim().required(),
    destinationTo: Yup.string().required(),
    dateFrom: Yup.date().required(),
    dateTo: Yup.date().required(),
    project: Yup.string().required(),
    advanceRequest: Yup.string().trim().required(),
    amounted: Yup.string().required(),
    airTicket: Yup.string().trim().required(),
    visaRequired: Yup.string().trim().required(),
    country: Yup.string().trim().required(),
    exitReEntryVisa: Yup.string().trim().required(),
    accomodationReservation: Yup.string().trim().required(),
    carRentalReservation: Yup.string().trim().required(),

});


export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        destinationFrom: "",
        destinationTo: "",
        project: "",
        amounted: "",
        advanceRequest: "",
        airTicket: "",
        visaRequired: "",
        country: "",
        exitReEntryVisa: "",
        accomodationReservation: "",
        carRentalReservation: "",
    },
};
let ValueCheck = (value) => value


export const formData = [
    { name: "destinationFrom", title: "Destination From", type: "text", width: "48%" },
    { name: "destinationTo", title: "Destination To", type: "text", width: "48%" },
    { name: "dateFrom", title: "Date From", type: "date", width: "48%", focused: true, },
    { name: "dateTo", title: "Date To", type: "date", width: "48%", focused: true, },
    { name: "project", type: "text" },
    { name: "advanceRequest", type: "switch" },
    { name: "amounted", type: "text", action: "toggle", actionONFields: [{ name: 'advanceRequest', schema: ValueCheck }], },
    { name: "airTicket", type: "text" },
    { name: "visaRequired", type: "switch" },
    { name: "country", type: "text", action: "toggle", actionONFields: [{ name: 'visaRequired', schema: ValueCheck }] },
    { name: "exitReEntryVisa", type: "switch" },
    { name: "accomodationReservation", type: "switch" },
    { name: "carRentalReservation", type: "switch" },
];


