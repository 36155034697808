import React from 'react'
import EquipmentMasterDataForm from '../Partials/EquipmentMasterDataForm'
import useStyles from './styles'

function AddEquipmentMasterData(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <EquipmentMasterDataForm {...props}  />
        </div>
    )
}

export default AddEquipmentMasterData;
