import { Button, withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { ActionButton, FormBuilder } from '~/Components/From';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { isEmpty } from '~/Services/Helpers';
import { NotificationContext } from '~/Services/Providers';
import { formData, formikProps } from './data';
import useStyles from "./styles";

class ItemForm extends React.Component {
    static contextType = NotificationContext;

    values = this.props.editValues ? this.props.editValues : {};


    handleSubmit = (values, editValues = {}) => {
        this.loading = true;
        let newValue =
        {
            "eqCode": this.props.code,
            "docTypeCode": values.docTypeCode,
            "docNum": values.docNum,
            "issueDate": values.issueDate,
            "expDate": values.expDate,
            "issuePlace": values.issuePlace
        };

        if (this.props.actionType == "show") {

            this.props.entityStore.post(newValue)
        }
        else {
            this.props.entityStore.patch({ id: values.docNum, editValues })
        }
    };


    successActions = (message) => {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'success',
            message
        }
        ))
        this.props.handleClose()
        this.props.refresh();
    }

    entityDidCatch(errors) {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'error',
            message: isEmpty(errors?.title) ? "Something went wrong" : errors.title
        }))
    }

    entityDidReceived(data) {
        if (!!data) {
            this.getLoading = false;
            this.props.handleClose()
            this.props.refresh();

        };
    }

    entityDidPosted(data) {
        this.successActions('EquipmentDocument Added successfully');


    };

    entityDidUpdated(data) {
        this.successActions('EquipmentDocument Updated successfully');

    }


    render() {
        const { classes } = this.props;

        if (this.getLoading) {
            return <Loading />
        }
        return (
            <>
                <FormBuilder {...formikProps}
                    formData={formData}
                    formName="ItemForm"
                    editValues={this.values}
                    submitAction={this.handleSubmit}
                    customStyle={{ flexBasis: "100%" }}
                >
                    <div style={{ flexBasis: "100%", display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.SubmitButton}
                            onClick={(e) => this.props.handleClose()}
                        >
                            Cancel
                        </Button>
                        <ActionButton customClass={classes.SubmitButton} title={this.props.actionType != "show" ? 'Edit' : 'Add'}
                            icon={this.props.actionType != "show" ? 'arrow_forward' : 'add'}
                            loading={this.loading}
                        />

                    </div>
                </FormBuilder>
            </>

        )
    }
}


decorate(ItemForm, {
    loading: observable,
})


export default withStyles(useStyles)(asEntity({ storeId: "EquipmentDocumentForm" })(observer(ItemForm)));


