import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Table from '~/Components/EnhancedTable';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { DataToColumns } from "~/Services/Helpers/Normalizers"
import { columnsValuesNormalizer } from './data';


class BrowseEmployeesMasterData extends React.Component {
  static contextType = NotificationContext;
  constructor () {
    super();
    this.state = {
      tableData : [],
      loading : true,
      getLoading: false,
      columns : [],
      per_page : 20,
      page : 1,
      count : 20,
      totalCount : 20,
      hasNext : false,
      hasPreviousPage: false
    }
  }

  componentDidMount() {
    this.getData();
  }
  
  getData = (page = this.state.page) => {
    this.setState({
      getLoading: true
    })
    this.props.entityStore.get({ page, });
  };

  entityDidReceived(data) {
    if (!!data) {
      const columns = DataToColumns(data, columnsValuesNormalizer);
      console.log('columns', columns);
      this.setState({
        hasNext: data.hasNextPage,
        hasPreviousPage: data.hasPreviousPage,
        columns: columns ? columns : [],
        tableData: columns.length > 0 ? data : [],
        totalCount: data.totalCount,
      })
      if (data.hasNextPage) {
        this.setState({
          count: this.state.count + this.state.per_page
        })
      }
      this.setState({
        loading: false,
        getLoading: false
      })
    }
  }

  entityDidCatch(error) {
    this.setState({
      loading: false,
      getLoading: false
    })
    this.notify("error", error.title)

  }


  notify = (severity, message) => {
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity,
      message
    }))
  }

  ParentTableChange = (action, { page, rowsPerPage, searchText }) => {
    if (this.state.hasNext || this.state.hasPreviousPage) {
      if (
        !this.state.loading &&
        (action === "changePage" || action === "changeRowsPerPage")
      ) {
        this.getData(page + 1, rowsPerPage);
        this.setState({
          page: page + 1,
        });
      }
    }
  };

  render() {


    if (this.loading) {
      return <Loading />
    }

    const options = {
      serverSide: true,
      count: this.state.totalCount,
      rowsPerPage: 20
    };


    return (
      <>
        { this.state.getLoading && <Loading /> }
        <div style={{ display: this.state.getLoading ? 'none' : 'block' }}>
          <Table
            showKeyIndex={0}
            addButtonText="Add Employee"
            title="Employees"
            defaultData={this.state.tableData}
            columns={toJS(this.state.columns)}
            parentDeleteAction={this.onDeleteConfirm}
            ParentTableChange={this.ParentTableChange}
            customOptions={options}
          />
        </div>
      </>
    )
  }
}

decorate(BrowseEmployeesMasterData, {
  tableData: observable.shallow,
  loading: observable,
})

export default asEntity({ storeId: 'BrowseEmployeesMasterData' })(observer(BrowseEmployeesMasterData));