import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
    ListItemText: {
        minWidth: "150px",
        display: "inline-block",
    },
    ListItem: {
        textAlign: theme.direction === "ltr" ? "left" : "right"
    },
 
}))

export default useStyles;