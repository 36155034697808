import { Button } from '@material-ui/core';
import React, { useState , useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../../Modal';
import ObjectsArrayForm from './Partials/ObjectsArrayForm';
import ShowComponent from './Partials/ShowComponent';
import useStyles from './styles';
import { isEmpty } from '~/Services/Helpers'
import uuid from 'react-uuid';

const ObjectsArray = ({ values, formikProps, formData, name, editValue, InputChange  , formDependency, showComponentType, onChange, ...rest }) => {
    const [subFormEditValues, setSubFormEditValues] = useState({})
    const [modalOpen, setModalOpen] = useState(false)
    const [subFormValues, setSubFormValues] = useState([])
    const classes = useStyles();
    
    useEffect(() => {
        if(!onChange)
            return;
        onChange({
            target: {
                name: 'transactions',
                value: subFormValues
            }
        }, InputChange, values);
    }, [subFormValues])

    const handleSubFromSubmit = (values, editedValues = []) => {
        if (!isEmpty(subFormEditValues)){
            updateAction( subFormEditValues.id , { id : uuid() , ...values});
            setSubFormEditValues({});
        }else{
            setSubFormValues(currentValues =>  [ ...currentValues ,{ id : uuid() , ...values} ]);
        }
        toggleModal();
    }

    const deleteAction = (id) => {
        const newValues = subFormValues.filter(entry => entry.id !== id)
        setSubFormValues(newValues);
    }

    const editAction= (editValues) => { 
        setSubFormEditValues(editValues);
        toggleModal();
    }
    
    const updateAction = (id , updatedValues) => {
        const newValues = subFormValues.map(entry => entry.id !== id ? entry : updatedValues )
        setSubFormValues(newValues);
    }

    useEffect(() => {
        if (!isEmpty(editValue)){
            setSubFormValues(editValue);
        }
    })

    useEffect(() => {
        InputChange(name ,subFormValues)
    }, [subFormValues])
    
    const toggleModal = () => setModalOpen(!modalOpen);

    return (<>
        <Modal handleClose={toggleModal} open={modalOpen}>
            <ObjectsArrayForm
                onChange={onChange}
                formikProps={formikProps}
                formData={formData}
                name={name}
                editValues={subFormEditValues}
                handleSubmit={handleSubFromSubmit}
                cancelAction={toggleModal}
                isEdit={!isEmpty(subFormEditValues)}
            />
        </Modal>

        <Button
            onClick={toggleModal}
            className={classes.AddButton}
            color="secondary"
            variant="contained"
        >
            <FormattedMessage id="add" />  <FormattedMessage id={name} />
        </Button>

        <ShowComponent 
            type={showComponentType}
            values={subFormValues}
            formName={name}
            editAction={editAction}
            deleteAction={deleteAction}
        />
    </>)
}

ObjectsArray.defaultProps = {
    formDependency: [],
}
export default ObjectsArray
