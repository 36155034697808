import { normalizeRoutData } from "~/Services/Helpers/Normalizers";
import { getStorage } from '~/Services/Helpers';
import { StorageKeys } from '~/Configs/Storage';
let RoutData = {}
export const DefaultNav = {
  
}
const getRoutData = () => { return RoutData; }

export const SetRoutData = (perm, navData = {}) => {
    if (!perm) { perm = getStorage(StorageKeys.permissions); }
    RoutData = perm && perm.length > 0 ? normalizeRoutData(navData, perm) : normalizeRoutData({}, [])
    // RoutData = navData

}
export default {
    active: 'Administration',
    data: getRoutData,
    routData: getRoutData()
};
