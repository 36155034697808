import React from 'react';
import ProfileListItem from '../ProfileListItem';
import { Input, PhoneNumInput } from '~/Components/From';
import { Button } from '@material-ui/core';
import { formData } from './data'
import { asEntity, withFormik } from '~/Hoc';
import { Form } from 'formik'
import { observer } from 'mobx-react'
import { decorate, observable } from 'mobx'

class ProfileForm extends React.Component {
    name = this.props.userContextObj.data.name;
    phoneNumber = this.props.userContextObj.data.phoneNumber ? this.props.userContextObj.data.phoneNumber : "";

    componentDidMount() {
        this.props.setValues({ name: this.name, phoneNumber: this.phoneNumber });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let validate = this.props.validateForm();

        validate.then((err) => {
            if (this.props.isValid) {
                this.loading = true;
                const values = []
                Object.keys(this.props.values).forEach(key => {
                    if (this.props.values[key]) {
                        values.push({
                            op: 'replace',
                            path: `/${key}`,
                            value: this.props.values[key],
                        })
                    }
                })
                this.props.entityStore.post(values);
            }
        });
    };
    entityDidReceived(data) {
        this.loading = true;
        this.props.handelSubmit(this.props.values)
    }


    render() {
        const { OpenTab, handleTabClick } = this.props;

        const ConfirmButton = () => (
            <div style={{ flexBasis: '25%', textAlign: 'right' }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: 16 }}
                    type="submit"
                >
                    Submit
                </Button>

            </div>
        )
        return (
            <Form id="profile-form" noValidate onSubmit={this.handleSubmit}>
                <ProfileListItem title="Name" handleTabClick={handleTabClick} open={"Name" === OpenTab} value={this.name}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '450px', flexWrap: 'wrap' }}>
                        <Input {...formData.name}
                            value={this.props.values[formData.name.name]}
                            error={!!this.props.errors[formData.name.name]}
                            errorText={this.props.errors[formData.name.name]}
                            InputChange={this.props.handleChange}

                        />
                        <ConfirmButton />
                    </div>
                </ProfileListItem>



                <ProfileListItem title="Phone Number" handleTabClick={handleTabClick} open={"Phone Number" === OpenTab} value={this.phoneNumber}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '450px', flexWrap: 'wrap' }}>
                        <PhoneNumInput {...formData.phoneNumber}
                            value={this.props.values[formData.phoneNumber.name]}
                            error={!!this.props.errors[formData.phoneNumber.name]}
                            errorText={this.props.errors[formData.phoneNumber.name]}
                            InputChange={this.props.setFieldValue}
                        />
                        <ConfirmButton />

                    </div>
                </ProfileListItem>

            </Form>
        )
    }
}


decorate(ProfileForm, {
    name: observable,
    phoneNumber: observable
})

export default withFormik(asEntity({ storeId: "ProfileFrom" })(observer(ProfileForm)));
