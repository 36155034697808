export const data = [
    { name: "ARDelivry", path: (id)=> `FleetManagement/ServiceContractORSC/${id}/print`  },
    { name: "APDelivry", path: (id)=> `FleetManagement/ServiceContractOPSC/${id}/print`  },
    
];





















