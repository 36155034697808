import * as Yup from "yup";
import { ItemParseData, ProjectParseData, MaintenanceTypeParseData, WarehouseCodeParseData, EquipmentTypeParseData } from './parsers'


export const validationSchema = Yup.object().shape({
    eqCode: Yup.string().trim().required(),
    kiloMeters: Yup.number(),
    maintCode: Yup.string().trim(),
    dueDate: Yup.string().trim(),
    maintenanceTrancstionIMT1s: Yup.array()
});

export const TestValidationSchema = Yup.object().shape({
    itemCode: Yup.string().trim().required(),
    warehouseCode: Yup.string().trim().required(),
    projectCode: Yup.string().required(),
    qty: Yup.number().required(),
    workHour: Yup.number().required(),
    remark: Yup.string()
});

export const testFormikProps = {
    validationSchema: TestValidationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        itemCode: '',
        warehouseCode: '',
        projectCode: '',
        qty: '',
        workHour: '',
        remark: '',
    },
};

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        eqCode: "",
        kiloMeters: "",
        maintCode: "",
        dueDate: "",
        maintenanceTrancstionIMT1s: [],
    },
};

let ItemCodeType = -1


export const detailForm = [
    { name: "itemCode", company: true, type: "dropdown", optionsURI: `/Inventory/Item/Short?type=0`, parseData: ItemParseData,hasSearch:true, searchPre:"&search=" },
    { name: "qty", type: "number", width: "48%", },
    { name: "workHour", type: "number", width: "48%", },
    { name: "projectCode", type: "dropdown", company: true, optionsURI: '/Financials/Project/Short', parseData: ProjectParseData, hasSearch:true, searchPre:"?search=" },
    { name: "warehouseCode", type: "dropdown", company: true, optionsURI: "/Inventory/Warehouse/Short", parseData: WarehouseCodeParseData ,hasSearch:true, searchPre:"?search="},
    { name: "remark", type: "textarea", maxWords: 25 },
];

export const formData = [
    { name: "eqCode", company: true, type: "dropdown", optionsURI: "/FleetManagement/EquipmentMasterData", parseData: EquipmentTypeParseData, width: "48%", },
    { name: "kiloMeters", type: "number", width: "48%", },
    { name: "maintCode", company: true, type: "dropdown", optionsURI: "/FleetManagement/MaintenanceType", parseData: MaintenanceTypeParseData, width: "48%", },
    { name: "dueDate", title: 'Due date', type: "date", width: "48%" },
    { name: "remark", type: "textarea", maxWords: 25 },
    {
        name: "attKey",
        label: "attach",
        type: "uploader",
        width: "100%",
        accept: ['image/*', ".pdf", ".doc", "audio/*", "video/*"],
        aspectRatio: "1:1",
        multiple: true,
        maxFileSize: 30
    },
    {
        name: "maintenanceTrancstionIMT1s", type: "objects-array",
        formikProps: testFormikProps, formData: detailForm, showComponentType: "maintenanceTransaction",
    },
];