import React from 'react'
import RequestForLettersForm from '../Partials/RequestForLettersForm'
import useStyles from './styles'

function AddRequestForLetters(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <RequestForLettersForm {...props} />
        </div>
    )
}

export default AddRequestForLetters;
