import React from 'react';
import { Modal } from '@material-ui/core';
import useStyles from './styles';

const ConfirmationModal = ({ confirmation, closeConfirmation, onSave }) => {
  const classes = useStyles();

  return (
    <>
      <Modal open={confirmation} className={classes.modal_wrapper}>
        <div className={classes.modal}>
          <div className={classes.header}>Are you sure you want to replace old file?</div>
          <div className={classes.buttons_wrapper}>
            <div className={classes.second_button} onClick={closeConfirmation}>
              Cancel
            </div>
            <div className={classes.first_button} onClick={onSave}>
              {'Confirm'}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ConfirmationModal;
