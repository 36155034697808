import React, { Component } from 'react';
import Login from './Steps/Login/index';
import ChoseCompany from "./Steps/ChoseCompany"
import get from "lodash/get";
import { NotificationContext } from '~/Services/Providers';
import { StorageKeys } from '~/Configs/Storage';
import { getStorage } from '~/Services/Helpers';



export default class componentName extends Component {
    static contextType = NotificationContext;
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            steps: [
                {
                    component: <Login moveForward={this.moveForward} notify={this.notify} />,
                },
                {
                    component: <ChoseCompany moveForward={this.moveFinish} />
                },

            ],
        };
    }
    moveForward = () => {
        this.setState({ activeStep: this.state.activeStep + 1, });
    }
    moveFinish = () => {
        this.props.history.push("/home-page");
    }
    get activeStep() {
        const { activeStep } = this.state;
        const step = get(this.state, `steps.${activeStep}`, {});
        return step;
    }
    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }
    render() {
        return (
            <>{this.activeStep.component}</>
        );
    }
}
