import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    Icon: {
        fontSize: 17,
    },
    text: {
        fontSize: 17
    },
    MenuItem: {
        textAlign: theme.direction ==='ltr' ? 'left' : 'right'
    } 
}))
export const Styles = {
    padding: (level = 0,) => ({
        padding: `${level * 8}px`,
    }),
}
export default useStyles;