import React from 'react';
import Styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormBuilder from "../FormBuilder";
import { decorate, observable, toJS } from "mobx";
import { observer } from "mobx-react";



class FormTabBuilder extends React.Component {
    value = 0;
    isAllFormValid

    componentDidMount() {
        this.isAllFormValid = this.initValidationArray()
    }
    handelSubmit = (TabIndex, ...params) => {
        this.isAllFormValid[TabIndex] = true
        if (this.isFormsValid()) {
        } else {
            // this.moveNext()
        }
    }
    initValidationArray = () => {
        let ValidationArray = []
        for (let i = 0; i < Object.keys(this.props.formData).length; i++) {
            ValidationArray.push(0)
        }
        return ValidationArray
    }
    isFormsValid = () => {
        let isAllValid = true;
        for (let i = 0; i < this.isAllFormValid.length; i++) {
            isAllValid &= this.isAllFormValid[i]
        }
        return isAllValid
    }
    moveNext = () => {
        for (let i = 0; i < this.isAllFormValid.length; i++) {
            if (!this.isAllFormValid[i]) {
                this.value = i
                return
            }
        }
    }

    render() {
        const { formData, children, classes, formikProps, submitAction, editValues } = this.props;
        return (
            <div className={classes.appBar}>
                <AppBar position='static' >
                    <Tabs
                        value={this.value} onChange={(event, value) => this.value = value}
                        variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs"
                    >
                        {Object.keys(formData).map((key, index) => {
                            return <Tab key={index} value={index} label={key} {...TabProps('' + index)} />
                        })}
                    </Tabs>
                </AppBar>
                {Object.keys(formData).map((key, index) => {
                    return <div
                        role="tabpanel"
                        hidden={this.value !== index}
                        id={`scrollable-auto-tabpanel-${index}`}
                        aria-labelledby={`scrollable-auto-tab-${index}`}
                    >
                        {this.value === index && (
                            <FormBuilder
                                {...formikProps[key]}
                                formData={formData[key]}
                                formName={key}
                                TabIndex={index}
                                editValues={editValues}
                                submitAction={this.handelSubmit}
                                onValidSubmit={true}
                                customStyle={{ flexBasis: "100%" }}
                            >
                            </FormBuilder>
                        )}
                    </div>
                })}
                {children}

            </div>
        )
    }
}

function TabProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

decorate(FormTabBuilder, {
    value: observable,
    isAllFormValid: observable
});
export default withStyles(Styles)((observer(FormTabBuilder)))
