import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    taskNumber: Yup.string().trim().required(),
    taskDescription: Yup.string().trim(),
    start: Yup.date(),
    end: Yup.date(),
    taskCategory: Yup.string().trim(),
    assignedPerson: Yup.string().trim(),
    reminder: Yup.string().trim(),
    typeNotfication: Yup.string().trim(),
    when: Yup.string().trim(),
    projectCode: Yup.string().trim(),
    projectStage: Yup.string().trim(),
});


export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        taskNumber: "",
        taskDescription: "",
        taskCategory: "",
        assignedPerson: "",
        reminder: "",
        typeNotfication: "",
        when: "",
        projectCode: "",
        projectStage: "",
    },
};


const projectStageItems = [
    { id: 0, name: "1", value: "1" },
    { id: 0, name: "2", value: "2" },
    { id: 0, name: "3", value: "3" },
];

const taskCategory = [
    { id: 0, name: "1", value: "1" },
    { id: 0, name: "2", value: "2" },
    { id: 0, name: "3", value: "3" },
];

const YesNo = [
    { id: false, name: "No " },
    { id: true, name: "Yes" },
]

const projectCode = (data) => {
    const positionData = []

    Array.isArray(data) && data.forEach(element => {
        positionData.push({
            name: `${element.code}-${element.name}`,
            id: element.code
        })
    });
    return positionData;
}
let ValueCheck = (value) => value


export const formData = [
    { name: "taskNumber", type: "text", editDisabled: true },
    { name: "taskDescription", type: "text" },
    { name: "start", title: "Start Date", type: "datetime", focused: true, width: "48%", },
    { name: "end", title: "End Date", type: "datetime", focused: true, width: "48%", },
    { name: "taskCategory", type: "dropdown", options: taskCategory },
    { name: "assignedPerson", type: "text", },

    { name: "projectCode", type: "dropdown", company: true, optionsURI: "/Financials/Project", parseData: projectCode },
    { name: "projectStage", type: "dropdown", options: projectStageItems },

    { name: "reminder", type: "switch", width: "100%" },
    { name: "when", type: "datetime", action: "toggle", actionONFields: [{ name: 'reminder', schema: ValueCheck }], },
    { name: "typeNotfication", type: "text", action: "toggle", actionONFields: [{ name: 'reminder', schema: ValueCheck }], },
];


