import { Button, Grid, IconButton, Link, Tooltip } from '@material-ui/core';
import React from 'react';
import { Visibility, DeleteOutline } from '@material-ui/icons';
import useStyles from './styles';
import ReplayIcon from '@material-ui/icons/Replay';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import ApiConfigs from "~/Configs/Api";
import { StorageKeys } from "~/Configs/Storage";
import { getStorage } from "~/Services/Helpers";
import axios from "axios";
const token = getStorage(StorageKeys.token);

const company = window.localStorage.getItem(StorageKeys.Company) ? window.localStorage.getItem(StorageKeys.Company).split(".")[0] : "";

export function FileHeader({ files, onDelete }) {
    const classes = useStyles();
    console.log(`files`, files)
    return (files.attachmentLines.map((file, index) => (
        <Grid container justify="space-between" alignItems="center">
            <Grid item>
                <aside className={classes.thumbsContainer}>
                    <div className={classes.thumb} key={file.fileTitel}>
                        {file.fileExtension.match(/.(jpg|jpeg|png|gif)$/i) ? <div className={classes.thumbInner}>
                            <img src={(ApiConfigs.getCompanyURL(company) + file.virtualPath).replace("api/~/", "")} className={classes.img} alt="" />
                        </div> :
                            <div className={classes.fileName}>
                                {file.fileTitel}
                            </div>}
                    </div>
                </aside></Grid>
            <Grid item className={classes.IconContainer}>
                <IconButton onClick={() => onDelete(file.attLineKey)}  >
                    <DeleteOutline color="primary" />
                </IconButton>
                <Link href={(ApiConfigs.getCompanyURL(company) + file.virtualPath).replace("api/~/", "")} target="_blank">
                    <Tooltip title="show file">
                        <Visibility color="primary" />
                    </Tooltip>
                </Link>
            </Grid>
        </Grid>
    ))
    );
}
