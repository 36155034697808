import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import { useAppContext } from "./AppContextProvider";

const ColorPalette = {
  primary: { main: "#283593" },
  secondary: { main: "#53599A" },
  danger: { main: "#D90368" },
  light: { main: "#F1E9DA" },
  dark: { main: "#0F0F0F" },
};
const overrides = {
  MUIDataTable: {
    paper: {
      display: "grid"
    }
  },
  PrivateTabIndicator: {
    colorSecondary: {
      backgroundColor: "#D9036899"
    }
  }
}
const RTLOverrides = {
  MUIDataTableToolbar: {
    actions: {
      textAlign: "left",
    }
  },
  MuiTableCell: {
    body: {
      textAlign: "right"
    }
  },
  PrivateNotchedOutline: {
    legendLabelled: {
      textAlign: "right",
      marginRight: 10,
    }
  },
  MuiInputLabel: {
    formControl: {
      right: 20,
      left: "unset",
    }
  },
  MuiFormHelperText: {
    root: {
      textAlign: "right"
    }
  },
  MuiButton: {
    endIcon: {
      marginRight: "8px",
      marginLeft: "-4px"
    }

  },
  MUIDataTable: {
    caption: {
      right: "-3000px",
      left: "unset",
    }
  },
  ...overrides
};

const LTROverrides = {
  PrivateNotchedOutline: {
    legendLabelled: {
      textAlign: "left",
    }
  },
  MuiInputLabel: {
    formControl: {
      // left: 21 ,
      right: "unset",
    }
  },
  ...overrides
}

export function ThemeContextProvider({ children }) {
  const { appContextObj } = useAppContext();
  const theme = createMuiTheme({
    direction: appContextObj.direction,
    overrides: appContextObj.direction === "rtl" ? RTLOverrides : LTROverrides,
    palette: {
      type: appContextObj.theme,
      ...ColorPalette
    },

  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
