import { makeStyles , lighten } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    deleteMessage: {
        minWidth: 350,
    },
    modalButton: {
        width: 80,
        height: 40,
        margin: theme.spacing(1),
    },
    modalBtnWrapper: {
        width: '100%',
        textAlign: 'right',
    },

}));

export default useStyles;