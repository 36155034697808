import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    spouse: Yup.string().trim().required(),
    dateBirth: Yup.date().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        spouse: "",
    },
};


export const formData = [
    { name: "spouse", type: "text" },
    { name: "dateBirth", title: "Date of Birth", type: "date", focused: true, },

];


