
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    deleteMessage: {
        minWidth: 350,
    },
    modalButton: {
        width: 80,
        height: 40,
        margin: theme.spacing(1),
    },
    modalBtnWrapper: {
        width: '100%',
        textAlign: 'right',
    }
}));

export default useStyles;