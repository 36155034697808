import React from 'react'
import BotForm from '../Partials/BotForm'
import useStyles from './styles'

function AddBot(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <BotForm {...props} />
        </div>
    )
}

export default AddBot;
