import React from 'react'
import useStyles from './styles'
import { isEmpty } from '~/Services/Helpers'
import TableAction from '~/Components/EnhancedTable/Partials/TableActions/Partials/TableActionButton'
import { FormattedMessage } from 'react-intl'


const MaintenanceTransaction = ({ values, formName, editAction, deleteAction }) => {
    const classes = useStyles();
    const columns = isEmpty(values) ? [] : Object.keys(values[0]);

    if (isEmpty(values)) {
        return <></>
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                {
                    !isEmpty(columns) && columns.map((key, index) => index === 0 ? <></> : <span>
                        <strong> <FormattedMessage id={`${formName}.${key}`} /> </strong>
                    </span>)
                }
                <span> <strong> Actions </strong></span>
            </div>

            {
                !isEmpty(columns) && values.map((row) => <div className={classes.MaintenanceItem}>
                    {
                        Object.values(row).map((value, index) => index === 0 ? <></> : <span> {value} </span>)

                    }
                    <span>
                        <TableAction title="Edit" icon="edit" action={() => editAction(row)} />
                        <TableAction title="Delete" icon="delete_outlined" action={() => deleteAction(row.id)} />
                    </span>
                </div>)
            }


        </div>
    )
}

export default MaintenanceTransaction
