import * as Yup from "yup";

export const validationSchema = Yup.object().shape({

});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
    },
};
const ParseData = (data) => {
    // const positionData = []
    // data.forEach(element => {
    //     positionData.push({
    //         name: "",
    //         id: ""
    //     })
    // });
    return [];
}
const BranchAssignment = [
    { id: 0, name: "Active " },
    { id: 1, name: "Inactive" },
    { id: 1, name: "Advanced" },
]
const ActiveInactive = [
    { id: 0, name: "Inactive" },
    { id: 1, name: "Active " },
]
const priority = [
    { id: 0, name: "Critical" },
    { id: 1, name: "Important" },
    { id: 1, name: "Normal" },
    { id: 1, name: "Low" },

]

export const headerFormData = [
    { name: "cardCode", type: "text", width: "48%", },
    { name: "cardName", type: "text", width: "48%", },
    { name: "cardForeignName", type: "text", width: "48%", },
    { name: "businessPartnerGroup", type: "text", width: "48%", },
    { name: "currency", type: "text", width: "48%", },
    { name: "federalTaxID", type: "text", width: "48%", },
    { name: "openOrdersBalance", type: "text", width: "48%", },


];

export const formData = {
    General: [
        { name: "tel1", type: "text", width: "48%", },
        { name: "tel2", type: "text", width: "48%", },
        { name: "phone1", type: "text", width: "48%", },
        { name: "fax", type: "text", width: "48%", },
        { name: "Email", type: "text", width: "48%", },
        { name: "website", type: "text", width: "48%", },
        { name: "shippingType", width: "48%", type: "dropdown", company: true, optionsURI: "", parseData: ParseData },
        { name: "password", type: "text", width: "48%", },
        { name: "indicator", type: "text", width: "48%", },
        { name: "project", type: "text", width: "48%", },
        { name: "industry", width: "48%", type: "dropdown", company: true, optionsURI: "", parseData: ParseData },
        { name: "businessType", width: "48%", type: "dropdown", company: true, optionsURI: "", parseData: ParseData },
        { name: "bpBranchAssignment", width: "48%", type: "dropdown", options: BranchAssignment, },
        { name: "federalTaxID", type: "text", width: "48%", },
        { name: "companyRegistrationNumber", type: "text", width: "48%", },
        { name: "channelBP", type: "text", width: "48%", },
        { name: "territory", type: "text", width: "48%", },
        { name: "globalLocationNumber", type: "text", width: "48%", },
        { name: "blockSendingMarketingContent", width: "48%", type: "dropdown", options: ActiveInactive, },
    ],
    "Payment terms": [
        { name: "paymentTermsType", type: "dropdown", width: "48%", company: true, optionsURI: "", parseData: ParseData },
        { name: "priceListNum", type: "dropdown", width: "48%", company: true, optionsURI: "", parseData: ParseData },
        { name: "discountPercent", type: "text", width: "48%", },
        { name: "creditLimit", type: "number", width: "48%", },
        { name: "maxCommitment", type: "number", width: "48%", },
        { name: "dunningTerm", type: "text", width: "48%", },
        { name: "automaticPosting", type: "dropdown", width: "48%", company: true, optionsURI: "", parseData: ParseData },
        { name: "effectiveDiscount", type: "dropdown", width: "48%", company: true, optionsURI: "", parseData: ParseData },
        { name: "effectivePrice", type: "dropdown", width: "48%", company: true, optionsURI: "", parseData: ParseData },
        { name: "bankCountry", type: "text", width: "48%", },
        { name: "bankName", type: "text", width: "48%", },
        { name: "defaultBankCode", type: "text", width: "48%", },
        { name: "defaultAccount", type: "text", width: "48%", },
        { name: "defaultBankBranch", type: "text", width: "48%", },
        { name: "defaultBankIBAN", type: "text", width: "48%", },
        { name: "creditCardNum", type: "text", width: "48%", },
        { name: "creditCardExpiration", type: "text", width: "48%", },
        { name: "ownerIDNumber", type: "text", width: "48%", },
        { name: "avarageLate", type: "text", width: "48%", },
        { name: "priority", width: "48%", type: "dropdown", options: priority, },
        { name: "iban", type: "text", width: "48%", },
        { name: "partialDelivery", width: "48%", type: "dropdown", options: ActiveInactive, },
        { name: "endorsableChecksFromBP", width: "48%", type: "dropdown", options: ActiveInactive, },
        { name: "acceptsEndorsedChecks", width: "48%", type: "dropdown", options: ActiveInactive, },
    ],
    "Payment Run": [
        { name: "houseBankCountry", type: "text", width: "48%", },
        { name: "houseBank", type: "text", width: "48%", },
        { name: "houseBankAccount", type: "text", width: "48%", },
        { name: "houseBankBranch", type: "text", width: "48%", },
        { name: "houseBankIBAN", type: "text", width: "48%", },
        { name: "referenceDetails", type: "text", width: "48%", },
        { name: "paymentBlock", type: "text", width: "48%", },
        { name: "singlePayment", width: "48%", type: "dropdown", options: ActiveInactive, },
        { name: "collectionAuthorization", width: "48%", type: "dropdown", options: ActiveInactive, },
        { name: "bankChargesAllocationCode", type: "text", width: "48%", },


    ],
    Accounting: [
        { name: "downPaymentInterimAccount", type: "text", width: "48%", },
        { name: "downPaymentClearAct", type: "text", width: "48%", },
        { name: "planningGroup", type: "text", width: "48%", },
        { name: "affiliate", width: "48%", type: "dropdown", options: ActiveInactive, },
    ]
};
