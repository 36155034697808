import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import styles from './styles';
import Table from '~/Components/EnhancedTable';
import { columns, data } from '../data';
import EmployeeDataForm from '../EmployeeDataForm';
import AddressDataForm from '../AddressDataForm';
import QualificationDataForm from '../QualificationDataForm';
import OfficialDataForm from '../OfficialDataForm';
import FamilyDataForm from '../FamilyDataForm';

class ViewUpdateEmployeeInfo extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';


    officialData = {
        id: "002",
        passport: "passport",
        drivingLicense: "drivingLicense",
        memberships: "memberships"
    };
    employeeData = {
        personalBrief: "Personal Brief",
        emergencyContact: "3123165489"
    };
    qualificationData = {
        qualification: "Qualification",
        workExperience: "Work Experience"
    };
    familyData = {
        spouse: "Spouse Name",
        dateBirth: `${new Date()}`
    };
    addressData = {
        "Permantent Adress": {
            permantentStreetNumber: "13",
            permantentCity: "City Permantent",
        },
        "Emergency Adress": {
            emergencyStreetNumber: "22",
            emergencyCity: " Emergency City",
        }
    };

    loading = false;


    componentDidMount() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 600);
    }
    setView = (view, title) => {
        if (title != undefined) {

            this.view = title;
        }


    }


    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <>
                        <ShowView title="Employee"
                            data={this.employeeData}
                            setView={(view) => this.setView(view, "editEmployeeData")}
                            showViewName="UpEmployeesData"
                        />
                        <ShowView title="Contact Address"
                            data={this.addressData}
                            showViewName="AddressesData"
                            Tab={true}
                            setView={(view) => this.setView(view, "editAddressData")}
                        />
                        <ShowView title="Qualification"
                            data={this.qualificationData}
                            setView={(view) => this.setView(view, "editQualificationData")}
                            showViewName="QualificationsData"
                        />
                        <ShowView title="Family Details"
                            data={this.familyData}
                            setView={(view) => this.setView(view, "editFamilyData")}
                            showViewName="FamiliesData"
                        />
                        <Table
                            showKeyIndex={0}
                            title="Childs"
                            defaultData={this.tableData}
                            columns={columns}
                            parentDeleteAction={this.onDeleteConfirm}

                        />
                        <ShowView title="Official Documents Details."
                            data={this.officialData}
                            setView={(view) => this.setView(view, "editOfficialData")}
                            showViewName="OfficialesData"
                        />
                    </>
                }
                {
                    this.view === "editEmployeeData" &&
                    <div className={classes.Edit}>
                        <EmployeeDataForm {...this.props} setView={this.setView} />
                    </div>
                }
                {
                    this.view === "editAddressData" &&
                    <div className={classes.Edit}>
                        <AddressDataForm {...this.props} setView={this.setView} />
                    </div>
                }
                {
                    this.view === "editQualificationData" &&
                    <div className={classes.Edit}>
                        <QualificationDataForm {...this.props} setView={this.setView} />
                    </div>
                }
                {
                    this.view === "editOfficialData" &&
                    <div className={classes.Edit}>
                        <OfficialDataForm {...this.props} setView={this.setView} />
                    </div>
                }
                {
                    this.view === "editFamilyData" &&
                    <div className={classes.Edit}>
                        <FamilyDataForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewUpdateEmployeeInfo, {
    view: observable,
    title: observable,
    loading: observable,

})




export default withStyles(styles)(asEntity({ storeId: "UpdateEmployeeInfoForm" })(observer(ViewUpdateEmployeeInfo)));
