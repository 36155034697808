import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Add, Delete, Edit } from '@material-ui/icons';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from "react";
import SortableTree, { addNodeUnderParent, changeNodeAtPath, getNodeAtPath, removeNode } from "react-sortable-tree";
import "react-sortable-tree/style.css";
import EditModal from "~/Components/EditModal";
import { ActionButton } from '~/Components/From';
import Loading from '~/Components/Loading';
import NotificationPopup from '~/Components/Notification/NotificationPopup';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { data } from './data';
import { DeleteMessage } from "./DeleteMessage";
import ItemForm from './ItemForm';
import styles from './styles';


class Tree extends Component {
    static contextType = NotificationContext;

    treeData = [];
    isModalOpen = false;
    ShowDeleteConfirm = false;
    loading = false;
    deleteRowInfo = {};
    type = "add"


    componentDidMount() {
        this.loading = true;
        setTimeout(() => {
            let arr = [],
                keys = Object.keys(data);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                arr.push(data[key]);

            }
            this.treeData = arr;
            this.loading = false;
        }, 600);

    }

    toggleModal = () => {
        this.isModalOpen = !this.isModalOpen;
    }
    toggleDeleteModal = () => {
        this.ShowDeleteConfirm = !this.ShowDeleteConfirm;
    }

    addNode = (rowInfo, NEW_NODE) => {
        let { node, treeIndex, path } = rowInfo;
        // path.pop();
        let parentNode = getNodeAtPath({
            treeData: this.treeData,
            path: path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            ignoreCollapsed: true
        });
        let getNodeKey = ({ node: object, treeIndex: number }) => {
            return number;
        };
        let parentKey = getNodeKey(parentNode);
        if (parentKey == -1) {
            parentKey = null;
        }
        let newTree = addNodeUnderParent({
            treeData: this.treeData,
            newNode: NEW_NODE,
            expandParent: true,
            parentKey: parentKey,
            getNodeKey: ({ treeIndex }) => treeIndex
        });
        this.updateTreeData(newTree.treeData);
        this.toggleModal();
    }

    editNode = (rowInfo, NEW_NODE) => {
        let newTree = changeNodeAtPath({
            treeData: this.treeData,
            path: rowInfo.path,
            newNode: NEW_NODE,
            getNodeKey: ({ treeIndex }) => treeIndex
        });
        this.updateTreeData(newTree);
        this.toggleModal();

    }
    removeNode = (rowInfo) => {
        let { node, treeIndex, path } = rowInfo;
        let newTree = removeNode({
            treeData: this.treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex
        });
        this.updateTreeData(newTree.treeData);
    }
    updateTreeData = (treeData) => {
        this.treeData = treeData;
    }


    IconAction = (rowInfo, type) => {
        if (type == "delete") {
            this.editValues = rowInfo
            this.toggleDeleteModal()
        } else {
            this.type = type;
            this.editValues = rowInfo
            this.toggleModal()
        }

    }

    save = () => {
        // this.loading = true;
        // let newTreeData = {}
        // for (let i = 0; i < toJS(this.treeData).length; i++) {
        //     newTreeData[`${toJS(this.treeData)[i].EnglishLabel}`] = this.pathHandel(toJS(this.treeData)[i], "/")
        // }
        // this.props.entityStore.post(newTreeData);
    }

    pathHandel = (data, path) => {
        let slug = data.Slug
        let objectPath = path + slug.replaceAll(" ", "-").toLowerCase() + "/"
        let children = []
        if (data.children && Array.isArray(data.children)) {
            for (let i = 0; i < data.children.length; i++) {
                let child = this.pathHandel(data.children[i], objectPath)
                if (child) children.push(child)
            }
            return { ...data, path: objectPath, children }
        }
        else {
            return {
                ...data, path: path + slug.replaceAll(" ", "-").toLowerCase()
            }
        }

    }

    render() {
        let { classes } = this.props;
        if (this.loading) {
            return <Loading />
        }
        return (
            <>
                <div className={classes.header}>
                    <ActionButton title="Save Change" icon="save" loading={this.loading}
                        onClick={this.save}
                    />
                </div>
                <div className={classes.menu}>
                    <SortableTree
                        treeData={this.treeData}
                        onChange={this.updateTreeData}
                        generateNodeProps={rowInfo => ({
                            buttons: [
                                <div>
                                    <IconButton edge="end" aria-label="edit" onClick={(event) => { this.IconAction(rowInfo, "edit") }}  >
                                        <Edit />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="add" onClick={(event) => { this.IconAction(rowInfo, "add") }}>
                                        <Add />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delet" onClick={(event) => this.IconAction(rowInfo, "delete")}>
                                        <Delete />
                                    </IconButton>
                                </div>,
                            ],
                            className: classes.node
                        })}
                    />
                </div>
                <EditModal
                    open={this.isModalOpen}
                    handleClose={this.toggleModal}
                    title={`${this.type} menu item`}
                >
                    <ItemForm
                        handleClose={this.toggleModal}
                        editValues={this.editValues}
                        handelSubmit={this.type === 'add' ? this.addNode : this.editNode}
                        type={this.type}
                    />
                </EditModal>
                <NotificationPopup
                    open={this.ShowDeleteConfirm}
                    vertical='bottom'
                    horizontal='center'
                    severity='warning'
                    message={
                        <DeleteMessage
                            rowInfo={this.editValues}
                            removeNode={this.removeNode}
                            handleClose={this.toggleDeleteModal}

                        />}
                />
            </>
        );
    }
}


decorate(Tree, {
    treeData: observable,
    isModalOpen: observable,
    ShowDeleteConfirm: observable,
    type: observable,
    loading: observable,
})


export default withStyles(styles)(asEntity({ storeId: 'menu' })(observer(Tree)));

