import { ButtonBase, Icon, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import useStyles from './styles';
import { getStorage } from '~/Services/Helpers';


const AsideTile = ({ icon, element, active, handleClick }) => {
    const classes = useStyles();
    const language = getStorage("locale") || "en";
    const name = language == "en" ? element.EnglishLabel : element.ArabicLabel
    return (
        <Tooltip title={name}>
            <ButtonBase
                focusRipple
                className={classNames(classes.menuHead, { [classes.active]: active })}
                onClick={handleClick}
            >
                <Icon className={classes.icon}> {icon} </Icon>
                <span className={classes.text}>
                    {name ? name : element.title}
                </span>
            </ButtonBase>

        </Tooltip>
    )
}

export default AsideTile
