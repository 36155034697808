import { findByText } from "@testing-library/dom";
import * as Yup from "yup";
import { isEmpty } from '~/Services/Helpers';

export const validationSchema = Yup.object().shape({
    tripCode: Yup.string().trim().required(),
    tripName: Yup.string().trim(),
    fromCity: Yup.string().trim(),
    toCity: Yup.string().trim(),
    distance: Yup.number(),
    price: Yup.number(),
});


export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        tripCode: "",
        tripName: "",
        fromCity: "",
        toCity: "",
        distance: "",
        price: "",
        tripTotal: "",
    },
};

export const formData = [
    { name: "tripCode", type: "text", editDisabled: true },
    { name: "tripName", type: "text" },
    { name: "fromCity", type: "text", },
    { name: "toCity", type: "text", },
    { name: "distance", type: "number" },
    { name: "price", type: "number", },
    { name: "tripTotal", type: "number",  disabled : true },
];

export const formDependency = { 
    tripTotal: [
        { fields: ['distance' , 'price'], prop: 'value', 
            action: (fieldValues) =>  !isEmpty(fieldValues.distance) && !isEmpty(fieldValues.price) ? fieldValues.distance * fieldValues.price : 0
        }
    ]
}

