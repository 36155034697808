import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Table from '~/Components/EnhancedTable';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { columns } from './data';


class Category extends React.Component {
  static contextType = NotificationContext;
  tableData = [];
  loading = true;
  componentDidMount() {
    this.loading = true;
    this.props.entityStore.get();
  }

  entityDidReceived(data) {
    if (!!data) {
      this.tableData = data;
      this.loading = false;
    }
  }

  entityDidCatch(error) {
    this.loading = false;
    this.notify("error", error)

  }
  onDeleteConfirm = (idsArray) => {
    idsArray.map(id => {
      this.props.entityStore.delete({ id });
    });
  }
  entityDidDelete() {
    this.notify("Success", "Row/s was deleted successfully");
  }
  notify = (severity, message) => {
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity,
      message
    }))
  }

  render() {

    if (this.loading) {
      return <Loading />
    }


    return (
      <Table
        addLink
        showKeyIndex={0}
        addButtonText="add Category"
        title="Categories"
        defaultData={this.tableData}
        columns={columns}
        parentDeleteAction={this.onDeleteConfirm}
        addToUrl="/Category"
      />
    )
  }
}

decorate(Category, {
  tableData: observable.shallow,
  loading: observable,
})

export default asEntity({ storeId: 'BrowseCategory' })(observer(Category));