import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AuthRoute, PrivateRoute } from '../Components/CustomRoutes';
import Layout from "../Components/Layout";
import Loading from '../Components/Loading';
import { USER_INITIAL_STATE, useUserContext } from '../Services/Providers';
import { StorageKeys } from '../Configs/Storage';
import AccountActions from '../Redux/Actions/Account';
import { authRoutes, privateRoutes, publicRoutes, privateRoutesGet } from "./RouteList";
import { getStorage, setStorage } from '~/Services/Helpers';
import { useDispatch } from 'react-redux';
import api from '../Services/Api';
import { SetRoutData } from "./RouteNavigator";
import { useHistory } from 'react-router-dom';
export default function Routes() {
  const history = useHistory()
  const { setUserContext } = useUserContext();
  const [IsLoading, setIsLoading] = useState(false)
  const pathname = window.location.pathname
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading(true)
    let token = getStorage(StorageKeys.token);
    let user = JSON.parse(window.localStorage.getItem(StorageKeys.userContext));
    let company = window.localStorage.getItem(StorageKeys.Company);
    async function fetchUser() {
      if (!token || !company || !user) {
        dispatch(AccountActions.logout());
        setUserContext({ ...USER_INITIAL_STATE });
        history.push("/login")
      } else {
        const userData = await api.Users.getUserData({ email: user.data.email }, { headers: { Authorization: "Bearer " + token, } });
        const data = !!userData.data && userData.ok ? userData.data : user.data
        setUserContext({ isAuthenticated: true, token, data });
      }
      setIsLoading(false);
    }
    fetchUser()
  }, [])
  useEffect(() => {
    return history.listen((location) => {
      let lastVisited = getStorage(StorageKeys.lastVisited) ? JSON.parse(getStorage(StorageKeys.lastVisited)) : [];
      const found = lastVisited.find(element => element === location.pathname);
      if (!found && location.pathname !== "/login" && location.pathname.trim !== "/" && location.pathname.trim !== "" && location.pathname !== "/home-page") {
        lastVisited = [location.pathname, ...lastVisited]
        if (lastVisited.length > 5) lastVisited.pop()
        setStorage(StorageKeys.lastVisited, JSON.stringify(lastVisited))
      }
    })
  }, [history])

  const privetRoutesRenderer = () => {

    if (privateRoutesGet()) {
      return privateRoutesGet().map((RouteObj, index) => {
        return (
          <PrivateRoute key={index} {...RouteObj} />
        )
      })
    }
    else {
      let token = getStorage(StorageKeys.token);
      let user = JSON.parse(window.localStorage.getItem(StorageKeys.userContext));
      let company = window.localStorage.getItem(StorageKeys.Company);
      if (token && company && user) {
        setIsLoading(true)
        setTimeout(() => {
          // history.push(history.location.pathname)
          setIsLoading(false)
        }, 100);
      } else return <></>
      // 
    }
  }
  if (IsLoading) { return <Loading /> }
  else {
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Redirect from="/" to="/login" exact />
          {
            authRoutes.map((RouteObj, index) => (
              <AuthRoute key={index} {...RouteObj} />
            ))
          }
          {
            publicRoutes.map((RouteObj, index) => (
              <Route key={index} {...RouteObj} />
            ))
          }
          <Layout>
            <Switch>
              {privetRoutesRenderer()}
            </Switch>
          </Layout>

        </Switch>
      </Suspense>
    );
  }
}