import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import EquipmentTypeForm from '../Partials/EquipmentTypeForm';
import styles from './styles';


class ViewEquipmentType extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {
        "Number": "1",
        "Equipment Code": "EQ-001",
        "Kilo Metraj": "1231314",
        "Maintenancetype": "صيانة عادية ",
        Date: "11/1/2020",
        spareparts: "SP-0002",
        Qty: "2",
        WorkingHours: "2",
        ProjectCode: "10",
    };
    loading = false;
    topics = [];


    setView = (view) => {
        this.view = view;
    }

    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="Maintenance Transaction Info."
                        data={this.data}
                        setView={this.setView}
                    />
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <EquipmentTypeForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewEquipmentType, {
    view: observable,
})




export default withStyles(styles)(asEntity({ storeId: "TopicForm" })(observer(ViewEquipmentType)));
