import React from 'react'
import LoansAdvancesReimbursementsForm from '../Partials/LoansAdvancesReimbursementsForm'
import useStyles from './styles'

function AddLoansAdvancesReimbursements(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <LoansAdvancesReimbursementsForm {...props} />
        </div>
    )
}

export default AddLoansAdvancesReimbursements;
