import React from 'react'
import { Button, Icon, CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'


function ActionButton({ title, loading, icon ,customClass , onClick  , variant }) {
    return (
        <Button
            variant={variant}
            color="secondary"
            className={customClass}
            endIcon={loading ? <CircularProgress color="primary" size={15} /> : <Icon > {icon} </Icon>}
            type="submit"
            disabled={loading}
            onClick={onClick}
        >
            <FormattedMessage id={title} /> 
        </Button>
    )
}

ActionButton.defaultProps = {
    variant  : "outlined",
}

export default ActionButton
