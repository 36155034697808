import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    requestNo: Yup.number().required(),
    entityName: Yup.string().trim().required(),
    lettersType: Yup.string().trim().required(),
    name: Yup.string().trim(),
    status: Yup.string().trim(),
    basicSalary: Yup.string().trim(),
    allowances: Yup.string().trim(),
    position: Yup.string().trim(),
    numOfYears: Yup.string().trim(),
    purpose: Yup.string().trim(),
    remarks: Yup.string().trim(),
});


export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        requestNo: "",
        entityName: "",
        lettersType: "",
        name: "",
        status: "",
        basicSalary: "",
        allowances: "",
        position: "",
        numOfYears: "",
        purpose: "",
        remarks: "",
    },
};

const lettersType = [
    { id: 0, name: "To Whom It May Concern", value: "To Whom It May Concern" },
    { id: 1, name: "Experience Letter", value: "Experience Letter" }
];
let ValueCheck = (value) => {
    if (value == 1) {
        return true;
    } else {
        return false;
    }
}

export const formData = [
    { name: "lettersType", type: "dropdown", options: lettersType },
    { name: "requestNo", type: "number", editDisabled: true },
    { name: "entityName", type: "text" },
    { name: "name", type: "text" },
    { name: "basicSalary", type: "text", },
    { name: "allowances", type: "text", },
    { name: "position", type: "text", action: "toggle", actionONFields: [{ name: 'lettersType', schema: ValueCheck }] },
    { name: "numOfYears", type: "text", action: "toggle", actionONFields: [{ name: 'lettersType', schema: ValueCheck }] },
    { name: "purpose", type: "text", action: "toggle", actionONFields: [{ name: 'lettersType', schema: ValueCheck }] },
];


