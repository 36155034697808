import { Breadcrumbs, Typography, Button } from '@material-ui/core'
import { ArrowBack,ArrowForward  } from '@material-ui/icons'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { UpperCaseFirst } from '~/Services/Helpers'
import useStyles from './styles'
import { useAppContext } from '~/Services/Providers';

const SubHeader = () => {
    const classes = useStyles();
    const history = useHistory();
    const locationArr = history.location.pathname.split('/');
    const { appContextObj } = useAppContext();

    const forward = (isLast,newPath) =>{

        if (!isLast){
            history.push(newPath);
        }
    }

    return (
        <div style={{ display : 'flex'}}>
           {
           locationArr.length > 3 ? <Button
                startIcon={appContextObj.direction === "ltr" ? <ArrowBack/> : <ArrowForward/>}
                onClick={() => history.goBack()}
                style={{marginRight : 3}}
            /> : " "
            }
            
            <Breadcrumbs maxItems={4} aria-label="breadcrumb">
                <Typography variant="h5">
                  {UpperCaseFirst(locationArr[1])}  
                </Typography>
                
                {
                    locationArr.length > 2 && locationArr.map((path , index) => {

                        if (index > 1){
                            const isLast = path === locationArr[locationArr.length - 1];
                            let currPath  = history.location.pathname;
                            let newPath  = currPath.substring(0, currPath.lastIndexOf(path))+path;
                  
                           return (
                            // <p style={{ textDecoration: !isLast ? 'underline' : 'none', cursor: !isLast ? 'pointer' : 'auto' }} color="inherit" onClick={() => forward(isLast,newPath)} key={index} className={classes.breadCrumb}>
                            //     {UpperCaseFirst(path)}
                            // </p>
                            <p color="inherit"  key={index} className={classes.breadCrumb}>
                                {UpperCaseFirst(path)}
                            </p>
                            )
                        }
                    })
                }
                
         
            
            </Breadcrumbs>
        </div>
    )
}

export default SubHeader
