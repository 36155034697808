export const columns = [
    { name: "claimCode", options: { filter: false, sort: true, display: true } },
    { name: "claimName", options: { filter: true, sort: true, } },
    { name: "budgetAmount", options: { filter: true, sort: true, } },
    { name: "details", options: { filter: false, sort: false, } },
];

export const detailsColumns = [
    { name: "id", options: { filter: false, sort: true, display: true } },
    { name: "date", options: { filter: false, sort: true, display: true } },
    { name: "bustTrip", options: { filter: false, sort: true, display: true } },
    { name: "amount", options: { filter: false, sort: true, display: true } },
    { name: "remark", options: { filter: false, sort: true, display: true } },
]


export const ClaimTransactionsData = [
    { claimCode: 'c-231', claimName: 'Vacation', budgetAmount: '$344', },
    { claimCode: 'c-d23', claimName: 'Business Trip', budgetAmount: '$500', },
    { claimCode: 'c-d24', claimName: 'Taxi', budgetAmount: '$10', },
    { claimCode: 'c-d25', claimName: 'Office tools', budgetAmount: '$34', },
]
export const ClaimTransactionsDetailsData = [
    { id: '121', date: 'Feb 8th 2020', bustTrip: 'Trip 1', amount : "$10" , remark : "No remark" },
    { id: '122', date: 'Nov 8th 2020', bustTrip: 'Trip 2', amount : "$30" , remark : "No remark" },
    { id: '124', date: 'Dec 8th 2020', bustTrip: 'Trip 4', amount : "$30" , remark : "No remark" },
    { id: '125', date: 'Dec 20th 2020', bustTrip: 'Trip 34', amount : "$50" , remark : "No remark" },
]



