
export const data =
{
    "Maneger": {
        "title": "Manager",
        "icon": "permIdentity",
        "key": "Manager",
        "expanded": true,
        "children": [
            {
                "title": "emp 1",
                "EnglishLabel": "emp 1",
                "ArabicLabel": "الموظف",
                "Slug": "emp",
                "icon": "assignment_ind",
                "key": "emp 1",
                "path": "/manager/emp"
            },
        ],
        "path": "/manager/",
        "EnglishLabel": "Manager",
        "Slug": "manager"
    }
}
