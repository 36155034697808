import React from 'react'
import ShipmentServiceMasterForm from '../Partials/ShipmentServiceMasterForm'
import useStyles from './styles'

function AddShipmentServiceMaster(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <ShipmentServiceMasterForm {...props}  />
        </div>
    )
}

export default AddShipmentServiceMaster;
