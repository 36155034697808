import React, { useState, useEffect } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './styles';


function SwitchInput({ name, placeholder, editValue, width , InputChange, getValue, error, errorText, blur }) {
  const [Checked, setChecked] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  React.useEffect(() => {
    let value = !!editValue ? editValue : Checked;
    setChecked(value);
    sendValueFormik(value);
    sendValue(value);
  }, []);

  const handleChange = () => {
    sendValueFormik(!Checked);
    sendValue(!Checked);
    setChecked(!Checked);
  };

  const sendValueFormik = value => {
    let event = { target: { name, value } };
    InputChange && InputChange(event);
  };

  const sendValue = value => {
    getValue && getValue(name, value);
  };
  return (
    <div className={classes.root} style={{ flexBasis: !!width ? width : "100%", minWidth: 200 }}>
      <FormControlLabel
        control={
          <Switch
            checked={Checked}
            onChange={handleChange}
            onBlur={blur}
            name={name}
            color='secondary'
            inputProps={{ 'aria-label': `${name} checkbox` }}
          />
        }
        style={{ color: error ? theme.palette.error.main : theme.palette.common.black }}
        label={placeholder}
      />
      <small style={{ color: theme.palette.error.main }}> {!!errorText && errorText} </small>
    </div>
  );
}

export default SwitchInput;
