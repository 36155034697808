import { withStyles } from '@material-ui/core';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import APMaintenanceTransactionForm from '../Partials/APMaintenanceTransactionForm';
import styles from './styles';
import Table from '~/Components/EnhancedTable';
import { DetailsColumns } from "../Browse/data"



class ViewAPMaintenanceTransaction extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {};
    itemDetails = []
    loading = false;

    componentDidMount() {
        if (!!this.id) {
            this.loading = true;
            this.props.entityStore.get(this.id)
        }
    }
    setView = (view) => {
        this.view = view;
    }
    entityDidReceived(data) {
        if (!!data) {
            this.loading = false;
            let { docNum, eqCode, cardName, kiloMeters, maintenanceType, date } = data;
            maintenanceType = data?.maintenanceType?.mainName;
            eqCode = data?.eqCodeNavigation?.equiName;
            this.data = { docNum, eqCode, cardName, kiloMeters, maintenanceType, date }
            //this.date.maintenanceType = 
            //this.date.eqCode = data?.eqCodeNavigation?.equiName;
            this.DetailsData = data.maintenanceTrancstionEMT1s;
        }
    }

    render() {
        const { classes } = this.props;
        const options = {
            enableNestedDataAccess: '.',
            selectableRows: "none",
            onRowClick: (rowData, rowMeta) => { }
        };
        if (this.loading) return <Loading />
        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="AP Maintenance Transaction."
                        showViewName="APMaintenanceTransaction"
                        data={this.data}
                        setView={false}
                    />
                }
                {this.view === "show" &&
                    < Table
                        title="APMaintenanceTransactionDetails"
                        showKeyIndex={0}
                        defaultData={this.DetailsData}
                        columns={DetailsColumns}
                        parentDeleteAction={this.onDeleteConfirm}
                        customOptions={options}
                    />
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <APMaintenanceTransactionForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewAPMaintenanceTransaction, {
    view: observable,
    data: observable,
    itemDetails: observable,
    DetailsData: observable
})




export default withStyles(styles)(asEntity({ storeId: "APMaintenanceTransactionForm" })(observer(ViewAPMaintenanceTransaction)));
