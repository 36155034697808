
import React from 'react'
import { Chip } from '@material-ui/core'
const statusVals = {
    0: "Opened",
    1: "Closed",
    2: "Canceled",
}

export const columns = [
    { name: "docNum", label: "Number", options: { filter: false, sort: true } },
    { name: "eqCodeNavigation", label: "Equipment", options: { filter: true, sort: true, 
        customBodyRender: (value, tableMeta, updateValue) => value?.equiName } },
    { name: "cardName", label: "Vendor", options: { filter: true, sort: true, } },
    { name: "kiloMeters", label: "Kilo Meters", options: { filter: true, sort: true, } },
    { name: "maintenanceType", label: "Maintenance Type", options: { filter: true, sort: true, 
         customBodyRender: (value, tableMeta, updateValue) => value?.mainName } },
    { name: "date", label: "Date", options: { filter: true, sort: true, } },
    { name: "invoiceStatus", label: "Invoice Status", options: { filter: true, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => <Chip label={statusVals[value]} /> } },
];
export const DetailsColumns = [
    { name: "itemName", options: { filter: true, sort: true, } },
    { name: "qty", options: { filter: true, sort: true, } },
    { name: "price", options: { filter: true, sort: true, } },
    { name: "discountPercent", options: { filter: true, sort: true, } },
    { name: "lineTotal", options: { filter: false, sort: true } },
    { name: "projectName", options: { filter: true, sort: true, } },
    { name: "warehouseName", options: { filter: true, sort: true, } },
    { name: "remark", options: { filter: true, sort: true, } },
];







