

export const columns = [
    { name: "shipCode", label: "Shipment Code", options: { filter: false, sort: true, display: true } },
    { name: "shipName", label: "Shipment Name", options: { filter: true, sort: true, } },
    { name: "frgnName", label: "Foreign Name", options: { filter: true, sort: true, } },
];







