import React from 'react'
import { FormattedMessage } from 'react-intl'
export const LoginPageData = {
    welcomeMsg: {
        text: <FormattedMessage id="Auth.Login.Message" />,
        subText: <FormattedMessage id="Auth.Login.CompanySubMessage" />
    },
    title: <FormattedMessage id="Auth.Login.ChoseCompany" />,
    link: {
        text: <FormattedMessage id="Auth.Login.HeaderLink" />,
        url: "/register",
    }

}