
const Styles = theme => ({
    root: {
        minWidth: 200,
        textAlign: 'left'
    },
    TableControl: {
        width: "100%",
        margin: `${theme.spacing(2)}px`
    },
    DataFetchError: {
        padding: theme.spacing(2),
        border: 'solid 1px red',
        borderRadius: 5
    },
    buttons_wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '97%',
        height: '85%',
    },
});

export default Styles;