

export const columns = [

    { name: "requestNo", label: "Request No", options: { filter: false, sort: true, display: true } },
    { name: "lettersType", label: "Letters Type", options: { filter: false, sort: true, display: true } },
    { name: "entityName", label: "Attention to (entity name)", options: { filter: false, sort: true, display: true } },
    { name: "status", label: "Status", options: { filter: true, sort: true, } },
    { name: "name", label: "Name", options: { filter: true, sort: true, } },
    { name: "basicSalary", label: "Basic Salary", options: { filter: true, sort: true, } },
    { name: "allowances", label: "Allowances", options: { filter: true, sort: true, } },
    { name: "position", label: "Position", options: { filter: true, sort: true, } },
    { name: "numOfYears", label: "Number of Years (Experience)", options: { filter: true, sort: true, } },
    { name: "purpose", label: "Purpose of the letter", options: { filter: true, sort: true, } },
    { name: "remarks", label: "Remarks", options: { filter: true, sort: true, } },

];




export const data = [
    { lettersType: "To Whom It May Concern", requestNo: "1", entityName: "entityName1", status: "status", name: "name", basicSalary: "basicSalary", allowances: "allowances", position: "position", numOfYears: "NumOfYears", purpose: "purpose", remarks: "remarks" },
    { lettersType: "Experience Letter", requestNo: "1", entityName: "entityName1", status: "status", name: "name", basicSalary: "basicSalary", allowances: "allowances", position: "position", numOfYears: "NumOfYears", purpose: "purpose", remarks: "remarks" },
    { lettersType: "To Whom It May Concern", requestNo: "1", entityName: "entityName1", status: "status", name: "name", basicSalary: "basicSalary", allowances: "allowances", position: "position", numOfYears: "NumOfYears", purpose: "purpose", remarks: "remarks" },
    { lettersType: "Experience Letter", requestNo: "1", entityName: "entityName1", status: "status", name: "name", basicSalary: "basicSalary", allowances: "allowances", position: "position", numOfYears: "NumOfYears", purpose: "purpose", remarks: "remarks" },
    { lettersType: "To Whom It May Concern", requestNo: "1", entityName: "entityName1", status: "status", name: "name", basicSalary: "basicSalary", allowances: "allowances", position: "position", numOfYears: "NumOfYears", purpose: "purpose", remarks: "remarks" },


]



