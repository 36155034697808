import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    code: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        code: '',
    },
};
const apExpenseCode = (data) => {
    const Options = []
    data=data.value
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}`,
            id: element.expensCode
        })
    });
    return Options;
}



export const formData = [
    { name: "code", type: "dropdown", company: true, optionsURI: "Financials/AdditionalExpenses/Short", parseData: apExpenseCode },
];