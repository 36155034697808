

export const columns = [
    { name: "foodicsID",  options: { filter: false, sort: true, display: true } },
    { name: "foodicsName",  options: { filter: false, sort: true, display: true } },
    { name: "accountCode",  options: { filter: false, sort: true, display: true } },
    { name: "accountName",  options: { filter: false, sort: true, display: true } },
    { name: "type",  options: { filter: false, sort: true, display: true } },
];







