import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Table from '~/Components/EnhancedTable';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { columns, data } from './data';



class BrowseRequestBusinessTrip extends React.Component {
  static contextType = NotificationContext;
  tableData = data;
  loading = true;

  componentDidMount() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 600);
  }



  onDeleteConfirm = (idsArray) => {
    this.notify("Success", "Row/s was deleted successfully");
  }


  notify = (severity, message) => {
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity,
      message
    }))
  }


  render() {

    if (this.loading) {
      return <Loading />
    }


    return (
      <Table
        addLink
        showKeyIndex={0}
        addButtonText="RequestBusinessTrip"
        title="RequestBusinessTrips"
        defaultData={this.tableData}
        columns={columns}
        parentDeleteAction={this.onDeleteConfirm}

      />
    )
  }
}

decorate(BrowseRequestBusinessTrip, {
  tableData: observable.shallow,
  loading: observable,
})

export default asEntity({ storeId: 'BrowseRequestBusinessTrip' })(observer(BrowseRequestBusinessTrip));
