import React, { useState } from 'react'
import { Icon, IconButton, ListItem, ListItemText, Divider, CircularProgress, Tooltip } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import 'date-fns';
const ShowLabelWithButton = ({ date, label, icon, loading, handleClick, tooltipTitle }) => {
    
    const [clickDate, setDate] = useState(new Date().toISOString().split("T")[0]);
    const click = () => {
        if (!!date) handleClick(clickDate)
        else handleClick()
    }
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return (
        <>
            <ListItem button>
                <ListItemText primary={
                    <React.Fragment>
                        <span style={{
                            minWidth: "150px",
                            display: "inline-block"
                        }}
                        > <strong>{label}</strong></span>
                        <Tooltip title={tooltipTitle}>
                            <IconButton aria-label="Test Con" onClick={click}>
                                {loading ?
                                    <CircularProgress color="light" size={24} /> : <Icon> {icon} </Icon>
                                }
                            </IconButton>
                        </Tooltip>
                        {!!date && (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant='inline'
                                    format='dd/MM/yyyy'
                                    id='date-picker-inline'
                                    label='Date'
                                    value={clickDate}
                                    onChange={(d) => { const time = new Date(d).toLocaleDateString({}, options); setDate(time) }}
                                    KeyboardButtonProps={{ 'aria-label': 'change date', }}
                                    autoOk
                                />
                            </MuiPickersUtilsProvider>)}
                    </React.Fragment>
                } />
            </ListItem>
            <Divider />
        </>
    )
}

ShowLabelWithButton.defaultProps = {
    date: null
}

export default ShowLabelWithButton;