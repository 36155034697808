import { makeStyles } from '@material-ui/core/styles';

export const drawerWidth = 230;
export const closedAside = 110;

const useStyles = makeStyles((theme) => {
    return {
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '4px 8px',
            ...theme.mixins.toolbar,
            // direction:theme.direction
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        drawerPaper: {
            //width: drawerWidth,
            backgroundColor: theme.palette.background.default,
            overflow: 'hidden',
        },
        SubMenu : {
            overflowY : 'scroll'  
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: 110,
            [theme.breakpoints.up('sm')]: {
                width: 115,
            },
        },
    }
});

export const Styles = {
    drawerPaperWidth: (level = 0, open = false) => ({
        width: open ? `${drawerWidth + (level * 2)}px` : "0px",
    }),
}
export default useStyles;