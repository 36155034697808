

import { List, ListSubheader } from '@material-ui/core';
import React, { useState } from 'react';
import PasswordForm from './Partials/PasswordForm';
import ProfileForm from "./Partials/ProfileForm";
import { profileFormProps, passwordFormProps } from './data'
import { useUserContext } from '../../Services/Providers'

import useStyles from './styles';

function Profile() {
  const classes = useStyles()
  const { userContextObj, setUserContext } = useUserContext();
  const [OpenTab, setOpenTab] = useState(null)


  const handleTabClick = (Tab) => {
    Tab === OpenTab
      ? setOpenTab(null)
      : setOpenTab(Tab);
  }
  const handelSubmit = (info) => {
    const data = { ...userContextObj.data, ...info }
    setUserContext({ data: data })
  }

  return (
    <List component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Personal Info.
        </ListSubheader>
      }
      className={classes.root}
    >

      <ProfileForm OpenTab={OpenTab}
        handleTabClick={handleTabClick}
        userContextObj={userContextObj}
        handelSubmit={handelSubmit}
        {...profileFormProps}
      />

      <PasswordForm OpenTab={OpenTab}
        handleTabClick={handleTabClick}
        {...passwordFormProps}
      />

    </List>
  )
}

export default Profile
