import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    url: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        url: '',
    },
};


export let formData = [
    { name: "url", type: "text", width: "100%", placeholder: "Url" },
];