

export const columns = [

    { name: "taskNumber", label: "Task Number", options: { filter: false, sort: true, display: true } },
    { name: "taskDescription", label: "Task Description", options: { filter: true, sort: true, } },
    { name: "start", label: "Start Date", options: { filter: true, sort: true, } },
    { name: "end", label: " End Date", options: { filter: true, sort: true, } },
    { name: "taskCategory", label: "Task Category", options: { filter: true, sort: true, } },
    { name: "assignedPerson", label: "Assigned person (Employee)", options: { filter: true, sort: true, } },
    { name: "reminder", label: "Reminder", options: { filter: true, sort: true, } },
    { name: "typeNotfication", label: "type notfication", options: { filter: true, sort: true, } },
    { name: "when", label: "type notfication", options: { filter: true, sort: true, } },
    { name: "projectCode", label: "Project Code", options: { filter: true, sort: true, } },
    { name: "projectStage", label: "Project Stage", options: { filter: true, sort: true, } },

];




export const data = [
    { taskNumber: "taskNumber1", taskDescription: "taskDescription", start: "start", end: "end", taskCategory: "taskCategory", assignedPerson: "assignedPerson", Reminder: "Reminder", typeNotfication: "1", when: "befor 15 min", projectCode: "projectCode", projectStage: "projectStage" },
    { taskNumber: "taskNumber2", taskDescription: "taskDescription", start: "start", end: "end", taskCategory: "taskCategory", assignedPerson: "assignedPerson", Reminder: "Reminder", typeNotfication: "1", when: "befor 15 min", projectCode: "projectCode", projectStage: "projectStage" },
    { taskNumber: "taskNumber3", taskDescription: "taskDescription", start: "start", end: "end", taskCategory: "taskCategory", assignedPerson: "assignedPerson", Reminder: "Reminder", typeNotfication: "1", when: "befor 15 min", projectCode: "projectCode", projectStage: "projectStage" },
    { taskNumber: "taskNumber4", taskDescription: "taskDescription", start: "start", end: "end", taskCategory: "taskCategory", assignedPerson: "assignedPerson", Reminder: "Reminder", typeNotfication: "1", when: "befor 15 min", projectCode: "projectCode", projectStage: "projectStage" },
    { taskNumber: "taskNumber5", taskDescription: "taskDescription", start: "start", end: "end", taskCategory: "taskCategory", assignedPerson: "assignedPerson", Reminder: "Reminder", typeNotfication: "1", when: "befor 15 min", projectCode: "projectCode", projectStage: "projectStage" },
    { taskNumber: "taskNumber6", taskDescription: "taskDescription", start: "start", end: "end", taskCategory: "taskCategory", assignedPerson: "assignedPerson", Reminder: "Reminder", typeNotfication: "1", when: "befor 15 min", projectCode: "projectCode", projectStage: "projectStage" },
    { taskNumber: "taskNumber7", taskDescription: "taskDescription", start: "start", end: "end", taskCategory: "taskCategory", assignedPerson: "assignedPerson", Reminder: "Reminder", typeNotfication: "1", when: "befor 15 min", projectCode: "projectCode", projectStage: "projectStage" },


]



