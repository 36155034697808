import { Avatar, ButtonBase, Menu, MenuItem } from '@material-ui/core';
import { ExitToApp, Home } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AccountActions from '~/Redux/Actions/Account';
import EntityActions from '~/Redux/Actions/Entity';
import { USER_INITIAL_STATE, useUserContext } from '~/Services/Providers';
import { useNotification } from '~/Services/Providers'


function MenuProfile() {
    const [anchorEl, setanchorEl] = useState(null)
    const history = useHistory();
    const { userContextObj, setUserContext } = useUserContext();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { setNotification } = useNotification();

    const handleOpen = (event) => {
        setanchorEl(event.currentTarget);
    }



    const handleClose = (event) => {
        setanchorEl(null)
    }


    const handleLogout = () => {
        dispatch(AccountActions.logout());
        dispatch(EntityActions.post('MenuAndPermissions'));
        setUserContext({ ...USER_INITIAL_STATE });
    }

    return (
        <div>
            <ButtonBase className={classes.avatarHead} onClick={handleOpen}>
                <Avatar
                    alt={userContextObj.data.name}
                    src={userContextObj.data.name}
                    className={classNames(classes.avatar, classes.bigAvatar)}
                />
            </ButtonBase>
            <Menu
                id="status-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.statusMenu}
            >
                <MenuItem className={classes.profile}>
                    <Avatar
                        alt={userContextObj.data.name}
                        src={userContextObj.data.name}
                        className={classNames(classes.avatar, classes.bigAvatar)}
                    />
                    <div className={classes.name}>
                        <h5> {userContextObj.data.name} </h5>
                    </div>
                </MenuItem>

                <MenuItem onClick={() => history.push('/home-page')} >
                    <Home color="primary" /> &nbsp; Home
                </MenuItem>

                <MenuItem onClick={handleLogout} >
                    <ExitToApp color="error" /> &nbsp; Logout
                </MenuItem>

                {/* <MenuItem onClick={() => setNotification((currState) => ({ ...currState , severity : "error"  , open: true , message : 'this is a custom notification'})) } >
                    <ExitToApp color="error" /> &nbsp; Notify
                </MenuItem> */}

            </Menu>
        </div >
    );
}

MenuProfile.defaultProps = {
    anchorEl: null,
    isLogin: false,
};

export default MenuProfile;
