export const columns = [
    { name: "Number", label: "Number", options: { filter: false, sort: true } },
    { name: "EquipmentCode", label: "Equipment Code", options: { filter: true, sort: true, } },
    { name: "kiloMetraj", label: "kilo metraj", options: { filter: true, sort: true, } },
    { name: "MaintenanceType", label: "Maintenance Type", options: { filter: true, sort: true, } },
    { name: "Date", label: "Date", options: { filter: true, sort: true, } },
    { name: "spareParts", label: "Spare Parts", options: { filter: false, sort: true } },
    { name: "Qty", label: "Qty", options: { filter: true, sort: true, } },
    { name: "WorkingHours", label: "Working Hours", options: { filter: true, sort: true, } },
    { name: "ProjectCode", label: "Project Code", options: { filter: true, sort: true, } },

];
export const DetailsColumns = [
    { name: "spareParts", label: "Spare Parts", options: { filter: false, sort: true } },
    { name: "Qty", label: "Qty", options: { filter: true, sort: true, } },
    { name: "WorkingHours", label: "Working Hours", options: { filter: true, sort: true, } },
    { name: "ProjectCode", label: "Project Code", options: { filter: true, sort: true, } },
    { name: "WhoseCode", label: "Whose Code", options: { filter: true, sort: true, } },
];
export const data = [
    {
        Number: "1", kiloMetraj: "1231314", EquipmentCode: "EQ-001", MaintenanceType: "صيانة عادية", Date: "11/1/2020", spareParts: "SP-0002", Qty: "2", WorkingHours: "10", ProjectCode: "P0001", itemDetails: [
            { spareParts: "SP-0002", Qty: "2", WorkingHours: "10", ProjectCode: "P0001", WhoseCode: "" },
            { spareParts: "SP-0005", Qty: "5", WorkingHours: "4", ProjectCode: "P0001", WhoseCode: "" },
            { spareParts: "SP-0010", Qty: "6", WorkingHours: "12", ProjectCode: "P0001", WhoseCode: "" }
        ]
    },

    {
        Number: "2", kiloMetraj: "5544511", EquipmentCode: "EQ-002", MaintenanceType: "صيانة عادية", Date: "11/15/2020", spareParts: "SP-0005", Qty: "5", WorkingHours: "5", ProjectCode: "P0001", itemDetails: []
    },

    {
        Number: "3", kiloMetraj: "2156435153", EquipmentCode: "EQ-001", MaintenanceType: "صيانة عادية", Date: "11/20/2020", spareParts: "SP-0010", Qty: "6", WorkingHours: "3", ProjectCode: "P0001", itemDetails: []
    }
]







