import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    id: Yup.number().required(),
    passport: Yup.string().trim().required(),
    drivingLicense: Yup.string().trim().required(),
    passport: Yup.string().trim().required(),
    memberships: Yup.string().trim().required(),
});


export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        id: "",
        passport: "",
        drivingLicense: "",
        memberships: "",
    },
};


export const formData = [
    { name: "id", type: "textarea" },
    { name: "passport", type: "text" },
    { name: "drivingLicense", type: "text" },
    { name: "memberships", type: "text" },

];


