const Seconds = (n) => n * 1000;

const multipleFileUpload = 'Administration/Attachment';
const baseURL =  window.globalConfig.url;
const apiUrl =  window.globalConfig.api;
/*
const baseURL = !!process.env.PUBLIC_URL
  ? process.env.PUBLIC_URL
  : "https://portal.ioi-tech.com/";

const apiUrl = !!process.env.REACT_APP_BACKEND_BASE_URL
  ? process.env.REACT_APP_BACKEND_BASE_URL
  : `https://api.portal.ioi-tech.com/api/`;
*/

const getCompanyURL = (company) => {
  let urlParts = apiUrl.split('//');
  return `${urlParts[0]}//${company}.${urlParts[1]}`
}




export default {
  baseURL,
  apiUrl,
  timeout: Seconds(100),
  clientToken: "SECRET_TOKEN",
  version: "1.0.0",
  multipleFileUpload,
  getCompanyURL
  // ...config,
};
