import React, { useEffect, useState } from "react";
import { USER_INITIAL_STATE, useUserContext } from '../Services/Providers';
import { StorageKeys } from '../Configs/Storage';
import AccountActions from '../Redux/Actions/Account';
import api from '../Services/Api';
import Loading from '../Components/Loading';
import { SetRoutData, DefaultNav } from "../Routes/RouteNavigator";
import { getStorage, setStorage } from '~/Services/Helpers';
import { privateRoutesSet } from "../Routes/RouteList"

export default function Initialization({ children, ...props }) {
    const [IsLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsLoading(true)
        let token = getStorage(StorageKeys.token);
        let user = JSON.parse(window.localStorage.getItem(StorageKeys.userContext));
        let company = window.localStorage.getItem(StorageKeys.Company);
        async function fetchPermissions() {
            console.log('I am getting permission');
            const menuRes = await api.Menu.get({}, { headers: { Authorization: "Bearer " + token, } });
            const PermissionsRes = await api.Users.getUserPermissions({ data: "" }, { headers: { Authorization: "Bearer " + token, } });
            let comLists = !!PermissionsRes.data?.comLists ? PermissionsRes.data?.comLists : {};
            let Permissions = []
            for (let i = 0; i < comLists.length; i++) {
                if (comLists[i].companyID == company.split(".")[1])
                    Permissions = [...comLists[i].permissions, ...Permissions]
            }
            SetRoutData(Permissions, { ...DefaultNav, ...menuRes.data })
            setStorage(StorageKeys.permissions, JSON.stringify(Permissions));
            privateRoutesSet();
            setIsLoading(false);
        }
        fetchPermissions()
    }, [])

    if (IsLoading) { return <Loading /> }
    else {
        return (
            <>
                {children}
            </>
        )
    }
}

