import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    permantentStreetNumber: Yup.number().required(),
    permantentCity: Yup.string().trim().required(),
    emergencyStreetNumber: Yup.number().required(),
    emergencyCity: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        permantentStreetNumber: "",
        permantentCity: "",
        emergencyStreetNumber: "",
        emergencyCity: "",
    },
};


export const formData = [
    { name: "permantentStreetNumber", type: "number" },
    { name: "permantentCity", type: "text" },
    { name: "emergencyStreetNumber", type: "number" },
    { name: "emergencyCity", type: "text" },

];

