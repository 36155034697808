import React from 'react'
import VacationRequestForm from '../Partials/VacationRequestForm'
import useStyles from './styles'

function AddVacationRequest(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <VacationRequestForm {...props} />
        </div>
    )
}

export default AddVacationRequest;
