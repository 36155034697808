import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    SAPSeriesCode: Yup.string().trim().required(),
    SAPItemGroup: Yup.string().trim().required(),

});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        SAPSeriesCode: '',
        SAPItemGroup: '',
    },
};


const sapSeriesCode = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}-${element.seriesType}`,
            id: element.series
        })
    });
    return Options;
}

const ItemGroups = (data) => {
    const Options = []
    data=data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.groupName}`,
            id: element.number
        })
    });
    return Options;
}

export const formData = [
    { name: "SAPSeriesCode", type: "dropdown", width: "48%", company: true, optionsURI: "Administration/Series/1", parseData: sapSeriesCode },
    { name: "SAPItemGroup", type: "dropdown", width: "48%", company: true, optionsURI: "Inventory/ItemGroups/Short", parseData: ItemGroups },
];