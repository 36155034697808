

export const columns = [
    { name: "requestName", label: "Request Name", options: { filter: false, sort: true, display: true } },
    { name: "requestType", label: "Request Type", options: { filter: false, sort: true, display: true } },
    { name: "status", label: "Request Status", options: { filter: false, sort: true, display: true } },

];




export const data = [
    { requestName: "Vacation Request", requestType: "Vacation Request", status: "Yes" },
    { requestName: "Vacation encashing Request", requestType: "Vacation encashing Request", status: "Yes" },
    { requestName: "Request for Business Trip", requestType: "Request for Business Trip", status: "Yes" },
    { requestName: "Claim Request", requestType: "Claim Request", status: "Yes" },
    { requestName: "Update Employee Information", requestType: "Update Employee Information", status: "Yes" },
    { requestName: "Request for Letters", requestType: "Request for Letters", status: "Yes" },

]



