import React from 'react'
import { validationSchema } from './RegisterForm/data';
import { FormattedMessage } from 'react-intl'

export const RegisterPageData = {
    welcomeMsg: {
        text: <FormattedMessage id="Auth.Register.Message" />,
        subText: <FormattedMessage id="Auth.Register.SubMessage" />
    },
    title:  <FormattedMessage id="Auth.Register.Header" />,
    socialDivider:  <FormattedMessage id="Auth.Register.SocialDivider" />,
    link: {
        text: <FormattedMessage id="Auth.Register.HeaderLink" />,
        url: "/login",
    }

}


export const formProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
    },
    initialVals: {
        username: "",
        email: "",
        phoneNumber: "",
        password: "",
    },
};