

export const columns = [

    { name: "endOfContractType", label: "End of Contract Type", options: { filter: false, sort: true, display: true } },
    { name: "endOfContractDate", label: "End of Contract Date", options: { filter: false, sort: true, display: true } },
    { name: "endOfContractStatus", label: "End Of Contract Status", options: { filter: false, sort: true, display: true } },

];




export const data = [
    { endOfContractType: "Type 1", endOfContractDate: "Date", endOfContractStatus: "Status" },
    { endOfContractType: "Type 2", endOfContractDate: "Date", endOfContractStatus: "Status" },
    { endOfContractType: "Type 3", endOfContractDate: "Date", endOfContractStatus: "Status" },
    { endOfContractType: "Type 1", endOfContractDate: "Date", endOfContractStatus: "Status" },

]



