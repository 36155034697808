import React, { Component } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import { asEntity } from '~/Hoc';
import { FormHelperText, Button, withStyles, IconButton } from '@material-ui/core';
import { handleChange } from '~Services/Helpers/UploaderHelper';
import styles from './styles';
import ApiConfigs from '~/Configs/Api';
import { PictureAsPdf, CloudUpload, Close } from '@material-ui/icons';
import ConfirmationModal from './ConfirmationModal';
import CircularProgressWithLabel from './CircularProgress';

class Upload extends Component {
  confirmation = false;
  value = !!this.props.editValue ? this.props.editValue : '';
  files = [];
  defaultValue = '';
  loading = false;
  progress = 0;

  componentDidMount() { }

  toggle = () => {
    this.open = !this.open;
  };
  openConfirmation = () => {
    this.confirmation = true;
  };
  closeConfirmation = () => {
    this.confirmation = false;
  };

  onSave = () => {
    //upload file to server
    this.loading = true;
    this.confirmation = false;
    this.props.entityStore.post({ data: handleChange(this.files[0]), onLoad: this.onLoad });
    this.toggle();
  };
  onLoad = progrss => {
    this.progress = progrss;
  };
  deleteFile = () => {
    if (!!this.value) {
      //delete file from server
      // this.loading = true;
      this.props.entityStore.delete({ id: this.value._id });
    }
  };

  entityDidPosted = data => {
    if (!!data) {
      this.loading = false;
      this.value = data;
      this.props.InputChange(this.props.name, data);
      this.props.getValues(this.props.name, data._id);
    }
  };
  entityDidCatch = error => {
    this.loading = false;
  };
  entityDidDelete = data => {
    if (!!data) {
      // this.loading = false;
      this.value = '';
      this.props.InputChange(this.props.name, null);
      this.props.getValues(this.props.name, null);
    }
  };

  render() {
    const { accept, classes, maxFileSize } = this.props;
    return (
      <>
        {!!this.value ? (
          <div className={classes.container}>
            <span>
              <a className={classes.link} target='_blank' href={`${ApiConfigs.apiUrl}${this.value.path}`}>
                <PictureAsPdf color='primary' fontSize={'large'} />
                <span className={classes.title}>PDF Name</span>
              </a>
            </span>
            <IconButton className={classes.deleteIcon} component='span' onClick={this.deleteFile}>
              <Close className={classes.delete_icon} />
            </IconButton>
          </div>
        ) : (
            <p className={classes.empty_message}>No file uploaded</p>
          )}
        <Button
          variant='contained'
          color='#18b2aa'
          className={classes.button}
          onClick={this.toggle}
          disabled={this.loading}
          startIcon={
            this.loading ? (
              <CircularProgressWithLabel value={this.progress} size={40} className={classes.icon} />
            ) : (
                <CloudUpload fontSize={'large'} className={classes.icon} />
              )
          }
        >
          <span style={{ textAlign: 'center', fontSize: 13 }} className={'Read-Now'}>
            Upload File
          </span>
        </Button>
        <DropzoneDialog
          dropzoneClass={classes.dropzone}
          acceptedFiles={accept}
          filesLimit={1}
          cancelButtonText={'cancel'}
          submitButtonText={'submit'}
          maxFileSize={maxFileSize * 1000000} //limit is in bytes muliplied by how many mega you want
          open={this.open}
          onClose={this.toggle}
          onSave={files => {
            this.files = files;
            !!this.value ? this.openConfirmation() : this.onSave();
          }}
          showPreviews={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
          previewChipProps={{ classes: { root: classes.previewChip } }}
          previewText='Selected files'
        />
        {this.props.error && (
          <p style={{ color: '#f44336' }}>
            <FormHelperText> {this.props.errorText} </FormHelperText>
          </p>
        )}
        <ConfirmationModal
          confirmation={this.confirmation}
          closeConfirmation={this.closeConfirmation}
          onSave={this.onSave}
        />
      </>
    );
  }
}
decorate(Upload, {
  open: observable,
  value: observable,
  loading: observable,
  confirmation: observable,
  progress: observable,
});

export default withStyles(styles)(asEntity({ storeId: 'Upload' })(observer(Upload)));
