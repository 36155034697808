import React from 'react'
import GeneralForm from '../Partials/GeneralForm'
import useStyles from './styles'

function AddEquipmentType(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <GeneralForm {...props} />
        </div>
    )
}

export default AddEquipmentType;
