

export const columns = [
    { name: "id", options: { filter: false, sort: true, } },
    { name: "connectionID", options: { filter: false, sort: true, } },
    { name: "date", options: { filter: false, sort: true, } },
    { name: "startUtc", options: { filter: false, sort: true, } },
    { name: "endUtc", options: { filter: false, sort: true, } },
    { name: "link", options: { filter: false, sort: true, } },
    { name: "logLevel", options: { filter: false, sort: true, } },
    { name: "message", options: { filter: false, sort: true, } },
    { name: "status", options: { filter: false, sort: true, } },
    // { name: "statusCode", options: { filter: false, sort: true, } },
    { name: "time", options: { filter: false, sort: true, } },
    { name: "type", options: { filter: false, sort: true, } },
];





















