

export const columns = [
    { name: "docTypeCode", label: "Document Type Name", options: { filter: false, sort: true, display: true } },
    { name: "docName", label: "Document Type Name", options: { filter: true, sort: true, } },
    { name: "frgnName", label: "Foreign Name", options: { filter: true, sort: true, } },
];







