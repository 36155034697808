import { Button, withStyles, CircularProgress } from '@material-ui/core';
import { ArrowForward, } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormBuilder } from '~/Components/From';
import { asEntity } from '~/Hoc';
import { UserContext } from '~/Services/Providers';
import { formData, formikProps } from './data';
import { getStorage } from '~/Services/Helpers';
import { StorageKeys } from '~/Configs/Storage';
import useStyles from "./styles";
import { setAPI } from '~/Services/Api'



class ChoseCompanyForm extends React.Component {
    static contextType = UserContext;
    loading = false; // true , false
    values = {}

    handleSubmit = (values, editValues = {}) => {
        this.loading = true;
        let token = getStorage(StorageKeys.token);
        window.localStorage.setItem(StorageKeys.Company, values.company);
        const { setUserContext } = this.context;
        const company = values.company.split(".")
        setUserContext({
            isAuthenticated: true, token: token, company: { companyHost: company[0], companyId: company[1] }
        })
        setAPI(company[0])
        this.props.entityStore.post({ company: company[1] });
    };

    entityDidPosted(data) {
        if (data) {
            this.loading = false;
            this.props.moveForward()
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.FormWrapper}>
                <FormBuilder {...formikProps}
                    formData={formData}
                    formName="ChoseCompany"
                    editValues={this.values}
                    submitAction={this.handleSubmit}
                    customStyle={{ flexBasis: "100%" }}
                >
                    <div style={{ flexBasis: "100%", textAlign: "center" }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.SubmitButton}
                            endIcon={this.loading ? <CircularProgress color="light" size={15} /> : <ArrowForward />}
                            type="submit"
                            disabled={this.loading}

                        >
                            <FormattedMessage id="Auth.Continue" />

                        </Button>
                    </div>
                </FormBuilder>
            </div>

        )
    }
}


decorate(ChoseCompanyForm, {
    loading: observable,
})

export default withStyles(useStyles)(asEntity({ storeId: "MenuAndPermissions" })(observer(ChoseCompanyForm)));
