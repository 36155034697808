import { withStyles } from '@material-ui/core';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import ManHouMaintenanceTransactionForm from '../Partials/ManHouMaintenanceTransactionForm';
import styles from './styles';


class ViewManHouMaintenanceTransaction extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {};
    itemDetails = []
    loading = false;

    componentDidMount() {
        // if (!!this.id) {
        //     this.loading = true;
        //     this.props.entityStore.get(this.id)
        // }
        this.data = {
            Number: "1", kiloMetraj: "1231314", EquipmentCode: "EQ-001", MaintenanceType: "صيانة عادية", Date: "11/1/2020", spareParts: "SP-0002", Qty: "2", WorkingHours: "10", ProjectCode: "P0001",
        }
        this.itemDetails = [
            { spareParts: "SP-0002", Qty: "2", WorkingHours: "10", ProjectCode: "P0001", WhoseCode: "" },
            { spareParts: "SP-0005", Qty: "5", WorkingHours: "4", ProjectCode: "P0001", WhoseCode: "" },
            { spareParts: "SP-0010", Qty: "6", WorkingHours: "12", ProjectCode: "P0001", WhoseCode: "" }
        ]
    }
    setView = (view) => {
        this.view = view;
    }
    entityDidReceived(data) {
        if (!!data) {
            this.loading = false;
            let { docTypeCode, docName, frgnName } = data;

            this.data = {

            }

        }
    }

    render() {
        const { classes } = this.props;
        if (this.loading) return <Loading />
        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="AP Maintenance Transaction."
                        showViewName="ManHouMaintenanceTransaction"
                        data={this.data}
                    />
                }
                {
                    toJS(this.itemDetails) && toJS(this.itemDetails).map((item, index) =>
                        <ShowView title=""
                            key={"itemDetails" + index}
                            showViewName="ManHouMaintenanceTransactionDetails"
                            data={item}
                        // setView={this.setView}
                        />)
                }
                {/* {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <ManHouMaintenanceTransactionForm {...this.props} setView={this.setView} />
                    </div>
                } */}
            </div>
        )
    }
}

decorate(ViewManHouMaintenanceTransaction, {
    view: observable,
    data: observable,
    itemDetails: observable
})




export default withStyles(styles)(asEntity({ storeId: "ManHouMaintenanceTransactionForm" })(observer(ViewManHouMaintenanceTransaction)));
