
import React from 'react'
import { Chip } from '@material-ui/core'

const statusVals = {
    0: "Opened",
    1: "Closed",
    2: "Canceled",
}

export const columns = [
    { name: "contractNum", options: { filter: false, sort: true, display: true } },
    { name: "contractTypeCode", options: { filter: true, sort: true, } },
    { name: "cardName", options: { filter: true, sort: true, } },
    { name: "fromCity", options: { filter: true, sort: true, } },
    { name: "toCity", options: { filter: true, sort: true, } },
    { name: "withReturn", options: { filter: true, sort: true, } },
    { name: "returnRate", options: { filter: true, sort: true, } },
    { name: "amount", options: { filter: true, sort: true, } },
    { name: "weight", options: { filter: true, sort: true, } },
    { name: "totalVAT", options: { filter: true, sort: true, } },
    { name: "totalAmount", options: { filter: true, sort: true, } },
    { name: "equipmentCode1", options: { filter: true, sort: true, } },
    { name: "equipmentCode2", options: { filter: true, sort: true, } },
    { name: "materialCode", options: { filter: true, sort: true, } },
    { name: "servCode", options: { filter: true, sort: true, } },
    { name: "projectCode", options: { filter: true, sort: true, } },
    { name: "deliveryName", options: { filter: true, sort: true, } },
    { name: "deliveryDate", options: { filter: true, sort: true, } },
    { name: "salesEmployeeName", options: { filter: true, sort: true, } },
    {
        name: "invoiceStatus", options: {
            filter: true, sort: true,
            customBodyRender: (value, tableMeta, updateValue) => <Chip label={statusVals[value]} />
        }
    },
];





















