export const ProjectParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.code}-${element.name}`,
            id: `${element.name}_#_${element.code}`
        })
    });
    return Options;
}

export const ItemParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.itemCode}-${element.itemName}`,
            id: `${element.itemName}_#_${element.itemCode}`
        })
    });
    return Options;
}

export const MaintenanceTypeParseData = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.maintCode}-${element.mainName}`,
            id: element.maintCode
        })
    });
    return Options;
}
export const BusinessPartnerParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.cardCode}-${element.cardName}`,
            id: `${element.cardName}_#_${element.cardCode}`
        })
    });
    return Options;
}
export const EquipmentTypeParseData = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.eqCode}-${element.equiName}`,
            id: element.eqCode
        })
    });
    return Options;
};

export const WarehouseCodeParseData = (data) => {
    const Options = []
    data = data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.warehouseName}`,
            id: `${element.warehouseName}_#_${element.warehouseCode}`
        })
    });
    return Options;
}