import { Icon, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React from 'react'
import useStyles, { Styles } from './styles'

const AsideItem = ({ icon, name, active, level, handleClick }) => {
    const classes = useStyles();

    return (
        <div style={Styles.padding(level)}>
            <ListItem className={classes.MenuItem} selected={active} onClick={handleClick} button>
                <ListItemIcon style={{ minWidth: 30 }} >
                    <Icon className={classes.Icon} > {icon} </Icon>
                </ListItemIcon>
                <ListItemText className={classes.text} primary={name} />
            </ListItem>
        </div>
    )
}

export default AsideItem
