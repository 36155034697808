import React from 'react'
import useStyles from './styles';
import { WarningOutlined } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'

const EmptyData = () => {
    const  classes = useStyles();

    return (
        <dev className={classes.root}>
            <WarningOutlined color="error" style={{ fontSize: 60 }} />
            <h2> <FormattedMessage id="EmptyData" /> </h2>
        </dev>
    )
}

export default EmptyData
