import React from "react";
import Bar from "~/Components/AppBar";
import { AppBarComponents, AppBarLabels } from "./data";
import useStyles from "./styles";


const EmployeeDetails = () => {
    const classes = useStyles();

    return (
        <div style={{ textAlign: "right" }}>
            <div className={classes.root}>
                <Bar AppBarLabels={AppBarLabels}
                    AppBarComponents={AppBarComponents}
                    variant="fullWidth"
                />
            </div>
        </div>
    )
}

export default EmployeeDetails;
