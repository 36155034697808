import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import GeneralForm from '../Partials/GeneralForm'
import styles from './styles';


class ViewPartners extends React.Component {
    view = "show";
    code = this.props.match?.params?.id ? this.props.match.params.id : '';
    loading = false;
    data = {}
    headerData = {}
    componentDidMount() {
        if (!!this.code) {
            this.loading = true;
            this.props.entityStore.get({ code: this.code })
        }
    }
    setView = (view) => {
        this.view = view;
    }
    PasswordFix = (password) => {
        return !!password ? password.length * "*" : "No record"
    }
    arrayFix = (array) => {
        return array.length > 0 ? array.join(" , ") : "No record"
    }
    decamelize(str, separator) {
        separator = typeof separator === 'undefined' ? ' ' : separator;
        return str
            .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
            .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
            .toLowerCase();
    }
    dataTOShowView = (object, options = {}) => {
        let data = {}
        Object.keys(object).map((key) => {
            if (options[key]) {
                data[`${this.decamelize(key)} : `] = options[key](object[key])
            } else data[`${this.decamelize(key)} : `] = object[key]
        })
        return data
    }
    entityDidReceived(data) {
        if (!!data) {
            this.loading = false;
            const { phone1, fax, website, shippingType, password, indicator, project, industry, businessType, bpBranchAssignment, federalTaxID, companyRegistrationNumber, channelBP, territory, globalLocationNumber, blockSendingMarketingContent, paymentTermsType, priceListNum, discountPercent, creditLimit, maxCommitment, dunningTerm, automaticPosting, effectiveDiscount, effectivePrice, bankCountry, defaultBankCode, bankName, defaultAccount, defaultBankBranch, defaultBankIBAN, creditCardNum, creditCardExpiration, ownerIDNumber, avarageLate, priority, iban, partialDelivery, endorsableChecksFromBP, acceptsEndorsedChecks, houseBankCountry, houseBank, houseBankAccount, houseBankBranch, houseBankIBAN, referenceDetails, paymentBlock, singlePayment, collectionAuthorization, bankChargesAllocationCode, downPaymentInterimAccount, downPaymentClearAct, planningGroup, affiliate, cardCode, cardName, cardForeignName, businessPartnerGroup, currency, openOrdersBalance,
            } = data;

            const General = { phone1, fax, website, shippingType, password, indicator, project, industry, businessType, bpBranchAssignment, federalTaxID, companyRegistrationNumber, channelBP, territory, globalLocationNumber, blockSendingMarketingContent }
            const PaymentTerms = { paymentTermsType, priceListNum, discountPercent, creditLimit, maxCommitment, dunningTerm, automaticPosting, effectiveDiscount, effectivePrice, bankCountry, defaultBankCode, bankName, defaultAccount, defaultBankBranch, defaultBankIBAN, creditCardNum, creditCardExpiration, ownerIDNumber, avarageLate, priority, iban, partialDelivery, endorsableChecksFromBP, acceptsEndorsedChecks }
            const PaymentRun = { houseBankCountry, houseBank, houseBankAccount, houseBankBranch, houseBankIBAN, referenceDetails, paymentBlock, singlePayment, collectionAuthorization, bankChargesAllocationCode, }
            const Accounting = { downPaymentInterimAccount, downPaymentClearAct, planningGroup, affiliate, }

            const headerData = { 
                cardCode, 
                cardName, 
                cardForeignName, 
                businessPartnerGroup : businessPartnerGroup.name, 
                currency, 
                openOrdersBalance 
            }

            this.data = {
                General,
                paymentTerms: PaymentTerms,
                paymentRun: PaymentRun,
                Accounting: Accounting,
            }
            this.headerData = headerData

        }
    }
    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <>
                        <ShowView title="Business Partner Info. "
                            data={this.headerData}
                            showViewName="BusinessPartners"
                        />

                        <ShowView title=""
                            Tab={true}
                            data={this.data}
                            showViewName="BusinessPartners"
                        />
                    </>
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <GeneralForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewPartners, {
    view: observable,
})

export default withStyles(styles)(asEntity({ storeId: "PartnersForm" })(observer(ViewPartners)));
