import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    endOfContractType: Yup.date().required(),
    endOfContractDate: Yup.string(),

});


export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        endOfContractType: "",
        endOfContractDate: "",
    },
};


const ContractTypes = [
    { id: 0, name: "type 1", value: "type 1" },
    { id: 1, name: "type 2", value: "type 2" },
    { id: 2, name: "type 3", value: "type 3" },
];

export const formData = [
    { name: "endOfContractType", title: "End Of Contract Type", type: "dropdown", options: ContractTypes },
    { name: "endOfContractDate", type: "date", title: "End Of Contract Date", },

];


