import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Table from '~/Components/EnhancedTable';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { columns, RolesData } from './data';


class Roles extends React.Component {
    static contextType = NotificationContext;
    tableData = RolesData;
    loading = false;

    onDeleteConfirm = (idsArray) => {
        // idsArray.map(id => {
        //   this.props.entityStore.delete({ id });
        // });
    }

    entityDidDelete() {
        this.notify("Success", "Row/s was deleted successfully");
    }

    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }


    render() {

        if (this.loading) {
            return <Loading />
        }

        return (
            <Table
                showKeyIndex={0}
                addButtonText="Role"
                title="Roles"
                defaultData={this.tableData}
                columns={columns}
                parentDeleteAction={this.onDeleteConfirm}
            />
        )
    }
}

decorate(Roles, {
    tableData: observable.shallow,
    loading: observable,
})

export default asEntity({ storeId: 'Roles' })(observer(Roles));
