import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    question: Yup.string().trim().required(),
    answer: Yup.string().trim().required(),
    type: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        question: '',
        answer: '',
        type: '',
    },
};

const typesItem = [
    { id: 1, name: 'Transaction Bot', value: 'Transaction Bot' },
    { id: 2, name: 'Support Bot', value: 'Support Bot' },
    { id: 3, name: 'Performance Bot', value: 'Performance Bot' },
    { id: 4, name: 'CRM Bot', value: 'CRM Bot' },
    { id: 5, name: 'Policy Bot', value: 'Policy Bot' },
    { id: 6, name: 'HR Admin Bot', value: 'HR Admin Bot' },
]
export const formData = [
    { name: "question", type: "textarea" },
    { name: "answer", type: "textarea" },
    { name: "type", type: "dropdown", options: typesItem },
];


