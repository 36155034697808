import React from 'react'
import { Chip } from '@material-ui/core'
import { isEmpty } from './'

export const BooleanField = (value, tableMeta, updateValue) => <Chip label={!!value ? "YES" : "NO"} />

export function noRecord(value) {
    return !!value ? value : "NO Record"
}


export function capitalize(string) {
    let addToString = "";
    if (typeof string !== 'string') return string
    string = decamelize(string);
    if (string.trim().split("")[string.trim().split("").length - 1] != ":") addToString = " : "
    return string.charAt(0).toUpperCase() + string.slice(1) + addToString
}
export function decamelize(str, separator) {
    separator = typeof separator === 'undefined' ? ' ' : separator;
    return str
        .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
        .toLowerCase();
}