import { withStyles, Chip,IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PrintRounded from '@material-ui/icons/PrintRounded';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import ARServiceContractForm from '../Partials/ARServiceContractForm';
import styles from './styles';
import ApiConfigs from "~/Configs/Api";
import { StorageKeys } from "~/Configs/Storage";
import { getStorage } from "~/Services/Helpers";
import axios from "axios";
import { FileHeader } from '../../../../../Components/Uploader/Partials/FileHeader';
import { useHistory } from 'react-router-dom';


const token = getStorage(StorageKeys.token);
const company = window.localStorage.getItem(StorageKeys.Company) ? window.localStorage.getItem(StorageKeys.Company).split(".")[0] : "";
//const history = useHistory();

class ViewARServiceContract extends React.Component {
    constructor(props) {
        super(props);
        this.getfiles = this.getfiles.bind(this);
    }
    
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {};
    files = {};
    loading = false;
    status = 0;

    statusVals = {
        0: "Opened",
        1: "Closed",
        2: "Canceled",
    }


    componentDidMount() {
        this.getData();
    }

    getData() {
        if (!!this.id) {
            this.loading = true;
            this.props.entityStore.get(this.id)
        }
    }

    setView = (view) => {
        this.view = view;
    }




    deletefile = async (id) => {
        axios.delete(`${ApiConfigs.getCompanyURL(company)}Administration/Attachment/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }).then((response) => {
                this.getData();
            })
            .catch((error) => {

            })
    };
    getfiles = async (id) => {
        const result = await axios(
            `${ApiConfigs.getCompanyURL(company)}Administration/Attachment/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        );
        if (result.data) {
            this.files = result.data
            this.loading = false;
        }
    };
    handleClick =() => {
        let mid = this.id;
       // history.push(`${window.location.pathname}/${this.id}/print`);
        //window.open( `${ApiConfigs.getCompanyURL(company)}FleetManagement/ServiceContractORSC/${this.id}/print`, "_blank");
        //this.props.entityStore.delete( { mid });
      };
    entityDidReceived(data) {
        if (!!data) {
            let { contractNum, date,deliveryDate,cardName, isShipFromCompany, fromCity, isShipToCompany, toCity, withReturn, returnRate, weight,amount,totalVAT, totalAmount,equipmentCode1, equipmentCode2,deliveryName, projectName,servName,contracttype,salesEmployeeName ,remark, attKey}  = data;
            contracttype= data.contractTypeNavigation.contName;
            servName= data.shipmentService.servName;
            this.data = { contractNum, date,deliveryDate,cardName, isShipFromCompany, fromCity, isShipToCompany, toCity, withReturn, returnRate, weight,amount, totalVAT, totalAmount, equipmentCode1, equipmentCode2,deliveryName, projectName,servName, contracttype,salesEmployeeName,remark,   }
            this.status = data.invoiceStatus;
            if (attKey) {
                this.getfiles(attKey);
            } else {
                this.loading = false;
            }

        }
    }

    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                <Chip style={{ position: 'absolute', right: 60, top: 13 }}
                    variant="outlined"
                    color="primary" label={this.statusVals[this.status]}
                    onClick={this.handleClick}
                    clickable={true}
                />
                {
                    <Link to={ "/print/ARDelivry-" + this.id} target="_blank">
                        <div style={{ position: 'absolute', right: 0, top: 13 }}>
                            <PrintRounded 
                            style={{ fontSize:40 }}
                            />
                        </div>
                    </Link>
                }
                {
                    this.view === "show" &&
                    <>
                        <ShowView title="AR service contract Info."
                            data={this.data}
                            showViewName="ARServiceContract"
                            setView={false}
                        />
                        {(this.files && this.files.attachmentLines) ? <FileHeader files={this.files} onDelete={this.deletefile} /> : null}
                    </>
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <ARServiceContractForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewARServiceContract, {
    view: observable,
    data: observable,
    loading: observable
})




export default withStyles(styles)(asEntity({ storeId: "ARServiceContractForm" })(observer(ViewARServiceContract)));
