import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    empId: Yup.string().trim().required(),
    loanTypeCode: Yup.string().trim().required(),
    loanAmount: Yup.string().trim().required(),
    distributionStartDate: Yup.string().trim().required(),
    distributionEndDate: Yup.string().trim().required(),
    NoOfMonths: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        empId: "",
        loanTypeCode: "",
        loanAmount: "",
        distributionStartDate: "",
        distributionEndDate: "",
        NoOfMonths: "",
    },
};

const empIdItem = [
    { id: 1, name: 'emp 1', value: 'emp 1' },
    { id: 2, name: 'emp 2', value: 'emp 2' },
    { id: 3, name: 'emp 3', value: 'emp 3' },

];
const loanTypeCodeItems = [
    { id: 1, name: 'code 1', value: 'code 1' },
    { id: 2, name: 'code 2', value: 'code 2' },
    { id: 3, name: 'code 3', value: 'code 3' },

]
export const formData = [
    { name: "empId", type: "dropdown", options: empIdItem },
    { name: "loanTypeCode", type: "dropdown", options: loanTypeCodeItems },
    { name: "loanAmount", type: "text" },
    { name: "distributionStartDate", type: "text" },
    { name: "distributionEndDate", type: "text" },
    { name: "NoOfMonths", type: "text" },
];


