import React from 'react';
import ApiConfigs from "~/Configs/Api";
import { StorageKeys } from "~/Configs/Storage";
import { getStorage } from "~/Services/Helpers";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { data } from './data';
import { string } from 'yup';
const token = getStorage(StorageKeys.token);
const company = window.localStorage.getItem(StorageKeys.Company) ? window.localStorage.getItem(StorageKeys.Company).split(".")[0] : "";

class Print extends React.Component {
    constructor(props) {
        super(props);
        this.state = {Page: ""};
      }
componentDidMount() {
    window.console.log('param');
     this.renderPosts();
  }
  
  renderPosts = async() => {
    let param= this.props.match.params.id.split('-');
    let id =  param[1];
    let opject = data.filter((value)=> value.name == param[0] )[0] ;
    
    window.console.log(param);
    try {
      const res =  await axios(
        `${ApiConfigs.getCompanyURL(company)}${opject.path(id)}`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }
    );
      const page = res.data;
  
      // this will re render the view with new data
      this.setState({
        Page: page
      });
    } catch (err) {
        window.console.log(err);
    }
  }
  render() {
      //?o=ARServiceContractDelivery&id=14
    const page = this.state.Page? this.state.Page :"";
    window.console.log("render");
    return (
        <div dangerouslySetInnerHTML={{ __html: page }}>
          </div>
    );
  }
}
export default Print;