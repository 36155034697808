import 'date-fns';
import React from 'react';
import useStyles from './styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';

export default function DatePicker({ placeholder, name, title, width, editValue, InputChange, error, errorText, getValue, }) {
  const classes = useStyles();
  const [content, setContent] = React.useState(new Date());
  const [dateError, setDateError] = React.useState('');
  
  const setFormattedContent = date => {
    setContent(date);
    InputChange(name, date);
  };

  React.useEffect(() => {
    let value = !!editValue ? editValue : content;
    setFormattedContent(value);
    sendValueFormik(value);
    sendValue(value);
  }, []);

  const sendValueFormik = value => {
    let event = { target: { name, value } };
    InputChange && InputChange(event);
  };
  const sendValue = value => {
    getValue && getValue(name, value);
  };

  return (
    <div className={classes.root} style={{ flexBasis: !!width ? width : '100%' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {placeholder && <div className={classes.title}>{placeholder}</div>}
        <KeyboardDatePicker
          variant='inline'
          format='MM/dd/yyyy'
          id='date-picker-inline'
          name={name}
          label={''}
          value={content}
          helperText={''}
          style={{
            width: '100%',
            margin: '5px 0px',
          }}
          onChange={date => {
            sendValueFormik(date);
            sendValue(date);
            setContent(date);
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputProps={{
            disableUnderline: true,
            className: classes.input,
          }}
          onError={error => {
            setDateError(error);
          }}
        />
      </MuiPickersUtilsProvider>
      <small className={classes.error}>{error ? errorText : ''}</small>
    </div>
  );
}
