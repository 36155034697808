import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Table from '~/Components/EnhancedTable';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { columnsOptions } from './data';
import { DataToColumns } from "~/Services/Helpers/Normalizers"


class BrowseItLabor extends React.Component {
  static contextType = NotificationContext;
  tableData = [];
  loading = true;
  columns = []

  componentDidMount() {
    this.props.entityStore.get({ type: 1 });
  }

  entityDidReceived(data) {
    if (!!data) {
      this.columns = DataToColumns(data, columnsOptions)
      this.tableData = data;
      this.loading = false;
    }
  }

  entityDidCatch(error) {
    this.loading = false;
    this.notify("error", error.title)
  }


  notify = (severity, message) => {
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity,
      message
    }))
  }

  render() {

    if (this.loading) {
      return <Loading />
    }


    return (
      <Table
        showKeyIndex={0}
        addButtonText="Add Item Labor"
        title="Items Labor"
        defaultData={this.tableData}
        columns={this.columns}
        parentDeleteAction={this.onDeleteConfirm}
      />
    )
  }
}

decorate(BrowseItLabor, {
  tableData: observable.shallow,
  loading: observable,
})

export default asEntity({ storeId: 'BrowseItLabor' })(observer(BrowseItLabor));