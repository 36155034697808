import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    code: Yup.string().trim().required(),
    name: Yup.string().trim().required(),
    foreignName: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        code: "",
        name: "",
        foreignName: "",
    },
};


export const formData = [
    { name: "Code", type: "text", width: "48%" },
    { name: "Name", type: "text", width: "48%" },
    { name: "TypeCode", type: "text", width: "48%" },
    { name: "FactoryName", type: "text", width: "48%" },
    { name: "ModelNo", type: "text", width: "48%" },
    { name: "Model", type: "text", width: "48%" },
    { name: "SeralNumber", type: "text", width: "48%" },
    { name: "FixedAssetCode", type: "text", width: "48%" },
    { name: "CostCenterCode", type: "text", width: "48%" },
];
