import React from 'react'
import TripMasterForm from '../Partials/TripMasterForm'
import useStyles from './styles'

function AddTripMaster(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <TripMasterForm {...props}  />
        </div>
    )
}

export default AddTripMaster;
