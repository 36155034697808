import React from 'react'
import ShowView from '~/Components/ShowView';
import { EmployeeData } from './data'

const BasicInfo = () => {
    return (
        <ShowView title="Employee Basic Info."
            showViewName="ManagesDetails"
            data={EmployeeData}
        />
    )
}

export default BasicInfo;