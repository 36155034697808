import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    company: Yup.string().trim().required(),

});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        company: "",

    },
};

const parseData = (data) => {
    const positionData = []
    data.forEach(element => {
        if (element.companyHost !== "api")
            positionData.push({
                id: `${element.companyHost}.${element.companyID}`,
                name: `${element.companyHost}-${element.companyName}`,
            })
    });
    return positionData;
}
export const formData = [
    { name: "company", type: "dropdown",company : false ,  optionsURI: '/Account/UserAPPS', parseData: parseData, focused: true },
];