const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    Edit: {
        width: "100%"
    },
    Avatar: {
        width: "130px",
        height: "130px",
        margin: "auto"
    }
})


export default styles;