import React from 'react'
import FormControl from '../../../FormControl'
import { isEmpty } from '~/Services/Helpers';


const ClassicFormGenerator = ({ formData, editValues, isEdit, formDependency, handleChange, SetFieldValue, errors, values, formName, blur, onChange }) => {

    return (<>
        {Array.isArray(formData) &&
            formData.map((field, index) => (
                <FormControl
                    key={index}
                    field={field}
                    editState={isEdit()}
                    value={values[field.name]}
                    InputChange={(e) => handleChange(e, field.name)}
                    SetFieldValue={SetFieldValue}
                    error={!!errors[field.name]}
                    errorText={errors[field.name]}
                    formName={formName}
                    editValue={isEmpty(editValues[field.name]) ? '' : editValues[field.name]}
                    blur={blur}
                    dependency={isEmpty(formDependency[field.name]) ? null : formDependency[field.name]}
                    onChange={onChange}
                    values={values}
                />
            ))
        }

    </>)
}

ClassicFormGenerator.defaultProps = {
    editValues: {},
}

export default ClassicFormGenerator
