import { noRecord , BooleanField } from "~/Services/Helpers"

export const columns = [
    { name: "salesEmployeeCode", options: { filter: false, sort: true, display: true } },
    { name: "salesEmployeeName", options: { filter: false, sort: true, display: true } },
    { name: "remarks", options: { filter: true, sort: true, } },
    { name: "commissionForSalesEmployee", options: { filter: true, sort: true, } },
    { name: "commissionGroup",  options: { filter: true, sort: true, } },
    { name: "locked",  options: { filter: true, sort: true, customBodyRender: BooleanField} },
    { name: "employeeID",  options: { filter: true, sort: true, customBodyRender: (value) => noRecord(value) } },
    { name: "active",  options: { filter: true, sort: true, customBodyRender: BooleanField } },
];


