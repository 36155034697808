import React, { useEffect, useState, useRef } from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import useStyles from './styles';


const ImageCropper = ({ setNewImage, src, aspectRatio }) => {
    const classes = useStyles();
    const imageElement = useRef(null)
    useEffect(() => {
        const cropper = new Cropper(imageElement.current, {
            zoomable: false,
            scalable: false,
            aspectRatio: aspectRatio ? [aspectRatio.split(":")[0] / aspectRatio.split(":")[1]] : [1 / 1],
            crop: () => {
                const canvas = cropper.getCroppedCanvas();
                let newImageDestination = canvas.toDataURL("image/png")
                setNewImage(newImageDestination);
            }
        });
    });

    return (
        <div>
            <div class="img-container" className={classes.img}>
                <img ref={imageElement} className={classes.img} src={src} alt="Source" crossorigin />
            </div>
        </div>
    )
}

export default ImageCropper