
import { Button } from '@material-ui/core';
import React from 'react';
import useStyles from "./style"



export const DeleteMessage = ({ rowInfo, removeNode, handleClose }) => {
    const classes = useStyles();


    const deleteRows = () => {
        removeNode(rowInfo);
        handleClose();
    }
    const cancelDelete = () => {
        handleClose();
    }

    return (
        <div className={classes.deleteMessage}>
            Are you sure you want to delete the selected Rows <br />
            <div className={classes.modalBtnWrapper}>
                <Button className={classes.modalButton} onClick={cancelDelete} variant="contained" color="error">
                    Cancel
            </Button>
                <Button className={classes.modalButton} onClick={deleteRows} variant="contained" color="primary">
                    Yes
            </Button>

            </div>
        </div>
    )
}