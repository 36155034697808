

export const columns = [
    { name: "payslipId", options: { filter: true, sort: true, display: true } },
    { name: "month", options: { filter: true, sort: true, display: true } },
    { name: "year", options: { filter: true, sort: true, display: true } },

];



export const data = [
    { payslipId: 1, month: "month", year: "2011" },
    { payslipId: 2, month: "month", year: "2012" },
    { payslipId: 3, month: "month", year: "2013" },
    { payslipId: 4, month: "month", year: "2014" },
    { payslipId: 5, month: "month", year: "2015" },

]



