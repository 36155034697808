import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    Number: Yup.string().trim().required(),
    EquipmentCode: Yup.string().trim().required(),
    KiloMetraj: Yup.string().trim().required(),
    Maintenancetype: Yup.string().trim().required(),
    Date: Yup.string().trim().required(),
    spareparts: Yup.string().trim().required(),
    Qty: Yup.string().trim().required(),
    WorkingHours: Yup.string().trim().required(),
    ProjectCode: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        code: "",
        name: "",
        foreignName: "",
    },
};


export const formData = [
    { name: "Number", type: "text", width: "48%" },
    { name: "EquipmentCode", type: "text", width: "48%" },
    { name: "KiloMetraj", type: "text", width: "48%" },
    { name: "Maintenancetype", type: "text", width: "48%" },
    { name: "Date", title: "Date", type: "date", focused: true, width: "48%" },
    { name: "spareparts", type: "text", width: "48%" },
    { name: "Qty", type: "number", width: "48%" },
    { name: "WorkingHours", type: "number", width: "48%" },
    { name: "ProjectCode", type: "text", width: "48%" },
];