import { withStyles } from "@material-ui/core";
import { decorate, observable , toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import Bar from "~/Components/AppBar";
import ShowLabelWithButton from '~/Components/ShowLableWithButton';
import Modal from '~/Components/Modal';
import { asEntity } from '~/Hoc';
import { NotificationContext } from "~/Services/Providers";
import { ActionButtons, AppBarComponents, AppBarLabels } from "./data";
import { styles } from "./styles";
import { Check , HighlightOff } from "@material-ui/icons"
import { UpperCaseFirst } from '~/Services/Helpers'


  
class FoodicsSetup extends React.Component {
  static contextType = NotificationContext;
  value = 0;
  loading = [];
  showCheckupModal = false;
  checkupData = {};

  sendRequest(type) {
    this.loading = [...this.loading , type];
    this.props.entityStore.get({ type });
  }

  toggleCheckupModal = () => {this.showCheckupModal = !this.showCheckupModal}

  entityDidReceived({ data, type }) {
    this.loading = this.loading.map(key => key !== type);
    
    if (!!data) {
      if (type === 'checkup') {
        this.checkupData = data;
        this.showCheckupModal = true;
        
      } else {
        console.log('update info data', data)
      }
    }

  }

  entityDidCatch({ error, type }) {
    const label = ActionButtons.find(actionButton => actionButton.key === type).label;
    this.notify("error", `${label} error`);
  }

  notify = (severity, message) => {
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity,
      message
    }))
  }

  render() {
    const { classes } = this.props;
    return (
      <div style={{ textAlign: "right" }}>
        <div className={classes.root}>
          <Modal
            open={this.showCheckupModal}
            handleClose={this.toggleCheckupModal}
            title="Check up results"
          >
            <div>
              { !!this.checkupData && Object.keys(this.checkupData).map(key =>{
                  return key !== "messages" ?
                  <div className={classes.CheckupItem}>
                    <span> {UpperCaseFirst(key)} </span> &nbsp;
                    {this.checkupData[key] ? <Check color="green" /> : <HighlightOff color="red" />}
                  </div>
                  :'';
              })}
            </div>
            <div className={classes.CheckupMessages} >
              {
                !!this.checkupData?.messages && this.checkupData.messages.map(message =>{
                  let messageParts = message.split(':');
                  return <p> {messageParts[0]} : {messageParts[1]} </p>
                })
              }

            </div>
            
          </Modal> 

          {
            !!ActionButtons && ActionButtons.map(actionButton =>
              <ShowLabelWithButton
                label={actionButton.label}
                icon={actionButton.icon}
                handleClick={() => this.sendRequest(actionButton.key)}
                loading={this.loading.includes(actionButton.key)}
                tooltipTitle={actionButton.toolTip}
              />)
          }
          <Bar AppBarLabels={AppBarLabels}
            AppBarComponents={AppBarComponents}
          />
        </div>
      </div>
    );
  }
}

decorate(FoodicsSetup, {
  value: observable,
  showCheckupModal: observable,
  loading : observable,
});


export default withStyles(styles)(asEntity({ storeId: "FoodicsSetup" })(observer(FoodicsSetup)));
