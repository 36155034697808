

export const columns = [
    { name: "Code", label: "Code", options: { filter: true, sort: true, } },
    { name: "Name", label: "Name", options: { filter: true, sort: true, } },
    { name: "TypeCode", label: "Type Code", options: { filter: true, sort: true, } },
    { name: "FactoryName", label: "Factory Name", options: { filter: true, sort: true, } },
    { name: "ModelNo", label: "Model Number", options: { filter: true, sort: true, } },
    { name: "Model", label: "Model", options: { filter: true, sort: true, } },
    { name: "SeralNumber", label: "Seral Number", options: { filter: true, sort: true, } },
    { name: "FixedAssetCode", label: "Fixed Asset Code", options: { filter: true, sort: true, } },
    { name: "CostCenterCode", label: "Cost Center Code", options: { filter: true, sort: true, } },
];



export const data = [
    { Code : "EQ-001" , Name : "راس تريلا مارسيدس " , TypeCode : "TY-001 - راس تريلا " , FactoryName : "مارسيدس " , ModelNo : "" , Model : "2018" , SeralNumber : "35465431312" , FixedAssetCode : "FA-001" , CostCenterCode : "FA-001" , },
    { Code : "EQ-002" , Name : "ثلاجة " , TypeCode : "TY-004 - الثلاجة " , FactoryName : "مارسيدس " , ModelNo : "" , Model : "2015" , SeralNumber : "8865412322" , FixedAssetCode : "FA-002" , CostCenterCode : "FA-002" , },
]

