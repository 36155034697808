import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    sapSeriesCode: Yup.number().required(),
    bpGroup: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        sapSeriesCode: '',
        bpGroup: '',
    },
};

const sapSeriesCode = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}-${element.seriesType}`,
            id: element.series
        })
    });
    return Options;
}
const bpGroupCustomer = (data) => {
    const Options = []
    data=data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}`,
            id: element.code
        })
    });
    return Options;
}



export const formData = [
    { name: "sapSeriesCode", type: "dropdown", width: "48%", company: true, optionsURI: "Administration/Series/3", parseData: sapSeriesCode },
    { name: "bpGroup", type: "dropdown", width: "48%", company: true, optionsURI: "BusinessPartners/BusinessPartnerGroup/ByType/1", parseData: bpGroupCustomer },

];