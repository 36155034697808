import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    dependenceTest: Yup.string().trim().required(),
    testAdd: Yup.string().trim().required(),
    ActivityNumber: Yup.string().trim().required(),
    BPCode: Yup.string().trim().required(),
    Remarks: Yup.string().trim().required(),
    ActivityDate: Yup.date().required(),
    Telephone: Yup.string().trim().required(),
    Fax: Yup.string().trim().required(),
});
export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        dependenceTest: "",
        testAdd: "",
        foreignName: "",
        ActivityNumber: "",
        BPCode: "",
        Remarks: "",
        Telephone: "",
        Fax: "",
        ActivityDate: "",
    },
};
export const formData = [
    { name: "ActivityNumber", type: "text", width: "48%" },
    { name: "BPCode", type: "text", width: "48%" },
    { name: "Remarks", type: "textarea", maxWords: 25 },
    { name: "Telephone", type: "phone", width: "48%" },
    { name: "Fax", type: "phone", width: "48%" },
    { name: "ActivityDate", type: "date", focused: true, }
];