

export const columns = [
    { name: "id", options: { filter: false, sort: true, } },
    { name: "action", options: { filter: false, sort: true, } },
    { name: "connection_ID", options: { filter: false, sort: true, } },
    { name: "date", options: { filter: false, sort: true, } },
    { name: "time", options: { filter: false, sort: true, } },
    { name: "entity", options: { filter: false, sort: true, } },
    { name: "app_ID", options: { filter: false, sort: true, } },
    { name: "innerError", options: { filter: false, sort: true, } },
    { name: "logLevel", options: { filter: false, sort: true, } },
    { name: "message", options: { filter: false, sort: true, } },
    { name: "saP_ID", options: { filter: false, sort: true, } },
    { name: "status", options: { filter: false, sort: true, } },
];





















