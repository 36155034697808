import { Divider, ListItem, ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EmptyData } from '~/Components/ErrorPages';
import { isEmpty } from "~/Services/Helpers";
import Loading from '../../../Loading';
import useStyles from './styles';


function ShowViewGenerator({ data, showViewName , shouldValueCheck }) {
    const classes = useStyles()
    const [showViewData, setShowViewData] = useState(null)
    
    const valueCheck = (entry) => {
        if (isEmpty(entry)) return ""
        if (entry === 1 || entry === true) return "YES"
        if (entry === 0 || entry === false) return "NO"
        return entry;
    }

    useEffect(() => {
        const showValue = isEmpty(data) ? null : data;
        setShowViewData(showValue)
    }, [data])


    if( data === undefined){
        return <Loading />
    }

    return (<>
        {
            showViewData !== null ?
                Object.keys(data).map((key, index) =>{
                   if(key.toLowerCase() === 'id') return
                   return (<>
                        <ListItem key={index} button>
                            <ListItemText className={classes.ListItem} primary={
                                <>
                                    <span className={classes.ListItemText}> <strong> <FormattedMessage id={`${showViewName}.${key}`} />  : </strong>  </span>
                                    <span> { shouldValueCheck ? valueCheck(data[key]) : data[key]} </span>
                                </>
                            } />
                        </ListItem>
                        <Divider />
                    </>)
                }
                )
                : <EmptyData />
        }

    </>)
}

ShowViewGenerator.defaultProps = {
    shouldValueCheck : true
}


export default ShowViewGenerator
