import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.type == "dark" ? "#FFF" : "#000",
    },
    OpenRecent: {
        height: '100%',
        paddingBottom: "0",
        width: '100%',
        boxShadow: "1px 1px 4px -1px #000",
        borderRadius: "6px",
    },
    layout: {
        "& .react-grid-item > .react-resizable-handle::after": {
            right: "3px",
            width: "7px",
            bottom: "4px",
            height: "7px",
            borderRight: "3px solid #fff",
            borderBottom: "3px solid #fff",
        }
    },
    dragDiv: {
        backgroundColor: theme.palette.type == "dark" ? theme.palette.background.paper : "#d2d4e6",
        color: theme.palette.type == "dark" ? "#FFF" : "#000",
        cursor: "move",


    },
    dragIfream: {
        color: theme.palette.type == "dark" ? "#FFF" : "#000",
        width: "100%",
        height: "100%",

    },
    subheader: {
        fontSize: "16px",
        lineHeight: "30px !important",
        borderRadius: "6px",
    },
    link: {
        color: theme.palette.secondary.main,
        display: "block",
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: "bold",
    },
    addBtn: {
        margin: 0,
        top: 'auto',
        right: theme.direction === "ltr" ? 20 : "auto",
        bottom: 20,
        left: theme.direction === "rtl" ? 20 : "auto",
        position: 'fixed',
        float: theme.direction === "ltr" ? "right" : "left",
        zIndex: "1000"
    },
    EditBtn: {
        margin: 0,
        top: 'auto',
        right: theme.direction === "ltr" ? 20 : "auto",
        bottom: 140,
        left: theme.direction === "rtl" ? 20 : "auto",
        position: 'fixed',
        float: theme.direction === "ltr" ? "right" : "left",
        zIndex: "1000"
    },
    ResetBtn: {
        margin: 0,
        top: 'auto',
        right: theme.direction === "ltr" ? 20 : "auto",
        bottom: 80,
        left: theme.direction === "rtl" ? 20 : "auto",
        position: 'fixed',
        float: theme.direction === "ltr" ? "right" : "left",
        zIndex: "1000"
    },
    removeIcon: {
        top: "-5px",
        right: "-12px",
        position: "absolute",
        color: "#FFF",
    },
    editIcon: {
        top: "-5px",
        right: "24px",
        position: "absolute",
        color: "#FFF",
    },
}))

export default useStyles;