import React from 'react'
import APMaintenanceTransactionForm from '../Partials/APMaintenanceTransactionForm'
import useStyles from './styles'

function APMaintenanceTransaction(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <APMaintenanceTransactionForm {...props} />
        </div>
    )
}

export default APMaintenanceTransaction;
