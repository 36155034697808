import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
    appBar: {
        flexGrow: 1,
        width: '100%',
    },

}))

export default useStyles;