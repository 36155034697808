

export const columns = [
    { name: "vacationEncashingRequest", label: "Vacation encashing Request", options: { filter: false, sort: true, display: true } },
    { name: "register", label: "Register", options: { filter: false, sort: true, display: true } },
    { name: "type", label: "Type", options: { filter: false, sort: true, display: true } },
    { name: "from", label: "From Date ", options: { filter: false, sort: true, display: true } },
    { name: "to", label: "to date ", options: { filter: false, sort: true, display: true } },
    { name: "duration", label: "Duration", options: { filter: false, sort: true, display: true } },
    { name: "considerHolidays", label: "Consider holidays in Calendar", options: { filter: false, sort: true, display: true } },
    {
        name: "requireExitReEntry", label: "Do you require Exit / Re-entry", options: {
            filter: false, sort: true, display: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? "Yes" : "No"

            }
        }
    },
    { name: "exitReEntryId", label: "Exit / Re-entry ID", options: { filter: false, sort: true, display: true } },
    { name: "exitReEntryFrom", label: "Exit / Re-entry From", options: { filter: false, sort: true, display: true } },
    { name: "exitReEntryTo", label: "Exit / Re-entry To", options: { filter: false, sort: true, display: true } },
    {
        name: "requireTickets", label: "Do you require tickets", options: {
            filter: false, sort: true, display: true, customBodyRender: (value, tableMeta, updateValue) => {
                return value ? "Yes" : "No"

            }
        }
    },
    { name: "ticketsNumber", label: "Tickets Number", options: { filter: false, sort: true, display: true } },
    { name: "ticketsAirlines", label: "Tickets Airlines", options: { filter: false, sort: true, display: true } },
    { name: "ticketsType ", label: "Tickets Type", options: { filter: false, sort: true, display: true } },
    {
        name: "settleRemaining", label: "Settle remaining loans?", options: {
            filter: false, sort: true, display: true, customBodyRender: (value, tableMeta, updateValue) => {
                return value ? "Yes" : "No"

            }
        }
    },
    { name: "reason", label: "Reason", options: { filter: false, sort: true, display: true } },
    { name: "attachment", label: "Attachment", options: { filter: false, sort: true, display: true } },

];




export const data = [
    { vacationEncashingRequest: "Yes", register: "register", type: "type", from: "from", to: "to", duration: "duration", considerHolidays: "considerHolidays", requireExitReEntry: "requireExitReEntry", exitReEntryId: "exitReEntryId", exitReEntryFrom: "exitReEntryFrom", exitReEntryTo: "exitReEntryTo", requireTickets: "requireTickets", ticketsNumber: "2000121", ticketsAirlines: "Airlines 1", ticketsType: "Type", settleRemaining: "settleRemaining", reason: "reason", attachment: "attachment" },
    { vacationEncashingRequest: "Yes", register: "register", type: "type", from: "from", to: "to", duration: "duration", considerHolidays: "considerHolidays", requireExitReEntry: "requireExitReEntry", exitReEntryId: "exitReEntryId", exitReEntryFrom: "exitReEntryFrom", exitReEntryTo: "exitReEntryTo", requireTickets: "requireTickets", ticketsNumber: "2000121", ticketsAirlines: "Airlines 1", ticketsType: "Type", settleRemaining: "settleRemaining", reason: "reason", attachment: "attachment" },
    { vacationEncashingRequest: "Yes", register: "register", type: "type", from: "from", to: "to", duration: "duration", considerHolidays: "considerHolidays", requireExitReEntry: "requireExitReEntry", exitReEntryId: "exitReEntryId", exitReEntryFrom: "exitReEntryFrom", exitReEntryTo: "exitReEntryTo", requireTickets: "requireTickets", ticketsNumber: "2000121", ticketsAirlines: "Airlines 1", ticketsType: "Type", settleRemaining: "settleRemaining", reason: "reason", attachment: "attachment" },
    { vacationEncashingRequest: "Yes", register: "register", type: "type", from: "from", to: "to", duration: "duration", considerHolidays: "considerHolidays", requireExitReEntry: "requireExitReEntry", exitReEntryId: "exitReEntryId", exitReEntryFrom: "exitReEntryFrom", exitReEntryTo: "exitReEntryTo", requireTickets: "requireTickets", ticketsNumber: "2000121", ticketsAirlines: "Airlines 1", ticketsType: "Type", settleRemaining: "settleRemaining", reason: "reason", attachment: "attachment" },
    { vacationEncashingRequest: "Yes", register: "register", type: "type", from: "from", to: "to", duration: "duration", considerHolidays: "considerHolidays", requireExitReEntry: "requireExitReEntry", exitReEntryId: "exitReEntryId", exitReEntryFrom: "exitReEntryFrom", exitReEntryTo: "exitReEntryTo", requireTickets: "requireTickets", ticketsNumber: "2000121", ticketsAirlines: "Airlines 1", ticketsType: "Type", settleRemaining: "settleRemaining", reason: "reason", attachment: "attachment" },
    { vacationEncashingRequest: "Yes", register: "register", type: "type", from: "from", to: "to", duration: "duration", considerHolidays: "considerHolidays", requireExitReEntry: "requireExitReEntry", exitReEntryId: "exitReEntryId", exitReEntryFrom: "exitReEntryFrom", exitReEntryTo: "exitReEntryTo", requireTickets: "requireTickets", ticketsNumber: "2000121", ticketsAirlines: "Airlines 1", ticketsType: "Type", settleRemaining: "settleRemaining", reason: "reason", attachment: "attachment" },
    { vacationEncashingRequest: "Yes", register: "register", type: "type", from: "from", to: "to", duration: "duration", considerHolidays: "considerHolidays", requireExitReEntry: "requireExitReEntry", exitReEntryId: "exitReEntryId", exitReEntryFrom: "exitReEntryFrom", exitReEntryTo: "exitReEntryTo", requireTickets: "requireTickets", ticketsNumber: "2000121", ticketsAirlines: "Airlines 1", ticketsType: "Type", settleRemaining: "settleRemaining", reason: "reason", attachment: "attachment" },

]



