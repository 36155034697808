import React from "react";
import Styles from "./styles";
import { withStyles } from "@material-ui/core";
import { FormGenerator, TabFormGenerator } from './Partials';
import { withFormik } from "~/Hoc";
import { isEmpty } from '~/Services/Helpers';
import { Form } from 'formik'
class FormBuilder extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    // checking form name in case there where a nesting forms
    if (this.props.formName === e.target.name) {
      let validate = this.props.validateForm();
      let { values, errors } = this.props;
      validate.then((err) => {
        if (this.props.isValid) {
          if (this.isEdit()) this.props.submitAction(values, this.getEditedFields());
          else this.props.submitAction(values);

        } else {
          console.log('Form builder logs', { values });
          console.log(errors);
          console.log("validation error");
        }
      });
    }
  };

  isEdit = () => Boolean(
    !!this.props.editValues && Object.keys(this.props.editValues).length
  );

  getEditedFields = () => {
    let touchedFields = this.props.touched;
    let editedFields = [];

    Object.keys(touchedFields).forEach(key => {
      if (touchedFields[key]) {
        editedFields.push({
          op: 'replace',
          path: `/${key}`,
          value: this.props.values[key],
        })

      }
    })

    return editedFields;
  }

  componentDidMount = () => {
    if (this.isEdit()) {
      this.props.setValues(this.props.editValues, true);
    }
  };

  render() {

    const { customStyle, formName, formData, formDependency, children, errors, values, classes, Tab, editValues, handleBlur, handleChange, setFieldValue, onChange } = this.props;

    const formProps = {
      customStyle,
      formData,
      errors,
      formName,
      values,
      editValues,
      isEdit: this.isEdit,
      handleChange: handleChange,
      SetFieldValue: setFieldValue,
      blur: handleBlur,
      formDependency: isEmpty(formDependency) ? {} : formDependency,
      onChange: onChange
    }

    return (
      <Form name={formName} noValidate onSubmit={this.handleSubmit}>
          <div className={classes.root}>
            <div className={classes.formWrapper} style={!!customStyle ? { ...customStyle } : {}} >
              {
                !!Tab ? <TabFormGenerator {...formProps} /> : <FormGenerator {...formProps} />
              }
            </div>

            {children}
          </div>
      </Form>
    );
  }
}

export default withStyles(Styles)(withFormik(FormBuilder));
