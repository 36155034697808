export const styles = theme => ({
    AddButton : {
        float : 'right',
    },
    CheckupMessages : {
        '& p' : { 
            margin  : 2,
            fontSize : '12px'
        }
    }
})