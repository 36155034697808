import { Button, withStyles } from '@material-ui/core';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormBuilder, ActionButton } from '~/Components/From';
import Loading from '~/Components/Loading';
import { NotificationContext } from '~/Services/Providers';
import { formData, formikProps, } from './data';
import useStyles from "./styles";
class UrlItemForm extends React.Component {
    static contextType = NotificationContext;

    actionType = this.props.type;
    values = {};

    componentWillMount() {

    }


    handleSubmit = (values, editValues = {}) => {

        this.props.handelSubmit(values);
    };




    render() {
        const { classes ,editValue} = this.props;

        if (this.getLoading) {
            return <Loading />
        }
        return (
            <>
                <FormBuilder {...formikProps}
                    formData={formData}
                    formName="UrlItemForm"
                    editValues={editValue ? {url: editValue} : this.values}
                    submitAction={this.handleSubmit}
                    customStyle={{ flexBasis: "100%" }}
                >
                    <div style={{ flexBasis: "100%", display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.SubmitButton}
                            onClick={(e) => this.props.handleClose()}
                        >
                            Cancel
                        </Button>
                        <ActionButton customClass={classes.SubmitButton} title={this.actionType === 'edit' ? 'Update' : 'Add'}
                            icon={this.actionType === 'edit' ? 'arrow_forward' : 'add'}
                            loading={this.loading}
                        />

                    </div>
                </FormBuilder>
            </>

        )
    }
}


decorate(UrlItemForm, {
    loading: observable,
})

export default withStyles(useStyles)(observer(UrlItemForm));



