import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    eqTypeCode: Yup.string().trim().required(),
    eqName: Yup.string().trim(),
    frgnName: Yup.string().trim(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        eqTypeCode: "",
        eqName: "",
        frgnName: "",
    },
};


export const formData = [
    { name: "eqTypeCode", type: "text", editDisabled: true },
    { name: "eqName", type: "text" },
    { name: "frgnName", type: "text", },
];