

export const columns = [
    { name: "atDocumentType", label: "At Document Type ", options: { filter: true, sort: true, } },
    { name: "bplid", label: "bplid ", options: { filter: true, sort: true, } },
    { name: "digitNumber", label: "Digit Number ", options: { filter: true, sort: true, } },
    { name: "document", label: "Document ", options: { filter: true, sort: true, } },
    { name: "documentSubType", label: "Document SubType ", options: { filter: true, sort: true, } },
    { name: "groupCode", label: "Group Code ", options: { filter: true, sort: true, } },
    { name: "initialNumber", label: "Initial Number ", options: { filter: true, sort: true, } },
    { name: "isDigitalSeries", label: "Is Digital Series ", options: { filter: true, sort: true, } },
    { name: "isElectronicCommEnabled", label: "Is Electronic Comm Enabled ", options: { filter: true, sort: true, } },
    { name: "lastNumber", label: "last Number ", options: { filter: true, sort: true, } },
    { name: "locked", label: "locked ", options: { filter: true, sort: true, } },
    { name: "name", label: "Name ", options: { filter: true, sort: true, } },
    { name: "nextNumber", label: "Next Number ", options: { filter: true, sort: true, } },
    { name: "periodIndicator", label: "Period Indicator ", options: { filter: true, sort: true, } },
    { name: "prefix", label: "Prefix ", options: { filter: true, sort: true, } },
    { name: "series", label: "Series ", options: { filter: true, sort: true, } },
    { name: "seriesType", label: "Series Type ", options: { filter: true, sort: true, } },
    { name: "suffix", label: "Suffix ", options: { filter: true, sort: true, } },
    { name: "costAccountOnly", label: "Cost Account Only ", options: { filter: true, sort: true, } },
    { name: "isManual", label: "Is Manual ", options: { filter: true, sort: true, } },
];

