import { autoGeneratedColumnsEnumNormalizer } from "~/Services/Helpers";

const genderObject = {
    0: 'Female',
    1: 'Male',
    2: 'Undefined',
    3: 'Masked',
}

const boolObject = {
    0: 'No',
    1: 'Yes'
}

export const columnsValuesNormalizer = {
    gender: autoGeneratedColumnsEnumNormalizer(genderObject),
    accountantResponsible : autoGeneratedColumnsEnumNormalizer(boolObject),
    legalRepresentative : autoGeneratedColumnsEnumNormalizer(boolObject),
    dirfResponsible : autoGeneratedColumnsEnumNormalizer(boolObject),
    active : autoGeneratedColumnsEnumNormalizer(boolObject),
    previousPRWebAccess: autoGeneratedColumnsEnumNormalizer(boolObject),
}