import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    dateOfClaim: Yup.date().required(),
    periodOfClaim: Yup.string().trim(),
    claimType: Yup.string().trim(),
    claimAmount: Yup.string().trim(),
    projectCode: Yup.string().trim(),
    stage: Yup.string().trim(),
    // attachments: Yup.string().trim(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        periodOfClaim: "",
        claimType: "",
        claimAmount: "",
        projectCode: "",
        stage: "",
        attachments: "",
    },
};

const ItemStage = [
    { id: 0, name: "1", value: "1" },
    { id: 0, name: "2", value: "2" },
    { id: 0, name: "3", value: "3" },
]
const ItemClaimType = [
    { id: 0, name: "Business Trip", value: "Business Trip" },
    { id: 0, name: "Medical Claim", value: "Medical Claim" },
    { id: 0, name: "Business Claim", value: "Business Claim" },
    { id: 0, name: "Mobile Claim", value: "Mobile Claim" },
]

const projectCode = (data) => {
    const positionData = []
    Array.isArray(data) && data.forEach(element => {
        positionData.push({
            name: `${element.code}-${element.name}`,
            id: element.code
        })
    });
    return positionData;
}
export const formData = [
    { name: "dateOfClaim", type: "data", focused: true, },
    { name: "periodOfClaim", type: "text" },
    { name: "claimType", type: "dropdown", options: ItemClaimType },
    { name: "claimAmount", type: "number" },
    { name: "projectCode", type: "dropdown", company: true, optionsURI: "/Financials/Project", parseData: projectCode },
    { name: "stage", type: "dropdown", options: ItemStage },
    { name: "attachment", type: "file", width: "100%", accept: ["application/pdf"], maxFileSize: 10 },

];


