import * as Yup from "yup";
import { ContractTypeParseData, EquipmentDocumentParseData, ProjectParseData, SalseEMPParseData,BusinessPartnerParseData, MaterialCodeParseData, WarehouseCodeParseData, ServicePraseData ,EMPParseData} from './parsers';
import { isEmpty } from '~/Services/Helpers'

export const validationSchema = Yup.object().shape({
    contractTypeCode: Yup.string().required(),
    cardCode: Yup.string().required(),
    equipmentCode1: Yup.string().required(),
    equipmentCode2: Yup.string().required(),
    projectCode: Yup.string().required(),
    materialCode: Yup.string().required(),
    weight: Yup.string().required(),
    servCode: Yup.string().required(),
    fromCity: Yup.string().required(),
    toCity: Yup.string().required(),
    amount: Yup.number().required(),
    deliveryCard:Yup.string().required(),
    salesEmployeeCode:Yup.string().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        contractTypeCode: "",
        contractNum: null,
        isShipFromCompany: false,
        fromCity: "",
        isShipToCompany: false,
        toCity: "",
        withReturn: false,
        returnRate: 0,
        amount: "",
        weight: "",
        deliveryDate: "",
        equipmentCode1: "",
        equipmentCode2: "",
        projectCode: "",
        attKey: "",
        MaterialsType: "",
        cardCode: "",
        deliveryCard:null,
        deliveryName:"",
        salesEmployeeName:""
    },
};


export const formData = [
    { name: "contractTypeCode", width: "48%", type: "dropdown", optionsURI: "/FleetManagement/ContractType?invoiceType=0", parseData: ContractTypeParseData },
    { name: "cardCode", type: "dropdown", optionsURI: "/BusinessPartners/BusinessPartner/Short?type=0", parseData: BusinessPartnerParseData, width: "48%", hasSearch:true, searchPre:"&search=" },
    { name: "deliveryCard", type: "dropdown", optionsURI: "/HR/Employee/Short?jobTitle=Driver", parseData: EMPParseData, width: "48%", hasSearch:true, searchPre:"&search=" },
    { name: "salesEmployeeCode", type: "dropdown", optionsURI: "/Administration/SalesEmployee/Short", parseData: SalseEMPParseData, width: "48%", hasSearch:true, searchPre:"?search=" },
    { name: "equipmentCode1", type: "text", width: "48%", type: "dropdown", optionsURI: "/FleetManagement/EquipmentMasterData", parseData: EquipmentDocumentParseData },
    { name: "equipmentCode2", type: "text", width: "48%", type: "dropdown", optionsURI: "/FleetManagement/EquipmentMasterData", parseData: EquipmentDocumentParseData },
    { name: "amount", type: "number", width: "48%", },
    { name: "projectCode", type: "dropdown", optionsURI: "/Financials/Project/Short", parseData: ProjectParseData, width: "48%", hasSearch:true, searchPre:"?search=" },
    { name: "materialCode", width: "48%", type: "dropdown", optionsURI: "/FleetManagement/TypeOfShipment", parseData: MaterialCodeParseData },
    { name: "servCode", width: "48%", type: "dropdown", optionsURI: "/FleetManagement/ShipmentServiceMasterPlusERPLink", parseData: ServicePraseData },
    { name: "weight", type: "text", width: "48%", },
    { name: "deliveryDate", type: "date", width: "48%", },
    { name: "withReturn", type: "switch", },
    { name: "returnRate", type: "number", show: false, },
    { name: "isShipFromCompany", type: "switch" },
    { name: "fromCity", type: "text", parseData: WarehouseCodeParseData, },
    { name: "isShipToCompany", type: "switch", width: "48%", },
    { name: "toCity", type: "text", parseData: WarehouseCodeParseData, },
    { name: "remark", type: "textarea", wordCount: 100, },
    {
        name: "attKey",
        label: "attach",
        type: "uploader",
        width: "100%",
        accept: ['image/*', ".pdf", ".doc", "audio/*", "video/*"],
        aspectRatio: "1:1",
        multiple: true,
        maxFileSize: 30
    },
];

export const formDependency = {
    returnRate: [
        { fields: ['withReturn'], prop: "field.show", action: (fieldValues) => fieldValues.withReturn }
    ],
    fromCity: [
        {
            fields: ['isShipFromCompany', 'cardCode'], prop: "field.type", action: (fieldValues) => {
                if (isEmpty(fieldValues.cardCode) && fieldValues.isShipFromCompany === false) return "text";
                else return "dropdown";
            }
        },
        {
            fields: ['isShipFromCompany', 'cardCode'], prop: "field.optionsURI", action: (fieldValues) => {
                if (fieldValues.isShipFromCompany) return "/Inventory/Warehouse"
                else {
                    let code = isEmpty(fieldValues.cardCode) ? '' : fieldValues.cardCode.split('_#_')[1];
                    return `/BusinessPartners/BusinessPartner/${code}/Addresses/1`;
                }
            }
        },
    ],
    toCity: [
        {
            fields: ['isShipToCompany', 'cardCode'], prop: "field.type", action: (fieldValues) => {
                if (isEmpty(fieldValues.cardCode) && fieldValues.isShipToCompany === false) return "text";
                else return "dropdown";
            }
        },
        {
            fields: ['isShipToCompany', 'cardCode'], prop: "field.optionsURI", action: (fieldValues) => {
                if (fieldValues.isShipToCompany) return "/Inventory/Warehouse";
                else {
                    let code = isEmpty(fieldValues.cardCode) ? '' : fieldValues.cardCode.split('_#_')[1];
                    return `/BusinessPartners/BusinessPartner/${code}/Addresses/0`;
                }
            }
        },
    ]
}
