import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '~/Components/EnhancedTable';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { NotificationContext } from '~/Services/Providers';
import { columns, data, DetailsColumns } from './data';
import styles from './styles';
import { withStyles } from '@material-ui/core';


class ManHouMaintenanceTransaction extends React.Component {
  static contextType = NotificationContext;
  tableData = data;
  DetailsData = [];
  loading = true;
  DetailsLoading = true
  itemsDetails = "";
  itemsDetailsOptions = []
  componentDidMount() {
    // this.props.entityStore.get();
    setTimeout(() => {
      this.loading = false;
    }, 600);
    this.itemsDetailsOptions = this.getOptions(data)
  }
  handleChange = (event) => {
    this.DetailsLoading = true
    this.itemsDetails = event.target.value;
    const Data = this.tableData.find(element => element.Number === event.target.value);
    if (Data) {
      this.DetailsData = Data.itemDetails
    }
    else this.DetailsData = []
    setTimeout(() => {
      this.DetailsLoading = false
    }, 100);

  }
  getOptions(data) {
    let options = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].itemDetails.length > 0) {
        options.push(data[i].Number)
      }
    }
    return options
  }
  entityDidReceived(data) {
    if (!!data) {
      this.tableData = data;
      this.itemsDetailsOptions = this.getOptions(data)
      this.loading = false;
    }
  }
  entityDidCatch(error) {
    this.loading = false;
    this.notify("error", error)

  }
  onDeleteConfirm = (idsArray) => {
    idsArray.map(id => {
      this.props.entityStore.delete({ id });
    });
  }
  entityDidDelete() {
    this.notify("Success", "Row/s was deleted successfully");
  }
  notify = (severity, message) => {
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity,
      message
    }))
  }

  render() {
    const options = {
      enableNestedDataAccess: '.',
      selectableRows: "none",
      onRowClick: (rowData, rowMeta) => { }
    };
    const { classes } = this.props;
    if (this.loading) return <Loading />
    return (
      <>
        <Table
          // addLink
          showKeyIndex={0}
          addButtonText="Man Hou Maintenance Transaction "
          title="ManHouMaintenanceTransaction"
          defaultData={this.tableData}
          columns={columns}
          parentDeleteAction={this.onDeleteConfirm}
        />
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">Details of</InputLabel>
          <Select
            native
            value={this.itemsDetails}
            onChange={this.handleChange}
            label="Details of"
            inputProps={{
              name: 'Details of',
              id: 'Details of',
            }}
          >
            <option aria-label="None" value="" />
            {this.itemsDetailsOptions.map((item, index) =>
              <option key={"itemsDetailsOptions" + index} value={item}>{item}</option>
            )}
          </Select>
        </FormControl>
        {!this.DetailsLoading &&
          < Table
            showKeyIndex={0}
            title="ManHouMaintenanceTransactionDetails"
            defaultData={toJS(this.DetailsData)}
            columns={DetailsColumns}
            parentDeleteAction={this.onDeleteConfirm}
            customOptions={options}
          />
        }
      </>
    )
  }
}

decorate(ManHouMaintenanceTransaction, {
  tableData: observable.shallow,
  DetailsData: observable,
  loading: observable,
  itemsDetails: observable,
  itemsDetailsOptions: observable,
  DetailsLoading: observable
})

export default withStyles(styles)(asEntity({ storeId: 'ManHouMaintenanceTransaction' })(observer(ManHouMaintenanceTransaction)));
