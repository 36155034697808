import React from 'react'
import AssignActivityForm from '../Partials/AssignActivityForm'
import useStyles from './styles'

function AddAssignActivity(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AssignActivityForm {...props} />
        </div>
    )
}

export default AddAssignActivity;
