import { Button, withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormBuilder, ActionButton } from '~/Components/From';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { formData, formikProps } from './data';
import useStyles from "./styles";
import { FormattedMessage } from 'react-intl';


class ClaimRequestForm extends React.Component {
    static contextType = NotificationContext;
    loading = false; // true , false
    getLoading = false;
    actionType = "Add";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';

    values = this.id == '' ? {} : { dateOfClaim: `${new Date()}`, periodOfClaim: "periodOfClaim", claimType: "claimType", claimAmount: "claimAmount", projectCode: "projectCode", attachments: "attachments", stage: "stage" };



    handleSubmit = (values, editValues = {}) => {
        this.loading = true;

        setTimeout(() => {
            this.loading = false;
        }, 400);

        this.successActions('success', `${this.actionType === "Add" ? "Added" : "Updated"} successfully`);
        // this.props.history.push('/administration/equipment-type');
    };



    entityDidPosted(data) {
        this.successActions('Document Type successfully');
    }

    entityDidUpdated(data) {
        this.successActions('Document Type updated successfully')
    }

    successActions = (message) => {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'success',
            message
        }
        ))
        this.props.history.goBack()
    }



    render() {
        const { classes } = this.props;

        if (this.getLoading) {
            return <Loading />
        }

        return (
            <div className={classes.FormWrapper}>
                <FormBuilder {...formikProps}
                    formData={formData}
                    formName="ClaimRequests"
                    editValues={this.values}
                    submitAction={this.handleSubmit}
                    customStyle={{ flexBasis: "100%" }}
                >
                    <div style={{ flexBasis: "100%", display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.SubmitButton}
                            onClick={(e) => !!this.id ? this.props.setView("show") : this.props.history.goBack()}
                        >
                            <FormattedMessage id="Cancel" />

                        </Button>
                        <ActionButton customClass={classes.SubmitButton} title={this.id !== '' ? 'Duplicate' : 'Add'}
                            icon={this.id !== '' ? 'add' : 'arrow_forward'}
                            loading={this.loading}
                        />
                        {
                            this.id !== '' &&
                            <ActionButton customClass={classes.SubmitButton} title="Update" icon="arrow_forward" loading={this.loading}
                                onClick={() => this.actionType = "Edit"}
                            />
                        }
                    </div>
                </FormBuilder>
            </div>

        )
    }
}


decorate(ClaimRequestForm, {
    loading: observable,
})

export default withStyles(useStyles)(asEntity({ storeId: "ClaimRequestForm" })(observer(ClaimRequestForm)));
