import * as Yup from "yup";


export const validationSchema = Yup.object().shape({
    tripCode: Yup.string().trim().required(), 
    tripName : Yup.string().trim().required(), 
    fromCity : Yup.string().trim().required(), 
    toCity : Yup.string().trim().required(), 
    distance : Yup.number().required(), 
    price : Yup.number().required(), 
});


export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate : false,
    },
    initialVals: {
        tripCode: "",
        tripName : "",
        fromCity : "",
        toCity : "",
        distance : "",
        price : "",
    },
};

export const formData = [
    { name: "tripCode", type: "text" },
    { name: "tripName", type: "text" },
    { name: "fromCity", type: "text", },
    { name: "toCity", type: "text", },
    { name: "distance", type: "number" },
    { name: "price", type: "number", },
];
