import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import styles from './styles';


class ViewPayslip extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';


    data = { payslipId: "payslipId", month: "month", year: "year" };
    loading = false;


    componentDidMount() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 600);
    }
    setView = (view) => {
        this.view = view;
    }


    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="Payslips Details."
                        data={this.data}
                        showViewName="Payslips"
                    />
                }
            </div>
        )
    }
}

decorate(ViewPayslip, {
    view: observable,
    loading: observable,

})




export default withStyles(styles)(asEntity({ storeId: "PayslipForm" })(observer(ViewPayslip)));
