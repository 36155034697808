import React from 'react'
import { validationSchema } from './LoginForm/data';
import { FormattedMessage } from 'react-intl'
export const LoginPageData = {
    welcomeMsg: {
        text: <FormattedMessage id="Auth.Login.Message" />,
        subText: <FormattedMessage id="Auth.Login.SubMessage" />
    },
    title: <FormattedMessage id="Auth.Login.Header" />,
    socialDivider: <FormattedMessage id="Auth.Login.SocialDivider" />,
    link: {
        text: <FormattedMessage id="Auth.Login.HeaderLink" />,
        url: "/register",
    }

}


export const formProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
    },
    initialVals: {
        email: "",
        password: "",
    },
};