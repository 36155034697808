import React, { useState, createContext, useContext } from "react";

import { getStorage } from '~/Services/Helpers';

import { default as Sau } from '../../Images/ar.svg'
import { default as Gb } from '../../Images/en.svg'


const AppContextData = {
  theme: getStorage("theme") || "light", // dark , light
  locale: getStorage("locale") || "en", // en , ar
  direction: (!getStorage("locale")) ? "ltr" : (getStorage("locale") === "en" ? "ltr" : "rtl"), //ltr , rtl
  langs: {
    ar: {
      text: "العربية",
      icon: <img height="10" src={Sau} />,
      code: "ar"
    },
    en: {
      text: "English",
      icon: <img height="10" src={Gb} />,
      code: "en"
    },

  }
};

// initializing context
export const AppContext = createContext(AppContextData);

// setting a helper function to use context
export const useAppContext = () => useContext(AppContext);

export function AppContextProvider({ children }) {
  const [appContextObj, setAppContext] = useState(AppContextData);
  return (
    <AppContext.Provider value={{ appContextObj, setAppContext }}>
      {children}
    </AppContext.Provider>
  );
}
