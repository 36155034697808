import React from 'react'
import ARServiceContractForm from '../Partials/ARServiceContractForm'
import useStyles from './styles'

function ARServiceContract(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <ARServiceContractForm {...props} />
        </div>
    )
}

export default ARServiceContract;
