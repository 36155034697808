import { Button, withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { ActionButton, FormBuilder } from '~/Components/From';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { foodicsNameDenormalizer, foodicsNameNormalizer, isEmpty } from '~/Services/Helpers';
import { NotificationContext } from '~/Services/Providers';
import { formData, formikProps } from './data';
import useStyles from "./styles";


class GIMaintenanceTransaction extends React.Component {
    static contextType = NotificationContext;
    loading = false; // true , false
    getLoading = false;
    actionType = "Add";
    values = {};
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    fieldsWithName = ['itemCode', 'projectCode', 'warehouseCode'];

    componentDidMount() {
        if (!!this.id) {
            this.getLoading = true;
            this.props.entityStore.get(this.id);
        }
    }

    handleSubmit = (values, editValues = []) => {
        this.loading = true;
        //const normalizedValues = values;
        //const normalizedEditValues = editValues
        const { values: normalizedValues, editValues: normalizedEditValues } = foodicsNameNormalizer([], values, editValues);

        const normalizedDetails = normalizedValues.maintenanceTrancstionIMT1s.map(entry => {
            let { values } = foodicsNameNormalizer(this.fieldsWithName, entry, []);
            return values
        })
        normalizedValues.maintenanceTrancstionIMT1s = normalizedDetails;

        if (editValues.some(entry => entry.path.includes('maintenanceTrancstionIMT1s'))) {
            normalizedEditValues = editValues.map(entry => {
                if (entry.path.includes('maintenanceTrancstionIMT1s')) {
                    const normalizedDetails = entry.map(subEntry => {
                        let { values } = foodicsNameNormalizer(this.fieldsWithName, subEntry, []);
                        return values
                    })
                    return normalizedDetails
                }
                return entry

            })
        }



        if (this.actionType !== "Edit") this.props.entityStore.post(normalizedValues);
        else this.props.entityStore.patch({ id: values.docTypeCode, editValues: normalizedEditValues })
    };


    entityDidReceived(data) {
        if (!!data) {
            this.getLoading = false;
            let normalizedData = {};
            let DocumentType = data;
            //removing null
            Object.keys(DocumentType).map(key => {
                if (DocumentType[key] === null) normalizedData[key] = '';
                else normalizedData[key] = DocumentType[key];
            })

            normalizedData.maintenanceTrancstionIMT1s = normalizedData.maintenanceTrancstionIMT1s.map(entry => {
                let values = foodicsNameDenormalizer(this.fieldsWithName, normalizedData);
                return values
            })
            this.values = normalizedData;
        }
    }

    entityDidPosted(data) {
        this.successActions('GI Maintenance Transaction Form successfully');
    }

    entityDidUpdated(data) {
        this.successActions('GI Maintenance Transaction Form updated successfully')
    }

    successActions = (message) => {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'success',
            message
        }
        ))
        this.props.history.goBack()
    }

    entityDidCatch(errors) {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'error',
            message: isEmpty(errors?.Message) ? "Something went wrong" : errors.Message

        }
        ))
    }

    render() {
        const { classes } = this.props;

        if (this.getLoading) {
            return <Loading />
        }

        return (
            <div className={classes.FormWrapper}>
                <FormBuilder {...formikProps}
                    formData={formData}
                    formName="GIMaintenanceTransaction"
                    editValues={this.values}
                    submitAction={this.handleSubmit}
                    customStyle={{ flexBasis: "100%" }}
                >
                    <div style={{ flexBasis: "100%", display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.SubmitButton}
                            onClick={(e) => !!this.id ? this.props.setView("show") : this.props.history.goBack()}
                        >
                            Cancel
                        </Button>

                        <ActionButton customClass={classes.SubmitButton} title={this.id !== '' ? 'Duplicate' : 'Add'}
                            icon={this.id !== '' ? 'add' : 'arrow_forward'}
                            loading={this.loading}
                        />

                        {
                            this.id !== '' &&
                            <ActionButton customClass={classes.SubmitButton} title="Update" icon="arrow_forward" loading={this.loading}
                                onClick={() => this.actionType = "Edit"}
                            />
                        }
                    </div>
                </FormBuilder>
            </div>

        )
    }
}


decorate(GIMaintenanceTransaction, {
    loading: observable,
})

export default withStyles(useStyles)(asEntity({ storeId: "GIMaintenanceTransactionForm" })(observer(GIMaintenanceTransaction)));
