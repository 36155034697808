import { FormHelperText, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { onlyOneOfProps } from '~/Services/Helpers';
import Styles from './styles';

class DropdownMultiple extends React.Component {
    value = [];
    options = [];
    loading = false;
    defaultValue = !!this.props.editValue ? this.props.editValue : this.value;

    componentDidMount = () => {
        if (!!this.props.options) {
            this.value = this.defaultValue;
            this.props.InputChange(this.props.name, this.defaultValue);
            this.options = this.props.options;
        }
        else {
            this.loading = true;
            this.props.entityStore.get({ uri: this.props.optionsURI, body: {} });
        }
    };

    handleChange = (event , values) => {
        this.value = values;
        this.props.InputChange(this.props.name, values);
    };

    entityDidReceived(data) {
        if(!!data){
            this.loading = false;
            this.options = data;
            this.props.InputChange(this.props.name, this.defaultValue);
            this.value = this.defaultValue;
        }
    }


    render() {
        let { error, errorText, name, width, placeholder, blur, classes } = this.props;

        if (this.loading) {
            return <Loading />;
        }
        return (
            <div className={classes.root} style={{ flexBasis: !!width ? width : "100%" }}>
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={this.options}
                    getOptionLabel={(option) => option.name}
                    value={this.value}
                    onChange={this.handleChange}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={placeholder}
                            onBlur={blur}
                            name={name}
                        />
                    )}
                    filterOptions={(options) => options.filter(option => !this.value.some(value => value.id === option.id))}
                />
                {error && <FormHelperText>{errorText}</FormHelperText>}
            </div>
        );
    }
}


const onlyOneOfPropsData = {
    options: 'object',
    optionsURI: 'string'
};

DropdownMultiple.propTypes = {
    options: onlyOneOfProps(onlyOneOfPropsData),
    optionsURI: onlyOneOfProps(onlyOneOfPropsData),
};

decorate(DropdownMultiple, {
    options: observable,
    value : observable.shallow
});

export default withStyles(Styles)(asEntity({ storeId: "GenericDropdownMultiple" })(observer(DropdownMultiple)));
