import * as Yup from "yup";

export const validationSchema = Yup.object().shape({});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {

    },
};
const YesNo = [
    { id: 0, name: "No" },
    { id: 1, name: "Yes" },
]

export const formData = [
    { name: "Startdatetime", type: "date", width: "48%", focused: true, },
    { name: "Intervalsecond", type: "number", width: "48%", },
    { name: "Name", type: "text", width: "48%", },
    { name: "Active", type: "dropdown", options: YesNo, width: "48%", },
    { name: "Syncfromdate", type: "date", focused: true, width: "48%", },
    { name: "Lastsussedsyncdatatime", type: "date", focused: true, width: "48%", },
    { name: "Laststartdatetime", type: "date", focused: true, width: "48%", },
    { name: "Lastenddatetime", type: "date", focused: true, width: "48%", },

];