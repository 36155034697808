

export const columns = [
    { name: "Number", label: "Number", options: { filter: true, sort: true, } },
    { name: "CustomerCode", label: "Customer Code", options: { filter: true, sort: true, } },
    { name: "CustomerName", label: "Customer Name", options: { filter: true, sort: true, } },
    { name: "From", label: "From", options: { filter: true, sort: true, } },
    { name: "To", label: "To", options: { filter: true, sort: true, } },
    { name: "WithReturn", label: "With Return", options: { filter: true, sort: true, } },
    { name: "ReturnRate", label: "Return Rate", options: { filter: true, sort: true, } },
    { name: "Amount", label: "Amount", options: { filter: true, sort: true, } },
    { name: "EquipmentCode_1", label: "Equipment Code (1)", options: { filter: true, sort: true, } },
    { name: "EquipmentCode_2", label: "Equipment Code (2)", options: { filter: true, sort: true, } },
    { name: "ProjectCode", label: "Project Code", options: { filter: true, sort: true, } },
    { name: "MaterialsType", label: "Materials Type", options: { filter: true, sort: true, } },
    { name: "Attached", label: "Attached", options: { filter: true, sort: true, } },
];



export const data = [
    { Number: "1", CustomerCode: "C000001", CustomerName: "Gaber El Seqail", From: "الرياض", To: "جدة ", WithReturn: "Yes", ReturnRate: "10%", Amount: "1500", EquipmentCode_1: "EQ-001", EquipmentCode_2: "EQ-004", ProjectCode: "P0001", MaterialsType: "مواد غذائية ", Attached: "", },
    { Number: "2", CustomerCode: "C000002", CustomerName: "Gaber El Seqail", From: "المدينة المنورة ", To: "الرياض", WithReturn: "Yes", ReturnRate:"20%", Amount:"2300", EquipmentCode_1: "EQ-002", EquipmentCode_2: "EQ-003", ProjectCode: "P0001", MaterialsType: "مواد بناء ", Attached: "", },
    { Number: "3", CustomerCode: "C000003", CustomerName: "Ko Shi Na", From: "القصيم ", To: "الدمام", WithReturn: "No", ReturnRate: "0%", Amount: "1254", EquipmentCode_1: "EQ-001", EquipmentCode_2: "EQ-004", ProjectCode: "P0002", MaterialsType: "كفرات ", Attached: "", },
]

















