

export const columns = [
    { name: "code", label: "Code", options: { filter: false, sort: true, display: true } },
    { name: "name", label: "Name", options: { filter: true, sort: true, } },
    { name: "validFrom", label: "Valid from", options: { filter: true, sort: true, } },
    { name: "validTo", label: "Valid to", options: { filter: true, sort: true, } },
    {
        name: "active", label: "Distance", options: {
            filter: true, sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? "True" : "False"

            }
        }
    },
];




