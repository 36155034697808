import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import ProjectForm from '../Partials/ProjectForm';
import styles from './styles';


class ViewProject extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {};
    loading = false;

    componentDidMount() {
        if (!!this.id) {
            this.loading = true;
            this.props.entityStore.get(this.id)
        }
    }

    setView = (view) => {
        this.view = view;
    }

    entityDidReceived(data) {
        if (!!data) {
            this.loading = false;
            let { code, name, active, validTo, validFrom } = data;

            this.data = {
                "code: ": code,
                "Name: ": name,
                "Valid from: ": validFrom,
                "valid To: ": validTo,
                "Active: ": active ? "true" : "false",
            }

        }
    }

    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="Project Info."
                        data={this.data}
                    // setView={this.setView}
                    />
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <ProjectForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewProject, {
    view: observable,
})




export default withStyles(styles)(asEntity({ storeId: "ViewProject" })(observer(ViewProject)));
