import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    qualification: Yup.string().trim().required(),
    workExperience: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        qualification: "",
        workExperience: "",
    },
};


export const formData = [
    { name: "qualification", type: "text" },
    { name: "workExperience", type: "textarea" },

];


