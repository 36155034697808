import React from 'react'
import AuthScreen from '../Partials/AuthScreen';
import { RegisterPageData , formProps } from './data';
import RegisterFrom from './RegisterForm'
const Login = (props) => {
    return (
        <AuthScreen {...RegisterPageData}>
            <RegisterFrom {...formProps} />
        </AuthScreen>
    )
}


export default Login;
