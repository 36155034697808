import { List, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ActionButton } from '~/Components/From';
import { MenuControl, MenuTile } from './';
import { MenuItemLoader } from '~/Components/Loaders'

const useStyles = makeStyles(theme => ({
    subMenu: {
        marginLeft: theme.direction === "ltr" ? 5 : 0,
        marginRight: theme.direction === "rtl" ? 5 : 0,
        width: '100%',
        height: `calc(100vh - 64px)`,
        overflowY: 'scroll',
    },
    contentLoader: {
        marginLeft: theme.direction === "ltr" ? 20 : 0,
        marginRight: theme.direction === "rtl" ? -20 : 0,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        direction: "ltr",
    },
    homeButton: {
        margin: theme.spacing(1),
        marginLeft: theme.direction === "rtl" ? theme.spacing(1) : theme.spacing(1.5),
        marginRight: theme.direction === "ltr" ? theme.spacing(1) : theme.spacing(1.5)
    }
}))

export const MainMenu = ({ data, setActive, handleDrawerToggle, activeNav }) => {
    const history = useHistory();
    const classes = useStyles();
    const MyLoader = () => (
        <div className={classes.contentLoader}>
            {Array(6).fill(<MenuItemLoader />)}
        </div>
    )

    if (Object.keys(data).length === 0) {
        return <MyLoader></MyLoader>
    }


    const Menu = () => (
        <div >
            <ActionButton
                customClass={classes.homeButton}
                variant="contained"
                icon="home-icon"
                onClick={() => history.push('/home-page')} title="home"
            />

            {
                Object.keys(data).map((key, index) =>

                    <MenuTile
                        key={index}
                        element={data[key]}
                        icon={data[key].icon}
                        active={activeNav.activeTile === key}
                        handleClick={() => {
                            const expanded = data[key]?.children?.length > 0
                            handleDrawerToggle(true, expanded);
                            setActive({ activeTile: key, path: data[key].path })
                            history.push(data[key].path)
                        }}
                    />)

            } </div >);

    return <Menu />
}

export const SubMenu = ({ level = 0, ...props }) => {
    const classes = useStyles();
    const history = useHistory();
    const { data, activeNav, setActiveNav } = props;
    return (
        <List className={classes.subMenu}>
            {data ?
                (!!activeNav.activeTile && data.map((element, index) => <MenuControl
                    type={Array.isArray(element.children) ? "Collapse" : "Item"}
                    element={element}
                    level={level}
                    key={index}
                    handleClick={() => {
                        setActiveNav(currState => ({
                            ...currState,
                            path: element.path
                        }))
                        history.push(element.path)
                    }}
                    activeNav={activeNav}
                    setActiveNav={setActiveNav}
                />)) : <></>
            }

        </List>
    )
}